import FormLabel from "@/components/_Common/Form/Label";
import { MimeType } from "@Shared/ui/InputFile/types";
import { FC } from "react";
import { Form } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import { PersonalDataType } from "@Features/PersonalForm/types/personalStateSchema";
import { InputFile } from "@Shared/ui/InputFile";
import useTranslation from "next-translate/useTranslation";

interface MilitaryDocumentFormProps {
  disabled?: boolean;
}

export const MilitaryDocumentForm: FC<MilitaryDocumentFormProps> = (props) => {
  const { disabled = false } = props;

  const { t } = useTranslation("personal/personally");

  const { control, formState } = useFormContext<PersonalDataType>();

  return (
    <>
      <Form.Group id="scansGroup">
        <FormLabel required>
          {t("form.documents.personId.scans.label")}
        </FormLabel>
        <Controller
          control={control}
          defaultValue={undefined}
          name="documents.personId.military.scans"
          render={({ field }) => (
            <InputFile
              category="passport"
              disabled={disabled}
              formats={[MimeType.Pdf, MimeType.Jpeg, MimeType.Png]}
              hint={t("form.documents.personId.scans.hint")}
              id="personIdScans"
              maxNumFiles={5}
              maxSize={10}
              placeholder={t("form.documents.personId.scans.placeholder")}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
        {formState.errors.documents?.personId?.military?.scans && (
          <Form.Control.Feedback type="invalid">
            {
              (formState.errors.documents?.personId?.military.scans as any)
                ?.message
            }
          </Form.Control.Feedback>
        )}
      </Form.Group>
    </>
  );
};
