import { StateSchema } from "@App/providers/StoreProvider";

export const getStudentDetailsActiveUserId = (state: StateSchema): number => {
  return state.user.studentDetailActive.id;
};

export const getStudentDetailsActiveLocked = (state: StateSchema): boolean => {
  return state.user.studentDetailActive.locked;
};

export const getStudentDetailsActiveDegree = (state: StateSchema): IDegree => {
  return state.user.studentDetailActive.degree;
};

