import { createContext } from "react";
import { AuthDefaultValueType } from "../../types/authTypes";

const defaultValues: AuthDefaultValueType = {
  isAuthenticated: false,
  isLoading: true,
  user: null,
  getAccessToken: undefined,
  logoutHandler: null,
  error: null,
};

export const AuthContext = createContext<AuthDefaultValueType>(defaultValues);
