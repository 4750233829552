import { ChangeEvent } from "react";

const inputTrim = (e:  ChangeEvent<any>): string => {
  if (e.target.value[0] === " ") {
    return e.target.value.trim();
  } else {
    return e.target.value.replace(/\s+/g, " ");
  }
};

export default inputTrim;
