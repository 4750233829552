import { StateSchema } from "@App/providers/StoreProvider";
import { sidebarEntityAdapter } from "../adapter/sidebarEntityAdapter";

export const sidebarSelectors = sidebarEntityAdapter.getSelectors<StateSchema>((state) => {
  return state.sidebarReducer?.items || sidebarEntityAdapter.getInitialState();
});

export const getSidebarLoading = (state: StateSchema): boolean => {
  return state.sidebarReducer?.isLoading || false;
};
