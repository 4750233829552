import { api } from "@Shared/api/createAxiosApi";
import { PostgraduateExamType } from "../types/postgraduateExamTypes";

interface PostPostgraduateExamsAdapterOptions {
    data: PostgraduateExamType[]
    year: number
}

export const postPostgraduateExamsAdapter = async (options: PostPostgraduateExamsAdapterOptions): Promise<any> => {
  const { data, year } = options;

  const languageExam = data.shift();
  const postgraduateExamsResponse = await api.get("/dictionaries/disciplines/postgraduate");
  const postgraduateExamsTypeOptions: MapItem[] = postgraduateExamsResponse.data.result.items;

  const LANGUAGE_EXAM_ID = postgraduateExamsTypeOptions.find(
    (item) => item.label === "Английский язык"
  )?.value;
  const SPECIALTY_EXAM_ID = postgraduateExamsTypeOptions.find(
    (item) => item.label === "Специальность"
  )?.value;

  const specialtyExamsBody = data.map(item => ({
    discipline_id: SPECIALTY_EXAM_ID,
    type_id: 4,
    year: year,
    postgraduate_exam_date: item.date?.value,
    scores: null,
    selected_program_id: item.program?.value,
  }));

  const body = {
    is_have_exams: true,
    language_exam: {
      discipline_id: LANGUAGE_EXAM_ID,
      type_id: 4,
      year: year,
      postgraduate_exam_date: languageExam?.date?.value,
      scores: null,
    },
    specialty_exams: specialtyExamsBody,
  };
  return body;
};
