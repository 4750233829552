export type AppRoutesWithAuthProps = {
    path: string,
    userOnly?: boolean
    windowOnly?: boolean
    fullVersionOnly?: boolean  // true только если роут доступен в сокращенной версии ЛК
};

export enum AppRoutes {
    // Common user routes
    PERSONAL_INDEX = "personalIndex",
    PERSONAL_ACHIEVEMENTS = "personalAchievements",
    PERSONAL_ADDRESS = "personalAddress",
    PERSONAL_DATA = "personalData",
    PERSONAL_DOCUMENTS = "personalDocuments",
    PERSONAL_DORMITORY = "personalDormitory",
    PERSONAL_EDUCATION = "personalEducation",
    PERSONAL_EXAMS = "personalExams",
    PERSONAL_ITMOSTARS = "personalItmostars",
    PERSONAL_KRONBARS = "personalKronbars",
    PERSONAL_MEDICAL = "personalMedical",
    PERSONAL_MILITARY = "personalMilitary",
    PERSONAL_OLYMPIADS = "personalOlympiads",
    PERSONAL_PAYMENT = "personalPayment",
    PERSONAL_PHOTO = "personalPhoto",
    PERSONAL_PRIVILEGES = "personalPrivileges",
    PERSONAL_PROGRAMS = "personalPrograms",
    PERSONAL_QUOTA = "personalQuota",
    PERSONAL_RELATIVES = "personalRelatives",
    PERSONAL_SPECIALITY = "personalSpecialty",
    SELECT_PROGRAMS = "selectPrograms",
    WINDOW_INDEX = "windowIndex",

    // 404
    NOT_FOUND = "notFound",
}

export const RoutesPath: Record<AppRoutes, string> = {
  /* Common user routes */
  [AppRoutes.PERSONAL_INDEX]: "/",
  [AppRoutes.SELECT_PROGRAMS]: "/programs",
  [AppRoutes.PERSONAL_ACHIEVEMENTS]: "/personal/achievements",
  [AppRoutes.PERSONAL_ADDRESS]: "/personal/address",
  [AppRoutes.PERSONAL_DATA]: "/personal/personally",
  [AppRoutes.PERSONAL_DOCUMENTS]: "/personal/documents",
  [AppRoutes.PERSONAL_DORMITORY]: "/personal/dormitory",
  [AppRoutes.PERSONAL_EDUCATION]: "/personal/education",
  [AppRoutes.PERSONAL_EXAMS]: "/personal/exams",
  [AppRoutes.PERSONAL_ITMOSTARS]: "/personal/itmostars",
  [AppRoutes.PERSONAL_KRONBARS]: "/personal/kronbars",
  [AppRoutes.PERSONAL_MEDICAL]: "/personal/medical",
  [AppRoutes.PERSONAL_MILITARY]: "/personal/military",
  [AppRoutes.PERSONAL_OLYMPIADS]: "/personal/olympiads",
  [AppRoutes.PERSONAL_PAYMENT]: "/personal/payment_details",
  [AppRoutes.PERSONAL_PHOTO]: "/personal/photo",
  [AppRoutes.PERSONAL_PRIVILEGES]: "/personal/privileges",
  [AppRoutes.PERSONAL_PROGRAMS]: "/personal/programs",
  [AppRoutes.PERSONAL_QUOTA]: "/personal/quota",
  [AppRoutes.PERSONAL_RELATIVES]: "/personal/relatives",
  [AppRoutes.PERSONAL_SPECIALITY]: "/personal/specialty",

  /* Window user routes */
  [AppRoutes.WINDOW_INDEX]: "/window/1",

  [AppRoutes.NOT_FOUND]: "/404",
};
