import * as yup from "yup";
import useTranslation from "next-translate/useTranslation";
import { localeDateString } from "../localeDateString";

type YupType = typeof yup;

const createYup = (): YupType => {
  yup.addMethod(yup.mixed, "isFilesAttached", function () {
    const { t } = useTranslation("validations");
    return this.test(
      "isFilesAttached",
      t("mixed.file.attached"),
      (value) => value?.length
    );
  });
  yup.addMethod(yup.string, "customEmail", function () {
    const { t } = useTranslation("validations");
    return this.test("customEmail", t("string.email"), (value) => {
      if (value)
        return /^[a-zA-Z0-9.!#$%&’*+=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          value
        );
      else return false;
    });
  });
  yup.addMethod(yup.mixed, "fileMaxSize", function (maxSize) {
    const { t } = useTranslation("validations");
    return this.test(
      "fileMaxSize",
      t("mixed.file.size.max", { max: maxSize }),
      function (value) {
        for (let i = 0; i < value.length; i++) {
          if (value[i].size / 1024 / 1024 > maxSize) {
            return false;
          }
        }
        return true;
      }
    );
  });
  yup.addMethod(
    yup.mixed,
    "imageMinDimensions",
    function (minWidth, minHeight) {
      const { t } = useTranslation("validations");
      return this.test(
        "imageMinDimensions",
        t("mixed.image.dimensions.min", { width: minWidth, height: minHeight }),
        function (value) {
          return new Promise((resolve) => {
            for (let i = 0; i < value.length; i++) {
              const image = new Image();
              const reader = new FileReader();
              reader.readAsBinaryString(value[i]);
              reader.addEventListener("load", (event) => {
                if (event.target && event.target.result) {
                  image.src = `data:${value[i]?.type};base64,${btoa(
                    event.target.result.toString()
                  )}`;
                  image.addEventListener("load", () => {
                    if (
                      image.naturalWidth < minWidth ||
                      image.naturalHeight < minHeight
                    ) {
                      resolve(false);
                    }
                    resolve(true);
                  });
                }
              });
            }
            resolve(true);
          });
        }
      );
    }
  );
  yup.addMethod(yup.string, "onlyDigits", function () {
    const { t } = useTranslation("validations");
    return this.test("onlyDigits", t("string.only_digits"), (value) => {
      if (value) {
        return /^[0-9]+$/.test(value);
      } else {
        return true;
      }
    });
  });
  yup.addMethod(yup.string, "onlyCyrillic", function () {
    const { t } = useTranslation("validations");
    return this.test("onlyCyrillic", t("string.only_cyrillic"), (value) => {
      if (value) {
        return /^[ЁёА-я]+$/.test(value);
      } else {
        return true;
      }
    });
  });
  yup.addMethod(yup.string, "onlyLetters", function () {
    const { t } = useTranslation("validations");
    return this.test("onlyLetters", t("string.only_letters"), (value) => {
      if (value) {
        return !/^[0-9]+$/.test(value);
      } else {
        return false;
      }
    });
  });
  yup.addMethod(yup.string, "min", function (min: number) {
    const { t } = useTranslation("validations");
    return this.test({
      name: "min",
      exclusive: true,
      message: t("string.min", { min }),
      test: (value) => !value || value.length >= min,
    });
  });
  yup.addMethod(yup.string, "max", function (max: number) {
    const { t } = useTranslation("validations");
    return this.test({
      name: "max",
      exclusive: true,
      message: t("string.max", { max }),
      test: (value) => !value || value.length <= max,
    });
  });
  yup.addMethod(yup.string, "range", function (min: number, max: number) {
    const { t } = useTranslation("validations");
    return this.test({
      name: "range",
      exclusive: true,
      message: t("string.range", { min, max }),
      test: (value) =>
        !(!value || parseInt(value) < min || parseInt(value) > max),
    });
  });
  yup.addMethod(yup.string, "passwordValid", function () {
    const { t } = useTranslation("validations");
    return this.test("passwordValid", t("string.password_valid"), (value) => {
      if (value) {
        return /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(
          value
        );
      } else {
        return true;
      }
    });
  });
  yup.addMethod(yup.string, "required", function (isRequired = true) {
    const { t } = useTranslation("validations");
    return this.test({
      name: "required",
      exclusive: true,
      message: t("string.required"),
      test: (value) => {
        return !isRequired || !(!value || !value.replace(/\s/g, ""));
      },
    });
  });
  yup.addMethod(yup.mixed, "passwordsMatched", function (ref) {
    const { t } = useTranslation("validations");
    return this.test(
      "passwordsMatched",
      t("mixed.passwords_no_matched"),
      function (value) {
        const refValue = this.resolve(ref);
        return !refValue || !value || value === refValue;
      }
    );
  });
  yup.addMethod(yup.string, "nameValid", function () {
    const { t } = useTranslation("validations");
    return this.test({
      name: "nameValid",
      message: t("string.nameValid"),
      test: (value) =>
        !value ||
        (/^[\s*а-яёА-ЯA-Za-z.'--]*$/i.test(value) &&
          /^[\s*а-яёА-ЯA-Za-z]/i.test(value[0])),
    });
  });
  yup.addMethod(yup.string, "linkValid", function () {
    const { t } = useTranslation("validations");
    return this.test({
      name: "linkValid",
      message: t("string.nameValid"),
      test: (value) =>
        !value ||
        /^((https?):\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/.test(
          value
        ),
    });
  });
  yup.addMethod(
    yup.string,
    "correctDate",
    function (isRequired = true, min = "1900-01-01", max = "2050-01-01") {
      const { t } = useTranslation("validations");
      const reg = new RegExp("^\\d{2}\\.\\d{2}\\.\\d{4}$");
      const minDate = new Date(min);
      const maxDate = new Date(max);

      return this.test({
        name: "correctDate",
        exclusive: true,
        message: t("string.correctDate"),
        test: (value) => {
          if (!value) value = "";
          const input = value.split(".");
          const inputDate = new Date(
            parseInt(input[2]),
            parseInt(input[1]) - 1,
            parseInt(input[0])
          );

          return (
            !isRequired ||
            !(
              !value ||
              !reg.test(value) ||
              parseInt(input[0]) !== inputDate.getDate() ||
              parseInt(input[1]) !== inputDate.getMonth() + 1 ||
              parseInt(input[2]) !== inputDate.getFullYear() ||
              inputDate < minDate ||
              inputDate > maxDate
            )
          );
        },
      });
    }
  );
  yup.addMethod(yup.string, "correctDateFormat", function () {
    const { t } = useTranslation("validations");
    const reg = new RegExp("^\\d{2}\\.\\d{2}\\.\\d{4}$");

    return this.test({
      name: "correctDateFormat",
      exclusive: true,
      message: t("string.correctDateFormat"),
      test: (value) => {
        if (!value) return true;
        const input = value.split(".");
        const inputDate = new Date(
          parseInt(input[2]),
          parseInt(input[1]) - 1,
          parseInt(input[0])
        );

        return !(
          !value ||
          !reg.test(value) ||
          parseInt(input[0]) !== inputDate.getDate() ||
          parseInt(input[1]) !== inputDate.getMonth() + 1 ||
          parseInt(input[2]) !== inputDate.getFullYear()
        );
      },
    });
  });
  yup.addMethod(yup.string, "minDate", function (minDate = "1950-01-01") {
    const { t } = useTranslation("validations");
    const minDateValue = new Date(minDate);

    return this.test({
      name: "minDate",
      message: t("string.minDate", { minDate: localeDateString(minDate) }),
      test: (value) => {
        if (!value) return true;
        const input = value.split(".");
        const inputDate = new Date(
          parseInt(input[2]),
          parseInt(input[1]) - 1,
          parseInt(input[0])
        );

        return inputDate > minDateValue;
      },
    });
  });
  yup.addMethod(
    yup.string,
    "maxDate",
    function (maxDate = new Date().toISOString()) {
      const { t } = useTranslation("validations");
      const maxDateValue = new Date(maxDate);

      return this.test({
        name: "correctDate",
        message: t("string.maxDate", {
          maxDate: localeDateString(maxDate),
        }),
        test: (value) => {
          if (!value) return true;
          const input = value.split(".");
          const inputDate = new Date(
            parseInt(input[2]),
            parseInt(input[1]) - 1,
            parseInt(input[0])
          );

          return inputDate < maxDateValue;
        },
      });
    }
  );
  yup.addMethod(yup.number, "maxPrecision", function (precision: number) {
    const { t } = useTranslation("validations");
    const re = new RegExp(`^\\d+(\\.\\d{0,${precision}})?$`);

    return this.test({
      name: "maxPrecision",
      message: t("number.maxPrecision", { precision }),
      test: (value) => !value || re.test(value.toString()),
    });
  });

  return yup;
};

export const AppYup = createYup();

export type YupAppType = typeof AppYup;
