enum SearchFilterEnum {
  FIO = "fio",
  NAME = "name",
  FULL_NAME = "full_name",
  DOCUMENT = "document",
  DEAL = "deal",
  EMAIL = "email",
  FACULTY = "faculty",
  PROGRAM = "program",
  SNILS = "snils",
  CASE_NUMBER = "caseNumber"
}

export default SearchFilterEnum;
