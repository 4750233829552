import { IWindowCost } from "@/types/window/forms";

export const getEmptyCostEducation = (year: number): IWindowCost => (
  {
    id: 0,
    year: year,
    rf: null,
    foreigner: null,
    costs_type: {
      id: 1,
      title: "базовая стоимость",
    },
  }
);
