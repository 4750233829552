import { ThunkApiType } from "@App/providers/StoreProvider";
import { createAsyncThunk } from "@reduxjs/toolkit";

interface PostUserCitizenshipOptions {
    body: {
        citizenship: string
    }
}

const errorMessage = "Ошибка при сохранении гражданства";

export const postUserCitizenship = createAsyncThunk<void, PostUserCitizenshipOptions, ThunkApiType<string>>(
  "userSlice/postUserCitizenship",
  async (options, thunkApiType) => {
    const { body } = options;
    const { extra, rejectWithValue } = thunkApiType;

    try {
      const response = await extra.api.patch("/users/citizenship", body);

      if (!response) {
        throw new Error(errorMessage);
      }
    } catch (error) {
      return rejectWithValue(errorMessage);
    }
  }
);
