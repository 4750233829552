import { StateSchema } from "@App/providers/StoreProvider";
import { postgraduateExamsAdapter } from "../adapters/examsAdapter";
import { postgraduateSelectedProgramsAdapter } from "../adapters/postgraduateSelectedProgramsAdapter";

export const getPostgraduateExamsData = postgraduateExamsAdapter.getSelectors<StateSchema>(
  state => state.postgraduatorExamsReducer || postgraduateExamsAdapter.getInitialState()
);

export const getSelectedProgramOptions = postgraduateSelectedProgramsAdapter.getSelectors<StateSchema>(
  state => state.postgraduatorExamsReducer?.selectedProgramOptions || postgraduateSelectedProgramsAdapter.getInitialState()
);

export const getPostgraduateExamProgramOptions = (state: StateSchema): MapItem[]  => {
  return state.postgraduatorExamsReducer?.programOptions || [];
};

export const getLanguageExamDateOptions = (state: StateSchema): MapItem[] => {
  return state.postgraduatorExamsReducer?.lnaguageDateOptions || [];
};

export const getSpecialtyExamOptions = (state: StateSchema, examId: number): MapItem[] => {
  return getPostgraduateExamsData.selectById(state, examId)?.dateOptions || [];
};

export const getPostgraduateLoading = (state: StateSchema): boolean => {
  return state.postgraduatorExamsReducer?.isLoading || false;
};

export const getValidatingMode = (state: StateSchema): boolean => {
  return state.postgraduatorExamsReducer?.isValidatingMode || false;
};

export const getIsValidatingSuccess = (state: StateSchema): boolean => {
  return state.postgraduatorExamsReducer?.isValidatingSuccess || false;
};
