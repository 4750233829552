import { FC, useEffect, useState } from "react";

import ArrowDown from "@/public/images/symbols/arrow_down.svg";
import clsx from "clsx";
import useTranslation from "next-translate/useTranslation";
import { LanguagesLocalesEnum } from "@/types/LanguagesLocalesEnum";
import { useDispatch, useSelector } from "react-redux";
import { getStudentDetailsActiveUserId, getUserDegree, getUserId, getUserTheme } from "@Entities/User";
import { getPersonalData } from "@Features/PersonalForm/model/selectors/getPersonalData/getPersonalData";
import { FetchPersonalData } from "@Features/PersonalForm";
import { ThemeEnum } from "@Shared/types/themeTypes";
import { degreeList, foreignerDegreeList } from "@Shared/consts/degreeList";
import { getPersonalLoading } from "@Features/PersonalForm/model/selectors/getComponentStates/getComponentStates";
import { Skeleton } from "@Shared/ui/Skeleton";
import styles from "./AbiturientInformation.module.scss";
import { RolesList } from "../RolesList/RolesList";

interface AbiturientInformationProps {
  isMobile?: boolean
}

export const AbiturientInformation: FC<AbiturientInformationProps> = (props) => {
  const { isMobile = false } = props;

  const { lang } = useTranslation();

  const dispatch = useDispatch();
  const userId = useSelector(getUserId);
  const studentDetailsId = useSelector(getStudentDetailsActiveUserId);
  const degree = useSelector(getUserDegree);
  const personalData = useSelector(getPersonalData);
  const isPersonalLoading = useSelector(getPersonalLoading);
  const theme = useSelector(getUserTheme);
  const degreeLabel = (lang === LanguagesLocalesEnum.RU ? degreeList : foreignerDegreeList)
    .find(degreeItem => degreeItem.value === degree)?.label || "";

  const [showRoles, setShowRoles] = useState(false);

  useEffect(() => {
    if (!personalData && degree) {
      dispatch(FetchPersonalData({ userId, studentDetailsId }));
    }
  }, [degree]);

  return (
    <div className={clsx(
      styles.information,
      theme === ThemeEnum.ACCESSIBLE && styles.information_accessible,
      isMobile && styles.information_burger
    )}>
      <div className={clsx(
        styles.information__photo,
        theme === ThemeEnum.ACCESSIBLE && styles.information__photo_accessible,
        isMobile && styles.information__photo_burger
      )}>
        <p className={clsx(styles.information__photo__letter)}>
          {isPersonalLoading ? (
            <Skeleton className={styles.information__photo__avatarSkeleton} />
          ) : (
            personalData?.fullName.firstName[0]?.toUpperCase()
          )}
        </p>
      </div>
      <div>
        <h1 className={clsx(styles.information__name, isMobile && styles.information__name_burger)}>
          {personalData?.fullName.firstName} {personalData?.fullName.lastName}
        </h1>
        <span
          id="rolesSelect"
          className={clsx(
            styles.information__role,
            theme === ThemeEnum.ACCESSIBLE && styles.information__role_accessible,
            isMobile && styles.information__role_burger,
            showRoles && !isMobile && styles.information__role_active,
            showRoles && isMobile && styles.information__role_burger_active
          )}
          onClick={() => setShowRoles(!showRoles)}
        >
          <p
            className={clsx(
              styles.information__degree,
              theme === ThemeEnum.ACCESSIBLE && styles.information__degree_accessible,
              isMobile && styles.information__degree_burger
            )}
          >{degreeLabel}</p>
          <ArrowDown />
        </span>
        {showRoles && <RolesList isMobile={isMobile} setIsOpen={setShowRoles} />}
      </div>
    </div>
  );
};
