import styles from "../Footer.module.scss";
import Vk from "@/public/images/symbols/vk.svg";
import Facebook from "@/public/images/symbols/facebook.svg";
import Instagram from "@/public/images/symbols/instagram.svg";
import Youtube from "@/public/images/symbols/youtube.svg";
import Telegram from "@/public/images/symbols/telegram.svg";
import ButtonCustom from "@/components/_Common/ButtonCustom";
import Feedback from "@/public/images/symbols/feedback.svg";
import useTranslation from "next-translate/useTranslation";
import clsx from "clsx";
import { useTypedSelector } from "@/hooks/useTypedSelector";
import { LanguagesLocalesEnum } from "@/types/LanguagesLocalesEnum";

const SocialFooter = (): JSX.Element => {
  const { t, lang } = useTranslation("common");
  const { theme } = useTypedSelector(state => state.user);

  return (
    <>
      <p className={clsx(styles.footer__heading, theme === "accessible" && styles.footer__heading_accessible)}>
        {t("footer.col_third.social")}
      </p>
      <div className={styles.footer__soc}>
        <div className={styles.footer__soc__item}>
          <a className={styles.footer__soc__link} href={lang === LanguagesLocalesEnum.RU ? "https://vk.com/itmoru" : "https://vk.com/itmo_uni"} rel="noopener noreferrer" target='_blank'>
            <Vk />
          </a>
        </div>
        <div className={clsx([styles.footer__soc__item, "d-none"])}>
          <a className={styles.footer__soc__link} href="https://www.facebook.com/itmoRU/" rel="noopener noreferrer" target='_blank'>
            <Facebook />
          </a>
        </div>
        <div className={clsx([styles.footer__soc__item, "d-none"])}>
          <a className={styles.footer__soc__link} href="https://www.instagram.com/itmoru/" rel="noopener noreferrer" target='_blank'>
            <Instagram />
          </a>
        </div>
        <div className={styles.footer__soc__item}>
          <a className={styles.footer__soc__link} href="https://www.youtube.com/user/SPbIFMO" rel="noopener noreferrer" target='_blank'>
            <Youtube />
          </a>
        </div>
        <div className={styles.footer__soc__item}>
          <a className={styles.footer__soc__link} href={lang === LanguagesLocalesEnum.RU ? "https://t.me/itmoru" : "https://t.me/itmo_uni"} rel="noopener noreferrer" target='_blank'>
            <Telegram />
          </a>
        </div>
      </div>
      <div className={styles.footer__buttonBlock}>
        <p className={clsx(styles.footer__heading, theme === "accessible" && styles.footer__heading_accessible)}>
          {t("footer.col_third.contact")}
        </p>
        <ButtonCustom
          Icon={Feedback}
          iconPosition='left'
          rounded={true}
          size='lg'
          type='button'
          variant='primary'
          onClick={() => location.href = "mailto:abit@itmo.ru"}
        >
          {t("footer.button")}
        </ButtonCustom>
      </div>
    </>
  );
};

export default SocialFooter;
