import { useTypedSelector } from "./useTypedSelector";

const useQuestionnaire = (): {
  user: IUserData,
  studentDetails: IStudentDetails,
} => {
  const { user, studentDetailActive } = useTypedSelector(state => state.user);
  const window = useTypedSelector(state => state.window);

  if ( window?.questionnaire ) {
    return {
      user: window.questionnaire.user,
      studentDetails: window.questionnaire.studentDetails,
    };
  }

  return {
    user: user,
    studentDetails: studentDetailActive,
  };
};

export default useQuestionnaire;
