import { FC } from "react";
import clsx from "clsx";

import styles from "./Skeleton.module.scss";

interface SkeletonProps {
    className?: string
    rounded?: boolean
}

export const Skeleton: FC<SkeletonProps> = (props) => {
  const { className, rounded = false } = props;

  const mods: Record<string, boolean> = {
    [styles.rounded]: rounded,
  };

  return (
    <div className={clsx(styles.skeleton, mods, className)} id="skeleton" />
  );
};
