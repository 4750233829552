import { ThunkApiType } from "@App/providers/StoreProvider";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { UserActions } from "../../slice/UserSlice";

interface StudentDetailsActiveProps {
    userId: number
}

const errorMessage = "";

export const setStudentDetailsActive = createAsyncThunk<IStudentDetails, StudentDetailsActiveProps, ThunkApiType<string>>(
  "user/studentDetailsActive",
  async (data, thunkApi) => {
    const { extra, dispatch, rejectWithValue } = thunkApi;
    const { userId } = data;

    try {
      const response = await extra.api.get(`/users/${userId}/studentDetails/active`);
      const payload = response.data.result;
      const studentDetailsId = payload.id;
      if (studentDetailsId) {
        const { data } = await extra.api.get(
          `/users/${userId}/studentDetails/${studentDetailsId}/application`
        );
        const status = data.result.status;
        dispatch(UserActions.setStatus(status));
      }

      if (!response.data) {
        throw new Error(errorMessage);
      }

      dispatch(UserActions.setStudentDetailsActive(payload));
      dispatch(UserActions.setInited());
      return response.data;
    } catch {
      return rejectWithValue(errorMessage);
    }
  }
);
