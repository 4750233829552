import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { PersonalDataType, PersonalStateSchema } from "../../types/personalStateSchema";
import { FetchPersonalData } from "../services/FetchPersonalData/FetchPersonalData";
import { PostPersonalData } from "../services/PostPersonalData/PostPersonalData";

const initialState: PersonalStateSchema = {
  data: null,
  documentTypeOptions: [],
  error: null,
  isSavedSuccessfull: false,
  isLoading: true,
  isComplited: false,
};

const PersonalSlice = createSlice({
  name: "personalSlice",
  initialState,
  reducers: {
    setPersonalData: (state, action: PayloadAction<PersonalDataType>) => {
      state.data = action.payload;
    },
    setDocumentTypeOptions: (state, action: PayloadAction<MapItem[]>) => {
      state.documentTypeOptions = action.payload;
    },
    setIsSavedSuccessfull: (state, action: PayloadAction<boolean>) => {
      state.isSavedSuccessfull = action.payload;
    },
    resetError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (build) => {
    build.addCase(FetchPersonalData.pending, (state) => {
      state.isLoading = true,
      state.error = null;
    });
    build.addCase(FetchPersonalData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    build.addCase(FetchPersonalData.rejected, (state, action) => {
      state.isLoading = false,
      state.error = action.error.message;
    });
    build.addCase(PostPersonalData.pending, (state) => {
      state.isLoading = true,
      state.error = null;
    });
    build.addCase(PostPersonalData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    build.addCase(PostPersonalData.rejected, (state, action) => {
      state.isLoading = false,
      state.error = action.payload;
    });
  },
});

export const { actions: PersonalActions } = PersonalSlice;
export const { reducer: PersonalReducer } = PersonalSlice;
