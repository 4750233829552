import { createAsyncThunk } from "@reduxjs/toolkit";
import { ThunkApiType } from "@App/providers/StoreProvider";
import { api } from "@Shared/api/createAxiosApi";

interface GetAchievementsOptions {
    degree: string;
    year: number;
}

const getAchievementsErrorMessage = "Ошибка при получении достижений";

export const fetchAchievements = createAsyncThunk<Achievement[], GetAchievementsOptions, ThunkApiType<string>>(
  "achievementSlice/getAchievements",
  async (options, thunkApi) => {
    const { degree, year } = options;
    const { rejectWithValue } = thunkApi;

    try {
      const response = await api.get(`/dictionaries/achievement_types_with_category/${degree}/${year}`);

      if (!response) {
        throw new Error(getAchievementsErrorMessage);
      }

      return response.data.result.items;
    } catch (error: any) {
      const errorDetail = error?.response?.data?.errors?.detail;
      return rejectWithValue(errorDetail);
    }
  }
);
