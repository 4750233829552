import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { updateEntrantAchievement } from "../services/updateEntrantAchievement";
import { createEntrantAchievement } from "../services/createEntrantAchievement";
import { fetchAchievements } from "../services/fetchAchievements";
import {
  fetchEntrantAchievements,
} from "@Features/AchievementsForm/model/services/fetchEntrantAchievements";

interface AchievementState {
    isLoading: boolean;
    error: string | null;
    achievementId: number | null;
    achievements: Achievement[];
    entrantAchievements: StudentAchievement[];
}

const initialState: AchievementState = {
  isLoading: false,
  error: null,
  achievementId: null,
  achievements: [],
  entrantAchievements: [],
};

export const achievementsSlice = createSlice({
  name: "achievementsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateEntrantAchievement.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateEntrantAchievement.fulfilled, (state, action: PayloadAction<{ achievementId: number }>) => {
        state.isLoading = false;
        state.achievementId = action.payload.achievementId;
      })
      .addCase(updateEntrantAchievement.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })
      .addCase(createEntrantAchievement.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createEntrantAchievement.fulfilled, (state, action: PayloadAction<number | null>) => {
        state.isLoading = false;
        state.achievementId = action.payload;
      })
      .addCase(createEntrantAchievement.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })
      .addCase(fetchAchievements.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchAchievements.fulfilled, (state, action: PayloadAction<Achievement[]>) => {
        state.isLoading = false;
        state.achievements = action.payload;
      })
      .addCase(fetchAchievements.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })
      .addCase(fetchEntrantAchievements.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchEntrantAchievements.fulfilled, (state, action: PayloadAction<StudentAchievement[] | null>) => {
        state.isLoading = false;
        if (action.payload) {
          state.entrantAchievements = action.payload;
        } else {
          state.error = "Failed to fetch achievements";
        }
      })
      .addCase(fetchEntrantAchievements.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      });
  },
});

export const { reducer: AchievementsReducer } = achievementsSlice;
export const { actions: AchievementsActions } = achievementsSlice;
