import { IDictionaryItem } from "@/lib/services/dictionary.service";
import DegreeEnum from "./enums/degree";
import EnvEnum from "./enums/envEnum";

export const fakeWindowDataEntryNav = {
  links: [
    { label: "Баллы", value: "scores" },
    { label: "Стоимость", value: "price" },
    { label: "Ввод КЦП", value: "budgetPlace" },
    { label: "Ввод адреса школ / ВУЗов", value: "addressEntry" },
    { label: "Комиссии", value: "commissions" },
    { label: "Даты экзаменов", value: "exams" },
    { label: "Приемная кампания", value: "configurations" },
    { label: "Вступительные испытания", value: "entrance" },
    { label: "Конкурсные группы", value: "competitive-groups" },
  ],
  title: "Ввод данных",
};

export const fakeDegreeList: IDegreeList[] = [
  {
    label: "Бакалавриат",
    value: "bachelor",
  },
  {
    label: "Магистратура",
    value: "master",
  },
  {
    label: "Аспирантура",
    value: "postgraduate",
  },
];

export const fakeFinancing = [
  {
    label: "Бюджет",
    value: "budget",
  },
  {
    label: "Контракт",
    value: "contract",
  },
];

export const fakeDegreeEnList: IDegreeList[] = [
  {
    label: "Bachelor",
    value: "bachelor",
  },
  {
    label: "Master",
    value: "master",
  },
  {
    label: "PhD",
    value: "postgraduate",
  },
];

export const fakeAcceptanceList: IDictionaryItem[] = [
  {
    label: "Да",
    value: "1",
  },
  {
    label: "Нет",
    value: "0",
  },
];

export const fakeStatusList: IDictionaryItem[] = [
  {
    label: "Новая",
    value: "new",
  },
  {
    label: "В работе",
    value: "at_work",
  },
  {
    label: "Обработано",
    value: "done",
  },
];

export const fakeYearList: IDictionaryItem[] = [
  { label: "2024", value: "2024" },
  { label: "2023", value: "2023" },
  { label: "2022", value: "2022" },
];

export const fakeStudentDetail = [
  {
    id: 0,
    degree: "bachelor",
    first_time_degree: true,
    year: 2021,
  },
  {
    id: 0,
    degree: "master",
    first_time_degree: true,
    year: 2021,
  },
  {
    id: 0,
    degree: "postgraduate",
    first_time_degree: true,
    year: 2021,
  },
];

export const fakeStudentDetailActiveBachelor = {
  id: 0,
  degree: "bachelor",
  first_time_degree: true,
  year: 2021,
};

export const fakeStudentDetailActiveMaster = {
  id: 0,
  degree: "master",
  first_time_degree: true,
  year: 2021,
};

export const fakeStudentDetailActivePostgraduate = {
  id: 0,
  degree: "postgraduate",
  first_time_degree: true,
  year: 2021,
};

export const fakeUser = {
  studentDetailActive: fakeStudentDetailActiveBachelor,
  studentDetails: fakeStudentDetail,
  degreeList: fakeDegreeList,
  user: {
    id: 0,
    email: "test@test.ru",
    first_name: "test",
    last_name: "test",
    middle_name: "test",
    citizenship: "test",
    phone: "test",
    student_details: 0,
    program: {
      id: 1,
      degree: "bachelor" || "master" || "postgraduate",
      title: "string",
      titleEn: "string",
      disciplines: [{ type: "string" }],
      directionCode: "string",
      direction: { title: "string", code: "string" },
      directionOfEducation: "string",
      isuId: 1,
      admissionQuotas: {
        budget: 1,
      },
    },

    windows: [],
    roles: [],
  },
};

export const fakeSidebar = {
  personalLink: [
    {
      name: "test",
      isCompleted: false,
      isActive: false,
    },
    {
      name: "test",
      isCompleted: false,
      isActive: false,
    },
  ],
  programsLink: {
    name: "test",
    isCompleted: false,
    isActive: false,
  },
  applicationsLink: {
    name: "test",
    isCompleted: false,
    isActive: false,
  },
};

export const fakeIDictionaryItem = [
  {
    label: "someFakeTestLabelOne",
    value: "someFakeTestValueOne",
  },
  {
    label: "someFakeTestLabelTwo",
    value: "someFakeTestValueTwo",
  },
];

export const fakeProgramsModal = {
  name: "testName",
  code: "testCode",
  id: "id",
};

export const fakeProgramsItem = {
  admissionQuotas: {
    budget: 1,
    contract: 1,
    contract_foreigner: 1,
    invalid: 1,
    recommended_count: 1,
    special_quota: 1,
    target_reception: 1,
  },
  degree: DegreeEnum.BACHELOR,
  directionOfEducation: "string",
  discipline: null,
  faculties: [],
  id: 1,
  isuId: 1,
  title: "string",
  name: "string",
  priority: 1,
  code: "testCode",
  index: 1,
  selectedProgramId: 1,
  selectedDisciplineId: 1,
  disciplines: [],
  financing: fakeIDictionaryItem[0].value,
  admissionCondition: fakeIDictionaryItem[1].value,
  direction: { title: "1", code: 1 },
  directionCode: "1",
  number: 1,
  track: "string",
  applications: [],
  program: {
    id: 1,
    title: "string",
    directionCode: "string",
    direction: { title: "string", code: "string" },
    directionOfEducation: "string",
    isuId: 1,
  },
  numberMail: "string",
  numberDirection: "string",
  dateMail: "string",
  scores: "string",
  status: "string",
  supervisorConfirm: null,
  supervisorComment: null,
};

export const fakeWindow: {
  questionnaire: null;
  active: { role: string; id: number; label: string; title: string };
  params: {
    direction_code: string;
    year: string;
    citizenship: string;
    program: string;
    is_have_original: boolean;
    is_in_order: string;
    faculty: string;
    search: { filter: string; query: string };
    caseNumber: string;
    sortingColName: string;
    sortingDirection: string;
    financing: string;
    page: number;
  };
} = {
  active: {
    id: 1,
    title: "test",
    role: "test",
    label: "test",
  },
  questionnaire: null,
  params: {
    page: 0,
    search: {
      query: "string",
      filter: "string",
    },
    year: "string",
    caseNumber: "string",
    citizenship: "string",
    direction_code: "string",
    financing: "string",
    is_in_order: "string",
    program: "string",
    faculty: "string",
    is_have_original: true,
    sortingColName: "string",
    sortingDirection: "string",
  },
};

export const abitLink = () => {
  const configEnv = process.env.APP_ENV as EnvEnum;

  const abitLinks: { [key in EnvEnum]: string } = {
    [EnvEnum.LOCAL]: "https://abit.test.itmo.dev",
    [EnvEnum.TEST]: "https://abit.test.itmo.dev",
    [EnvEnum.STAGE]: "https://abit.stage.itmo.dev",
    [EnvEnum.DEV]: "https://abit.dev.itmo.dev",
    [EnvEnum.PROD]: "https://abit.itmo.ru",
  };

  return abitLinks[configEnv] ?? abitLinks[EnvEnum.PROD];
};

export const currentCompanyYear = 2024;
