import { ClipboardEvent } from "react";
import dateMask from "@/lib/_common/inputs/dateMask";

const pasteNumberHandle = (e: ClipboardEvent<HTMLInputElement>): void => {
  e.preventDefault();
  const target = e.target as HTMLInputElement;
  const input = e.clipboardData.getData("Text").replace(/[^0-9]/ig, "");
  if (target.value) {
    const selectionStart = target.selectionStart ? target.selectionStart : 0;
    const selectionEnd = target.selectionEnd ? target.selectionEnd : 0;
    if (target.value.length === selectionEnd - selectionStart) {
      target.value = dateMask(input);
    }
    else {
      target.value = (target.value.substring(0, selectionStart) + input + target.value.substring(selectionEnd)).substring(0, 10);
      target.value = dateMask(target.value);
    }
  }
  else {
    target.value = dateMask(input);
  }
};

export const pasteNumberWithDoth = (e: ClipboardEvent<HTMLInputElement>): void => {
  e.preventDefault();
  const target = e.target as HTMLInputElement;
  const input = e.clipboardData.getData("Text").replace(/[^0-9]/ig, "");
  if (target.value) {
    const selectionStart = target.selectionStart ? target.selectionStart : 0;
    const selectionEnd = target.selectionEnd ? target.selectionEnd : 0;
    if (target.value.length === selectionEnd - selectionStart) {
      target.value = dateMask(input);
    }
    else {
      target.value = (target.value.substring(0, selectionStart) + input + target.value.substring(selectionEnd)).substring(0, 10);
      target.value = dateMask(target.value);
    }
  }
  else {
    target.value = dateMask(input);
  }
};


export default pasteNumberHandle;
