import { FC } from "react";
import clsx from "clsx";
import Link from "next/link";
import useTranslation from "next-translate/useTranslation";
import { SidebarItemType } from "../../types/sidebarConfigTypes";
import styles from "./SidebarItem.module.scss";

interface SidebarItemProps {
  userId?: number;
  studentDetailsId?: number;
  item: SidebarItemType;
  isWindow?: boolean;
  windowId?: string;
}

export const SidebarItem: FC<SidebarItemProps> = (props) => {
  const {
    item: { name, isCompleted, isActive, isHidden, isAccepted, hasErrors },
    isWindow = false,
    userId,
    studentDetailsId,
    windowId,
  } = props;

  const { t } = useTranslation("sidebar");

  const modes: Record<string, boolean> = {
    active: isActive,
    complete: isCompleted && !isActive,
    accepted: isAccepted && !isActive,
    error: hasErrors && !isActive,
  };

  const urlPathName = isWindow
    ? `/window/${windowId}/questionnaire/${userId}/${studentDetailsId}/${name}`
    : `/personal/${name}`;

  if (isHidden) {
    return null;
  }

  return (
    <li
      className={styles.list__item}
      data-mode={clsx(modes)}
      id={`sidebar_item_${name}`}
    >
      {isActive ? (
        <span>{t(`questionnaire.${name}`)}</span>
      ) : (
        <Link href={urlPathName}>{t(`questionnaire.${name}`)}</Link>
      )}
    </li>
  );
};
