import { ThunkApiType } from "@App/providers/StoreProvider";
import { createAsyncThunk } from "@reduxjs/toolkit";
import camelcaseKeys from "camelcase-keys";
import { FormActions } from "../../slice/FormSlice";

interface ChosenProgramsType {
    userId: number
    studentDetailsId: number
}

const errorMessage = "";

export const setChosenPrograms = createAsyncThunk<ISendProgram[], ChosenProgramsType, ThunkApiType<string>>(
  "form/setChosenPrograms",
  async (options, thunkApi) => {
    const { extra, dispatch, rejectWithValue } = thunkApi;
    const { userId, studentDetailsId } = options;

    try {
      const response = await extra.api.get(`/users/${userId}/studentDetails/${studentDetailsId}/programs`);
      const payload = camelcaseKeys(response.data.result.selected_programs, { deep: true }).sort((a: any, b: any) => a.priority - b.priority);

      if (!response.data) {
        throw new Error(errorMessage);
      }

      dispatch(FormActions.setChosenPrograms(payload));
      return response.data;
    } catch {
      rejectWithValue(errorMessage);
    }
  }
);
