import { AchievementCategoryEnum } from "@/lib/enums/dictionaries/achievementCategoryEnum";

const initialAchievement: StudentAchievementResponse = {
  id: 0,
  type: {
    id: 0,
    title: "",
  },
  award: null,
  certificate: null,
  grant: null,
  olympiad: null,
  patent: null,
  publication: null,
  professional: null,
  scans: [],
  year: null,

  status: null,
  comment: null,
};

export const achievementToRequest = (achievement: StudentAchievement, category: AchievementCategoryEnum): StudentAchievementResponse => {
  const data = { ...initialAchievement };
  data.id = achievement.id;
  data.type = { id: achievement.typeId, title: "" };
  data.scans = achievement.scans;
  if (achievement.year) data.year = achievement.year;
  switch (category) {
    case AchievementCategoryEnum.Publication:
      if (achievement.title && achievement.authors && achievement.year && achievement.journal && achievement.pages)
        data.publication = {
          title: achievement.title,
          authors: achievement.authors,
          year: achievement.year,
          journal: achievement.journal,
          journal_number: achievement.journalNumber,
          page_numbers: achievement.pages,
          doi: achievement.doi,
          volume: achievement.volume,
        };
      break;
    case AchievementCategoryEnum.Patent:
      if (achievement.title && achievement.authors && achievement.year && achievement.number)
        data.patent = {
          title: achievement.title,
          authors: achievement.authors,
          year: achievement.year,
          number: achievement.number,
        };
      break;
    case AchievementCategoryEnum.Grant:
      if (achievement.title && achievement.authors && achievement.year && achievement.issuedBy)
        data.grant = {
          title: achievement.title,
          authors: achievement.authors,
          year: achievement.year,
          issuedBy: achievement.issuedBy,
        };
      break;
    case AchievementCategoryEnum.Award:
      if (achievement.title && achievement.year && achievement.issuedBy)
        data.award = {
          title: achievement.title,
          year: achievement.year,
          issuedBy: achievement.issuedBy,
        };
      break;
    case AchievementCategoryEnum.Certificate:
      if (achievement.issuedBy && achievement.language && achievement.result && achievement.level)
        data.certificate = {
          certificate: achievement.issuedBy,
          language: achievement.language,
          result: achievement.result,
          level: achievement.level,
        };
      break;
    case AchievementCategoryEnum.Olympiad:
      if (achievement.year && achievement.olympiad)
        data.olympiad = {
          year: achievement.year,
          olympiad: achievement.olympiad,
        };
      break;
    case AchievementCategoryEnum.Professional:
      if (achievement.track)
        data.professional = {
          title: achievement.track,
        };
      break;
  }
  return data;
};
