import LogoRu from "@/public/images/symbols/logo_ru.svg";
import LogoEn from "@/public/images/symbols/logo_en.svg";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import clsx from "clsx";
import useTranslation from "next-translate/useTranslation";
import SocialFooter from "@Entities/Footer/Social/SocialFooter";
import Link from "@/components/_Common/Link";
import { useTypedSelector } from "@/hooks/useTypedSelector";
import { LanguagesLocalesEnum } from "@/types/LanguagesLocalesEnum";
import styles from "./Footer.module.scss";

const Footer = (): JSX.Element => {
  const { t, lang } = useTranslation("common");
  const { theme } = useTypedSelector(state => state.user);
  const [path, setPath] = useState<string>("");

  useEffect(() => {
    setPath(window.location.pathname);
  }, []);

  return (
    <footer className={clsx(
      styles.footer,
      path.indexOf("/window") > -1 && " pt-0",
      theme === "accessible" && styles.footer_accessible)}
    >
      <div className={clsx(
        styles.footer_top,
        theme === "accessible" && styles.footer_top_accessible,
        path.indexOf("/window") > -1 && "d-none"
      )}>
        <Container>
          <Row className={styles.footer__row}>
            <Col className={styles.footer__grid_left} lg={3} sm={6}>
              <p className={clsx(styles.footer__heading, theme === "accessible" && styles.footer__heading_accessible)}>
                {t("footer.col_first.heading")}
              </p>
              <div className='d-flex flex-wrap flex-column'>
                <div className={styles.footer__item}>
                  <p>
                    <Link className='link_footer' href={t("footer.col_first.link")} target='_blank'>
                      {t("footer.col_first.content.one")}
                    </Link>
                  </p>
                </div>
                <div className={styles.footer__item}>
                  <p>{t("footer.col_first.content.two.mondayFriday")}</p>
                  <p>{t("footer.col_first.content.two.saturday")}</p>

                </div>
              </div>
              <div className='d-none d-sm-block d-lg-none mt-1'>
                <SocialFooter />
              </div>
            </Col>

            <Col className={styles.footer__grid_middle} sm={6}>
              <p className={clsx(styles.footer__heading, theme === "accessible" && styles.footer__heading_accessible)}>
                {t("footer.col_second.heading")}
              </p>
              <div className='d-flex flex-wrap'>
                <Col className={styles.footer__grid_middle_left} lg={6}>
                  <div className={styles.footer__item}>
                    <p>{t("footer.col_second.content.one.label")}</p>
                    <p>
                      <span className='link_footer'>
                        {t("footer.col_second.content.one.phone")}
                      </span>
                      <Link className='link_footer' href={`mailto:${lang === LanguagesLocalesEnum.EN ? "international@itmo.ru" : "abit@itmo.ru"}`} target='_blank'>
                        {t("footer.col_second.content.one.mail")}
                      </Link>
                    </p>
                  </div>
                  <div className={styles.footer__item}>
                    <p>{t("footer.col_second.content.two.label")}</p>
                    <p>
                      <span className='link_footer'>
                        {t("footer.col_second.content.two.phone")}
                      </span>
                      <Link className='link_footer' href='mailto:aspirantura@itmo.ru' target='_blank'>
                        {t("footer.col_second.content.two.mail")}
                      </Link>
                    </p>
                  </div>
                </Col>
                <Col className={styles.footer__grid_middle_right} lg={6}>
                  <div className={styles.footer__item}>
                    <p>{t("footer.col_second.content.three.label")}</p>
                    <p>
                      <span className='link_footer'>
                        {t("footer.col_second.content.three.phone")}
                      </span>
                      <Link className='link_footer' href='mailto:int.students@itmo.ru' target='_blank'>
                        {t("footer.col_second.content.three.mail")}
                      </Link>
                    </p>
                    <p>
                      {t("footer.col_second.content.three.bachelorAndPostgraduate")}
                    </p>
                  </div>
                </Col>
              </div>
            </Col>

            <Col className={clsx(styles.footer__grid_right, "d-block d-sm-none d-lg-block")} lg={3}>
              <SocialFooter />
            </Col>
          </Row>
        </Container>
      </div>

      <div className={styles.footer_bottom}>
        <Container>
          <Row className={styles.footer__row}>
            <Col className={styles.footer__copyright_left} lg={4} sm={6} xl={3}>
              <div className='d-flex flex-wrap flex-column'>
                <div className={styles.footer__copyright}>
                  {t("footer.copyright.main")}
                </div>
                <div className={styles.footer__copyright}>
                  {t("footer.copyright.produced")}
                </div>
              </div>
              <Col className='d-none d-sm-block d-lg-none '>
                <a href="https://itmo.ru/" rel="noopener noreferrer" target='_blank'>
                  {lang === LanguagesLocalesEnum.RU ? (
                    <LogoRu
                      className={clsx(
                        styles.footer__itmoLogo,
                        theme === "accessible" &&
                        styles.footer__itmoLogo_accessible,
                        "d-block mt-1"
                      )}
                    />
                  ) : (
                    <LogoEn
                      className={clsx(
                        styles.footer__itmoLogo,
                        theme === "accessible" &&
                        styles.footer__itmoLogo_accessible,
                        "d-block mt-1"
                      )}
                    />
                  )}
                </a>
              </Col>
            </Col>

            <Col className='d-none d-lg-block' lg={2} xl={5}>
              <a href="https://itmo.ru/" rel="noopener noreferrer" target='_blank'>
                {lang === LanguagesLocalesEnum.RU ? (
                  <LogoRu
                    className={clsx(
                      styles.footer__itmoLogo,
                      theme === "accessible" &&
                      styles.footer__itmoLogo_accessible,
                      "mt-1 mx-auto d-block"
                    )}
                  />
                ) : (
                  <LogoEn
                    className={clsx(
                      styles.footer__itmoLogo,
                      theme === "accessible" &&
                      styles.footer__itmoLogo_accessible,
                      "mt-1 mx-auto d-block"
                    )}
                  />
                )}
              </a>
            </Col>

            <Col className={clsx(styles.footer__copyright_right, "pl-3")} sm={6} xl={4}>
              <div className='d-flex flex-wrap flex-column'>
                <div className={styles.footer__copyright}>
                  <Link className='link_footer' href={t("footer.copyright.domainLink")} target='_blank'>
                    {t("footer.copyright.domain")}
                  </Link>
                </div>
                <div className={styles.footer__copyright}>
                  <Link className='link_footer' href={t("footer.copyright.policiesLink")} target='_blank'>
                    {t("footer.copyright.policies")}
                  </Link>
                </div>
              </div>
            </Col>

            <Col className='d-block d-sm-none'>
              <a href="https://itmo.ru/" rel="noopener noreferrer" target='_blank'>
                {lang === LanguagesLocalesEnum.RU ? (
                  <LogoRu
                    className={clsx(
                      styles.footer__itmoLogo,
                      theme === "accessible" &&
                      styles.footer__itmoLogo_accessible,
                      "mt-1 mx-auto d-block"
                    )}
                  />
                ) : (
                  <LogoEn
                    className={clsx(
                      styles.footer__itmoLogo,
                      theme === "accessible" &&
                      styles.footer__itmoLogo_accessible,
                      "mt-1 mx-auto d-block"
                    )}
                  />
                )}
              </a>
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
