import { ThunkApiType } from "@App/providers/StoreProvider";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { UserActions } from "../../slice/UserSlice";

const errorMessage = "";

export const setConfiguration = createAsyncThunk<any, void, ThunkApiType<string>>(
  "user/configuration",
  async (data, thunkApi) => {
    const { extra, dispatch, rejectWithValue } = thunkApi;

    try {
      const response = await extra.api.get("/configurations");
      const configuration = response.data.result;

      if (!response.data) {
        throw new Error(errorMessage);
      }

      const payload = {
        year: configuration.reception_company_year,
        acceptance: configuration.reception_company_in_process,
        changeAchievement: configuration.allowed_postgraduate_change_achievement,
      };

      dispatch(UserActions.setConfiguration(payload));
      return response.data;
    } catch (error) {
      return rejectWithValue(errorMessage);
    }
  }
);
