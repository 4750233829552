enum UserRoleEnum {
  ROLE_USER = "ROLE_USER",
  ROLE_MANAGER = "ROLE_MANAGER",
  ROLE_WINDOW = "ROLE_WINDOW",
  ROLE_SUPER_ADMIN = "ROLE_SUPER_ADMIN",
  ROLE_WINDOW_FOREIGNER = "ROLE_WINDOW_FOREIGNER",
  ROLE_WINDOW_BACHELOR = "ROLE_WINDOW_BACHELOR",
  ROLE_WINDOW_MASTER = "ROLE_WINDOW_MASTER",
  ROLE_WINDOW_POSTGRADUATE = "ROLE_WINDOW_POSTGRADUATE",
}

export default UserRoleEnum;
