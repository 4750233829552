import { ResponseReturnType } from "../../types/garReqyestTypes";
import { FetchGarOptions } from "../../types/garSelectTypes";
import axios from "axios";

interface RegionGarType {
  id: number
  title: string
  type: number
  typeTitle: string
  garInfo: {
    garId: number
    garLevel: number
    garGuid: string
    garPath: string
  },
  readableTitle: string
}

interface RegionsGarResponseResponse {
  meta_data: {
    total: number
  }
  result: {
    districts: RegionGarType[]
  }
}

export const getGarDistrictsWithLimit = (options: FetchGarOptions): Promise<ResponseReturnType> => {
  const { page, limit, regionId, value } = options;

  let url = `https://gar.itmo.ru/api/v1/regions/${regionId}/districts?page=${page}&limit=${limit}`;

  if (value) {
    url = `https://gar.itmo.ru/api/v1/regions/${regionId}/districts?title=${value}&page=${page}&limit=${limit}`;
  }

  return axios.get<RegionsGarResponseResponse>(url).then(res => {
    return {
      total: res.data.meta_data.total,
      options: res.data.result.districts.map(district => ({
        label: district.readableTitle,
        value: district.id,
      })),
    };
  });
};
