import { AppRoutes, AppRoutesWithAuthProps, RoutesPath } from "@Shared/lib/routes";

export const routeConfig: Record<AppRoutes, AppRoutesWithAuthProps> = {
  // User routes
  personalIndex: {
    path: RoutesPath.personalIndex,
    userOnly: true,
    fullVersionOnly: true,
  },
  selectPrograms: {
    path: RoutesPath.selectPrograms,
    userOnly: true,
    fullVersionOnly: true,
  },
  personalAchievements: {
    path: RoutesPath.personalAchievements,
    userOnly: true,
  },
  personalAddress: {
    path: RoutesPath.personalAddress,
    userOnly: true,
  },
  personalData: {
    path: RoutesPath.personalData,
    userOnly: true,
  },
  personalDocuments: {
    path: RoutesPath.personalDocuments,
    userOnly: true,
  },
  personalDormitory: {
    path: RoutesPath.personalDormitory,
    userOnly: true,
  },
  personalEducation: {
    path: RoutesPath.personalEducation,
    userOnly: true,
  },
  personalExams: {
    path: RoutesPath.personalExams,
    userOnly: true,
  },
  personalItmostars: {
    path: RoutesPath.personalItmostars,
    userOnly: true,
  },
  personalKronbars: {
    path: RoutesPath.personalKronbars,
    userOnly: true,
  },
  personalMedical: {
    path: RoutesPath.personalMedical,
    userOnly: true,
  },
  personalMilitary: {
    path: RoutesPath.personalMilitary,
    userOnly: true,
  },
  personalOlympiads: {
    path: RoutesPath.personalOlympiads,
    userOnly: true,
  },
  personalPayment: {
    path: RoutesPath.personalPayment,
    userOnly: true,
  },
  personalPhoto: {
    path: RoutesPath.personalPhoto,
    userOnly: true,
  },
  personalPrivileges: {
    path: RoutesPath.personalPrivileges,
    userOnly: true,
  },
  personalPrograms: {
    path: RoutesPath.personalPrograms,
    userOnly: true,
    fullVersionOnly: true,
  },
  personalQuota: {
    path: RoutesPath.personalQuota,
    userOnly: true,
  },
  personalRelatives: {
    path: RoutesPath.personalRelatives,
    userOnly: true,
  },
  personalSpecialty: {
    path: RoutesPath.personalSpecialty,
    userOnly: true,
  },

  // Window routes
  windowIndex: {
    path: RoutesPath.windowIndex,
    windowOnly: true,
  },

  // Shared routes
  notFound: {
    path: RoutesPath.notFound,
    userOnly: true,
    windowOnly: true,
  },
};
