import {
  IApplication,
  IStudentDetailsApplication,
} from "@/types/window/application";
import { AxiosResponse } from "axios";
import snakecaseKeys from "snakecase-keys";
import { formLink } from "../config";
import ApplicationSearchFilterEnum from "../enums/application/searchFilter";
import ApplicationTransitionEnum from "../enums/application/transition";
import DegreeEnum from "../enums/degree";
import { IDictionaryGroupItem, IDictionaryIdItem, IDictionaryItem } from "./dictionary.service";
import {
  IAchievementFilters,
  IWindowStateQuestionnaireApplication,
  IWindowTargetAdmission,
} from "@/types/store";
import {
  EntranceData,
  IWindowAdmissionQuotaValues,
  IWindowCompetitiveGroupScore,
  IWindowDiscipline,
  IWindowDisciplineScore,
  IWindowProgramAdmissionQuota,
  IWindowProgramCost,
  IWindowProgramWithCost,
} from "@/types/window/forms";
import camelcaseKeys from "camelcase-keys";
import { captureException } from "@sentry/nextjs";
import { concatUrlParams } from "./functions";
import DictionaryAdmissionEnum from "../enums/dictionaries/admission";
import DictionaryFinancingEnum from "../enums/dictionaries/financing";
import { getEmptyCostEducation } from "../_common/getEmptyCostEducation";
import { ICommission } from "@/components/Window/СommissionsWindowForm";
import { CommissionToSend } from "@/components/Window/СommissionsWindowForm/Commission";
import { downloadBlob } from "../_common/helpers";
import { api } from "@Shared/api/createAxiosApi";
import { ApplicationTableFilters } from "@/components/Window/Tables/Applications/types/oldApplicationsTypes";
import { PaymentFile } from "@/components/_Common/Forms/PaymentDocuments";

const getEmailApplication = async (
  degree: string,
  page: number = 1,
  limit: number = 20,
  sortingColName?: string,
  sortingDirection?: string | undefined,
  searchQuery = "",
  searchFilter = ApplicationSearchFilterEnum.NAME,
  year?: number
): Promise<{ items: IStudentDetailsApplication[]; total: number }> => {
  let url = `/window/students/${degree}/with_original_education`;

  if (page) url += `?page=${page}`;

  if (limit) url += `&limit=${limit}`;

  if (year) url += `&year=${year}`;

  if (sortingColName)
    url += `&sort_by=${sortingColName}&order=${sortingDirection}`;

  switch (searchFilter) {
    case ApplicationSearchFilterEnum.NAME:
      url += `&name=${searchQuery}`;
      break;
    case ApplicationSearchFilterEnum.SNILS:
      url += `&snils=${searchQuery}`;
  }

  const response = await api.get(url);

  return {
    items: camelcaseKeys(response.data.result.students),
    total: response.data.meta_data.total,
  };
};

const getTargetAdmissions = async (
  degree: string,
  page: number = 1,
  limit: number = 20,
  sortBy?: "id" | "isConfirmed",
  order?: "ASC" | "DESC"
): Promise<{ items: IWindowTargetAdmission[]; total: number }> => {
  let url = `/window/target_admissions/${degree}?page=${page}&limit=${limit}`;

  if (sortBy) {
    url += `&sort_by=${sortBy}&order=${order}`;
  }

  const response = await api.get(url);

  return {
    items: camelcaseKeys(response.data.result.target_admissions),
    total: response.data.meta_data.total,
  };
};

const getApplications = async (
  limit: number = 20,
  statuses: string[] = [],
  roles: string[] = [],
  filters?: ApplicationTableFilters,
  degree?: IDegree
): Promise<{
  items: IApplication[] | IStudentDetailsApplication[] | ITargetUser[];
  total: number;
}> => {
  try {
    let url = `/window/users/studentDetails?limit=${limit}`;

    if (statuses.length > 0)
      statuses.forEach((status) => (url += `&statuses[]=${status}`));

    if (roles.length > 0)
      roles.forEach((role) => (url += `&windowRoles[]=${role}`));

    if (filters) {
      for (const [name, value] of Object.entries(snakecaseKeys(filters))) {
        const specialFilterOptions: { [key: string]: string } = {
          search: `&${value.at(1)}=${value.at(0)}`,
        };
        const defaulFilterOption = `&${name}=${value}`;
        const filterValue = specialFilterOptions[name] || defaulFilterOption;
        url += filterValue;
      }
    }

    if (degree) url += `&degree=${degree}`;

    const response = await api.get(url);
    let items: IApplication[] | IStudentDetailsApplication[] = [];

    if (response.data.result.applications) {
      const data = response.data.result.applications;
      (items as IApplication[]) = data.map((item: any) => ({
        id: item.id,
        status: item.status,
        window: item.window,
        number: item.number,
        sendAgreement: item.send_agreement,
        caseFormed: item.case_formed,
        user: {
          id: item.user.id,
          studentDetailsId: item.user.student_details_id,
          firstName: item.user.first_name,
          lastName: item.user.last_name,
          middleName: item.user.middle_name,
          email: item.user.email,
          document: item.user.passport_number,
          is_sspvo: item.user.is_sspvo,
          birthDate: item.user.birth_date,
          sendIsOriginal: item.user.is_send_original,
          degree: item.user.degree,
          isCompatriot: item.user.is_compatriot,
        },
        createdAt: item.created_at,
        updatedAt: item.updated_at,
      }));
    } else {
      const data = response.data.result.student_details;
      (items as IStudentDetailsApplication[]) = data.map((item: any) =>
        camelcaseKeys(item, { deep: true })
      );
    }

    return {
      items: items,
      total: response.data.meta_data.total as number,
    };
  } catch {
    return { items: [], total: 0 };
  }
};

const getProgramsWithCost = async (
  degree: IDegree,
  year: number
): Promise<IWindowProgramWithCost[]> => {
  try {
    const url = `/glossary/programs_with_costs?degree=${degree}&page=1&limit=100&year=${year}`;
    const response = await api.get(url);
    const data = camelcaseKeys(
      response.data.result.programs
    ) as IWindowProgramWithCost[];
    return data.map((item) => {
      if (item.costsEducations.length) return item;
      else
        return {
          ...item,
          costsEducations: [{ ...getEmptyCostEducation(year) }],
        };
    });
  } catch (err) {
    captureException(err);
    return [];
  }
};

const updateProgramsCosts = async (
  programsCosts: IWindowProgramCost[]
): Promise<boolean> => {
  try {
    const url = "/glossary/program/costs";
    await api.put(url, {
      costs_educations: programsCosts.map((item) => ({
        rf: item.rf,
        foreigner: item.foreigner,
        program: { id: item.id },
        costs_type: { id: item.typeId },
      })),
    });
    return true;
  } catch (err) {
    captureException(err);
    return false;
  }
};

const getProgramsWithAdmissionQuotas = async (
  degree?: string,
  year?: number,
  limit?: number
): Promise<IWindowProgramAdmissionQuota[]> => {
  try {
    const url = "/glossary/competitive_groups/admission_quotas";
    const urlParams: string[] = [];
    if (degree) urlParams.push(`degree=${degree}`);
    if (year) urlParams.push(`year=${year}`);
    if (limit) urlParams.push(`limit=${limit}`);
    const response = (await api.get(url + concatUrlParams(urlParams))).data
      .result.items;

    return response.map((item: any) => ({
      ...camelcaseKeys(item),
      admissionQuotas: { ...camelcaseKeys(item.admission_quotas) },
    }));
  } catch (err) {
    captureException(err);
    return [];
  }
};

const updateAdmissionQuotas = async (
  admissionQuotas: IWindowAdmissionQuotaValues[]
): Promise<boolean> => {
  try {
    const url = "/glossary/competitive_groups/admission_quotas";
    const response = await api.put(url, {
      items: admissionQuotas.map((item) => ({
        id: item.id,
        admission_quotas: {
          budget: item.budget,
          contract: item.contract,
          target_reception: item.targetReception,
          invalid: item.invalid,
          special_quota: item.specialQuota,
          contract_foreign: item.contractForeign,
          recommended_count: item.recommendedCount,
        },
      })),
    });
    return response.status.toString() === "204";
  } catch (err) {
    captureException(err);
    return false;
  }
};

const getProgramsWithoutGroups = async (
  degree: string,
  year?: number
): Promise<AxiosResponse | void> => {
  try {
    let url = `/glossary/programs/check_student_groups?degree=${degree}`;
    if (year) url += `&year=${year}`;
    const response = await api.get(url);
    return response.data.result.programs;
  } catch (e) {
    captureException(e);
  }
};

const getDisciplines = async (): Promise<IWindowDiscipline[]> => {
  try {
    const url = "/glossary/discipline";
    const response = await api.get(url);
    return camelcaseKeys(response.data.result.items);
  } catch (err) {
    captureException(err);
    return [];
  }
};

const getCompetitiveGroups = async (
  year: number,
  degree?: IDegree
): Promise<IWindowCompetitiveGroup[]> => {
  try {
    let url = `/glossary/competitive_groups?year=${year}`;
    if (degree) url += `&degree=${degree}`;
    const response = await api.get(url);
    return camelcaseKeys(response.data.result.items);
  } catch (err) {
    captureException(err);
    return [];
  }
};

const updateDisciplinesScores = async (
  disciplineScores: IWindowDisciplineScore[]
): Promise<boolean> => {
  try {
    const url = "glossary/disciplines/min_scores";
    const response = await api.patch(url, {
      items: disciplineScores.map((item) => ({
        id: item.id,
        min_score: item.minScore,
      })),
    });
    return response.status.toString() === "201";
  } catch (err) {
    captureException(err);
    return false;
  }
};

const updateCompetitiveGroupScores = async (
  competitiveScores: IWindowCompetitiveGroupScore[]
): Promise<boolean> => {
  try {
    const url = "glossary/competitive_groups/scores";
    const response = await api.patch(url, {
      items: competitiveScores.map((item) => ({
        id: item.id,
        pass_score: item.passScore,
        average_score: item.averageScore,
      })),
    });
    return response.status.toString() === "204";
  } catch (err) {
    captureException(err);
    return false;
  }
};

const startWorkOnApplication = (
  applicationId: number,
  windowId: number
): Promise<AxiosResponse> => {
  const url = "/window/applications/transition";
  return api.post(url, {
    application_id: applicationId,
    window_id: windowId,
    transition: ApplicationTransitionEnum.TO_WORK,
  });
};

const applyQuestionnaireForm = async (
  applicationId: number,
  form: string
): Promise<AxiosResponse> => {
  const url = `/window/applications/${applicationId}/submitted_form`;
  const requestData = {
    status: "accepted",
    form,
  };

  return api.post(url, requestData);
};

const rejectQuestionnaireForm = async (
  applicationId: number,
  form: string,
  comment: string
): Promise<AxiosResponse> => {
  const url = `/window/applications/${applicationId}/submitted_form`;
  const requestData = {
    status: "has_errors",
    form,
    comment,
  };

  return api.post(url, requestData);
};

const getUserApplication = async (
  userId: number,
  studentDetailsId: number
): Promise<IWindowStateQuestionnaireApplication | null> => {
  const url = `/users/${userId}/studentDetails/${studentDetailsId}/application`;
  const response = await api.get(url);

  const application = camelcaseKeys(response.data.result, { deep: true });

  if (!application.id) return null;
  return application as IWindowStateQuestionnaireApplication;
};

const rejectApplication = async (
  applicationId: number
): Promise<AxiosResponse> => {
  const url = "/window/applications/transition";
  return api.post(url, {
    application_id: applicationId,
    transition: ApplicationTransitionEnum.REJECT,
  });
};

const acceptApplication = async (
  applicationId: number
): Promise<AxiosResponse> => {
  const url = "/window/applications/transition";
  return api.post(url, {
    application_id: applicationId,
    transition: ApplicationTransitionEnum.FORM_A_CASE,
  });
};

const createEmptyApplication = async (
  userId: number,
  studentDetailsId: number,
  windowId: string
): Promise<AxiosResponse> => {
  const url = `/users/${userId}/studentDetails/${studentDetailsId}/application/force/windows/${windowId}`;
  return api.post(url);
};

const annulCase = async (applicationId: number): Promise<AxiosResponse> => {
  const url = "/window/applications/transition";
  return api.post(url, {
    application_id: applicationId,
    transition: ApplicationTransitionEnum.ANNUL_THE_CASE,
  });
};

const rejectAgreement = async (
  applicationId: number
): Promise<AxiosResponse> => {
  const url = `/window/applications/${applicationId}/withdraw`;

  return api.put(url);
};

const sendAgreement = async (
  applicationId: number,
  data: {
    financing: string;
    verified_agreement_file: string;
    selected_program_id: number;
  }
): Promise<AxiosResponse> => {
  const url = `/window/applications/${applicationId}/agreement`;

  return api.put(url, data);
};

const sendEnrollmentOrder = async (data: {
  admission_condition: string;
  date: string;
  degree: string;
  financing: string;
  is_hidden: boolean;
  number: string;
}): Promise<AxiosResponse> => {
  const sendedData = { ...data, status: "ready" };
  const url = "/glossary/enrollment_order";
  return api.post(url, sendedData);
};

const changeProgramCost = async (
  programId: number,
  typeId: number,
  data: { rf: number; foreigner: number }
): Promise<AxiosResponse> => {
  const url = `/glossary/program/${programId}/cost/${typeId}`;

  return api.put(url, data);
};

const getDirectionCodesDictionary = async (
  degree: DegreeEnum
): Promise<IDictionaryItem[]> => {
  const url = `/window/dictionaries/direction_codes/degree/${degree}`;
  const response = await api.get(url);

  // <value> propety from dictionary item is <id> so <number> type
  // we should convert it to <string> for correct props for <Select> copmponent
  return response.data.result.items.map((item: IDictionaryIdItem) => ({
    ...item,
    value: String(item.value),
  }));
};

const getDirectionPrograms = async (
  directionOrCommissionId: string,
  year?: string
): Promise<IDictionaryItem[]> => {
  const baseUrl = `/window/dictionaries/programs/direction/${directionOrCommissionId}`;
  const queryParams = new URLSearchParams();

  if (year) {
    queryParams.append("year", year);
  }

  const url = `${baseUrl}?${queryParams.toString()}`;

  const response = await api.get(url);

  // <value> propety from dictionary item is <id> so <number> type
  // we should convert it to <string> for correct props for <Select> copmponent
  return response.data.result.items.map((item: IDictionaryIdItem) => ({
    ...item,
    value: String(item.value),
  }));
};

const getGroupOrders = async (
  programId: string
): Promise<IDictionaryItem[]> => {
  const url = `/window/program/${programId}/student_group`;
  const response = await api.get(url);
  return response.data.result.student_groups.map((item: IDictionaryGroupItem) => ({
    value: item.id,
    label: item.title,
  }));
};

const getJournalMaster = async (
  fileParams: IWindowTableStatementsFileParamsMaster
): Promise<BlobPart[]> => {
  const url = "/window/entrance_tests_journal/master";

  const response = await api.get(url, {
    params: fileParams,
    responseType: "blob",
  });

  return [response.data];
};

const getJournalPostgraduate = async (
  fileParams: IWindowTableStatementsFileParamsPostgraduate
): Promise<BlobPart[]> => {
  const url = "/window/entrance_tests_journal/postgraduate";

  const response = await api.get(url, {
    params: fileParams,
    responseType: "blob",
  });

  return [response.data];
};

const getProtocolPostgraduate = async (
  fileParams: IWindowTableStatementsPostgraduateUserFile
): Promise<BlobPart[]> => {
  let url = "/window/entrance_test_protocol/postgraduate?";
  for (const [name, value] of Object.entries(snakecaseKeys(fileParams)))
    url += `&${name}=${value}`;

  const response = await api.get(url, { responseType: "blob" });

  return [response.data];
};

const sendIsOriginal = async (
  userId: number,
  studentDetailsId: number,
  isSendOriginal: boolean
): Promise<void> => {
  const url = `/users/${userId}/studentDetails/${studentDetailsId}/send_original/${
    isSendOriginal ? 1 : 0
  }`;

  return api.put(url);
};

const getOriginalEduDocument = async (
  userId: number,
  studentDetailsId: number
): Promise<{ is_original: boolean }> => {
  const url = `/users/${userId}/studentDetails/${studentDetailsId}/original_education_document`;
  const response = await api.get(url);
  return response.data.result;
};

const sendOriginalEduDocument = async (
  userId: number,
  studentDetailsId: number,
  isOriginal: boolean
): Promise<void> => {
  const url = `/users/${userId}/studentDetails/${studentDetailsId}/original_education_document`;

  return api.patch(url, { is_original: isOriginal });
};

const sendOriginalOnGosuslugi = async (
  userId: number,
  studentDetailsId: number,
  value: boolean
): Promise<void> => {
  const url = `users/${userId}/studentDetails/${studentDetailsId}/send_original_sspvo/${
    value ? 1 : 0
  }`;
  return api.put(url);
};

const sendNotarizedCopy = async (
  userId: number,
  studentDetailsId: number,
  isSendNotarizedCopy: boolean
): Promise<void> => {
  try {
    const url = `/users/${userId}/studentDetails/${studentDetailsId}/send_notarized_copy/${
      isSendNotarizedCopy ? 1 : 0
    }`;

    return api.put(url);
  } catch {}
};

const getUsersFile = async (degree: IDegree): Promise<Blob | null> => {
  try {
    const url = `/window/users/${degree}/all/download`;
    const response = await api.get(url, { responseType: "blob" });
    return response.data;
  } catch {
    return null;
  }
};

const getRating = async (
  degree: string,
  financing: string,
  id: number,
  sortBy: "scores" | "agreement",
  name?: string,
  snils?: string
): Promise<IWindowRatingItem[]> => {
  try {
    const url = `/rating/admin/${degree}/${financing}/${id}`;
    const urlParams: string[] = [];
    if (
      (degree === "bachelor" || degree === "postgraduate") &&
      financing === "budget"
    )
      urlParams.push(`sort=${sortBy}`);
    if (name) urlParams.push(`name=${name}`);
    if (snils) urlParams.push(`snils=${snils}`);

    const result = (await api.get(url + concatUrlParams(urlParams))).data
      .result;
    return camelcaseKeys(
      []
        .concat(
          result.without_entry_tests,
          result.by_special_quota,
          result.by_unusual_quota,
          result.by_target_quota,
          result.general_competition
        )
        .filter((item) => !!item)
    );
  } catch (err) {
    captureException(err);
    return [];
  }
};

const getAllDocuments = async (
  deegre: DegreeEnum,
  page: number,
  limit: number,
  directionCode: string,
  directionCodeProgram: string,
  financing: string,
  isCaseFormed: string,
  sortingColName: string,
  sortingDirection: "" | "ASC" | "DESC"
): Promise<{
  items:
    | IWindowTableDocumentsRowBachelor[]
    | IWindowTableDocumentsRowMaster[]
    | IWindowTableDocumentsRowPostgraduate[];
  total: number;
}> => {
  const url =
    `/window/users/${deegre}/all` +
    `?page=${page}` +
    `&limit=${limit}` +
    `&directionId=${directionCode}` +
    `&programId=${directionCodeProgram}` +
    `&financing=${financing}` +
    `&isCaseFormed=${
      isCaseFormed === "true" ? 1 : isCaseFormed === "false" ? 0 : ""
    }` +
    `&orderBy=${sortingColName}` +
    `&orderDirection=${sortingDirection.toLocaleLowerCase()}`;
  const response = await api.get(url);

  return {
    items: camelcaseKeys(response.data.result.items, { deep: true }),
    total: response.data.meta_data.total,
  };
};

const getOrders = async (
  filters: any
): Promise<{ items: IWindowTableOrdersRow[]; total: number }> => {
  const url = "/window/enrollment/selected_programs";
  const { search, ...params } = filters;

  if (search && search.length) {
    const [value, prop] = search;
    params[prop] = value;
  }

  const response = await api.get(url, { params });

  return {
    items: camelcaseKeys(response.data.result.enrollment_selected_programs, {
      deep: true,
    }),
    total: response.data.meta_data.total,
  };
};

const getDirectionCodes = async (
  degree: string,
  year?: string
): Promise<IDictionaryItem[]> => {
  const url = `/window/dictionaries/direction_codes/degree/${degree}${
    year ? `?year=${year}` : ""
  }`;
  const response = await api.get(url);

  return response.data.result.items;
};

const getProgramsManager = async (
  directionOrComission: string,
  year: string
): Promise<IDictionaryItem[]> => {
  const url = `/window/dictionaries/programs/direction/${directionOrComission}${
    year ? `?year=${year}` : ""
  }`;
  const response = await api.get(url);

  return response.data.result.items;
};

const updateOrderGroup = async (
  selectedProgramId: number,
  groupId: number
): Promise<AxiosResponse> => {
  const url = `/window/selectedProgram/${selectedProgramId}/group`;
  const response = await api.patch(url, { group_id: groupId });

  return response;
};

const getAchievements = async (
  page: number = 1,
  limit: number = 20,
  filters: IAchievementFilters,
  degree: string,
  publications?: boolean
): Promise<{ items: IWindowTableIARow[]; total: number }> => {
  let url = `/window/achievements/${degree}?page=${page}&limit=${limit}`;

  if (publications) url += "&has_all_publications=1";

  if (filters.search)
    url += `&${filters.search.filter}=${filters.search.query}`;

  for (const [name, value] of Object.entries(snakecaseKeys(filters))) {
    if (name !== "search" && value && !url.includes(name)) {
      url += `&${name}=${value}`;
    }
  }

  const response = await api.get(url);

  return {
    items: camelcaseKeys(response.data.result.achievements, { deep: true }),
    total: response.data.meta_data.total,
  };
};

const setStatusComment = async (
  achievementsId: number,
  payload: { status: string | null; comment: string | null }
): Promise<boolean> => {
  try {
    const url = `/window/achievements/${achievementsId}/status_comment`;
    const response = await api.patch(url, payload);
    return response.status === 204;
  } catch (error) {
    return false;
  }
};

const getPostgraduateCommissionsDictionary = async (): Promise<
  IDictionaryItem[]
> => {
  try {
    const url = "/window/dictionaries/direction_codes/commissions";
    const response = await api.get(url);

    return response.data.result.items;
  } catch {
    return [];
  }
};

const getFormDisciplines = async (
  degree: string,
  isAll: boolean,
  spo?: boolean,
  forUkraine?: boolean
): Promise<IDictionaryItem[]> => {
  let url = `/dictionaries/disciplines/${degree}`;

  if (isAll) url += "?all=true";

  if (spo) url += `?spo=${spo}`;

  if (forUkraine) url += `?for_ukraine=${forUkraine}`;

  const response = await api.get(url);

  return response.data.result.items;
};

const getDisciplineProgram = async (
  programId: string
): Promise<IDictionaryItem[] | void> => {
  try {
    const url = `/glossary/program/${programId}/disciplines`;
    const response = await api.get(url);

    return response.data.result;
  } catch (e) {
    captureException(e);
  }
};

const updateDisciplineProgram = async (
  programId: string,
  data:
    | EntranceData
    | {
        discipline_id: { discipline_id: string; stack: number };
        stack: number;
      }[]
    | {
        common_disciplines:
          | EntranceData
          | {
              discipline_id: { discipline_id: string; stack: number };
              stack: number;
            }[];
      }
): Promise<boolean> => {
  try {
    const url = `/glossary/program/${programId}/disciplines`;

    await api.put(url, data);
    return true;
  } catch (e) {
    return false;
  }
};

const updateTargetAdmissionIsConfirmed = async (
  userId: number,
  studentDetailsId: number,
  selectedProgramId: string,
  isConfirmed: boolean | null
): Promise<void> => {
  try {
    const url = `/users/${userId}/studentDetails/${studentDetailsId}/programs/${selectedProgramId}/target_admission_is_confirmed`;
    await api.patch(url, { isConfirmed: isConfirmed });
  } catch (e) {
    captureException(e);
  }
};

const addValidatedWindowForm = async (
  userId: string,
  windowIdDegree: string,
  transition: string
): Promise<void> => {
  try {
    const url = "/window/applications/transition";
    return await api.post(url, {
      window_id: Number(windowIdDegree),
      application_id: userId,
      transition: transition,
    });
  } catch (e) {
    captureException(e);
  }
};

const getComment = async (
  applicationId: number,
  type: string
): Promise<{ type: string; text: string } | undefined> => {
  try {
    const url = `/applications/${applicationId}/comment/${type}`;
    const response = await api.get(url);
    return response.data.result;
  } catch (e) {
    captureException(e);
  }
};

const submitComment = async (
  applicationId: number,
  data: { type: string; text: string }
): Promise<any> => {
  try {
    const url = `/window/applications/${applicationId}/comment`;
    await api.put(url, data);
  } catch (e) {
    captureException(e);
  }
};

const getSpecialConditions = async (
  degree: string,
  page: number = 1,
  limit: number = 20,
  sortBy?: "special_condition_is_confirmed" | "number",
  order: "ASC" | "DESC" = "ASC",
  year?: number
): Promise<{ items: IWindowSpecialCondition[]; total: number } | void> => {
  const getSortBy = (): string =>
    sortBy === "number" ? "number" : "special_condition_is_confirmed";
  try {
    const url = `/window/special_conditions/${degree}?page=${page}&limit=${limit}`;
    const urlParams: string[] = [];
    if (sortBy) urlParams.push(`&sort_by=${getSortBy()}&order=${order}`);
    if (year) urlParams.push(`&year=${year}`);

    const { result, meta_data } = (
      await api.get(url + concatUrlParams(urlParams))
    ).data;

    return {
      items: camelcaseKeys(result.special_conditions),
      total: meta_data.total,
    };
  } catch (err: unknown) {
    captureException(err);
  }
};

const updateStatusSpecialCondition = async (
  userId: number,
  studentDetailsId: number,
  status: boolean
): Promise<boolean> => {
  const url = `${formLink(userId, studentDetailsId)}/special_condition/confirm`;
  try {
    await api.patch(url, { is_confirmed: status });
    return true;
  } catch (err) {
    captureException(err);
    return false;
  }
};

export type IEnrollmentOrder = {
  id: number;
  admission_condition: keyof typeof DictionaryAdmissionEnum;
  financing: keyof typeof DictionaryFinancingEnum;
  subcondition: string;
  number: string;
  date: string;
  degree: IDegree;
  status: string;
  year: number | string;
  is_hidden: boolean;
};

const getEnrollmentOrder = async (params: {
  degree?: string;
  limit: number;
  year?: number;
  page: number;
}): Promise<IEnrollmentOrder[]> => {
  try {
    const url = "/glossary/enrollment_orders";
    const response = await api.get(url, { params });
    return response.data.result.items;
  } catch (e) {
    captureException(e);
    return Promise.reject(e);
  }
};

const getJournalBachelor = async (
  fileParams: IWindowTableStatementsFileParamsBachelor
): Promise<BlobPart[]> => {
  const url = "/window/entrance_tests_journal/bachelor";

  const response = await api.get(url, {
    params: fileParams,
    responseType: "blob",
  });

  return [response.data];
};

const getCommissions = async (
  year: string,
  type?: string
): Promise<ICommission[] | void> => {
  try {
    let url = `commissions?year=${year}`;

    if (type) url += `&commission_type=${type}`;

    const resonse = await api.get(url);

    return resonse.data.result.commissions;
  } catch {}
};

const deleteCommission = async (
  idToDelete: number,
  year: string
): Promise<boolean> => {
  try {
    await api.delete(`commisions/${idToDelete}?year=${year}`);
    return true;
  } catch {
    return false;
  }
};

const updateCommission = async (
  idToUpdate: number,
  changedData: CommissionToSend
): Promise<boolean> => {
  try {
    await api.patch(`commissions/${idToUpdate}`, changedData);
    return true;
  } catch {
    return false;
  }
};

const addCommission = async (newData: CommissionToSend): Promise<boolean> => {
  try {
    await api.post("commissions", newData);
    return true;
  } catch {
    return false;
  }
};

const deleteCommissionMember = async (
  idToDelete: number | string
): Promise<void> => {
  try {
    await api.delete(`commissions/members/${idToDelete}`);
  } catch {}
};

const getAcademicDegrees = async (): Promise<MapItem[]> => {
  try {
    const url = "dictionaries/academic_degrees";

    const resonse = await api.get(url);

    return resonse.data.result.items;
  } catch {
    return [];
  }
};

const getRanks = async (): Promise<MapItem[]> => {
  try {
    const url = "dictionaries/ranks";

    const resonse = await api.get(url);

    return resonse.data.result.items;
  } catch {
    return [];
  }
};

const getCommissionTypes = async (): Promise<MapItem[]> => {
  try {
    const url = "dictionaries/commission_types";

    const resonse = await api.get(url);

    return resonse.data.result.items;
  } catch {
    return [];
  }
};

const getCommissionMembersRoles = async (): Promise<string[]> => {
  try {
    const url = "commissions/members/roles";

    const resonse = await api.get(url);

    return resonse.data.result.roles;
  } catch {
    return [];
  }
};

const getStatisctics = async (params: {
  degree: IDegree;
  type: string;
}): Promise<any | void> => {
  try {
    const { degree, type } = params;
    const url = `window/statistic/${degree}/${type}`;

    const response = await api.get(url);

    return response.data.result;
  } catch (err: any) {
    captureException(err);
  }
};

const getManagerDegrees = async (userId: number): Promise<string[]> => {
  try {
    const url = `window/users/${userId}/degrees`;

    const response = await api.get(url);

    return response.data.result.degrees;
  } catch {
    return [];
  }
};

const sendAddressEntry = async (element: any): Promise<void> => {
  await api.put("/glossary/university", element);
};

const getAchievementFile = async (
  degree: IDegree,
  options: AchievementFileParams
): Promise<Blob | null> => {
  const { stars, ...params } = options;

  try {
    const url = `window/achievements${
      stars ? `/${stars}` : ""
    }/${degree}/download`;

    const response = await api.get(url, {
      responseType: "blob",
      headers: {
        "Content-Type":
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
      params,
    });
    return response.data;
  } catch {
    return null;
  }
};

const getPaymentFilesInfo = async (
  userId: number,
  studentDetailsId: number,
  isStudent: boolean = false
): Promise<PaymentFile[]> => {
  try {
    const url = `/files/users/${userId}/studentDetails/${studentDetailsId}/contracts/files/${
      isStudent ? "student" : "admin"
    }`;

    const response = await api.get(url);

    return response.data.result.files_contract;
  } catch {
    return [];
  }
};

const getPaymentFiles = async (
  userId: number,
  studentDetailsId: number,
  fileId: number,
  fileName: string,
  format: "pdf" | "docx" = "pdf",
  isStudent: boolean = false
): Promise<void> => {
  const url = `/files/users/${userId}/studentDetails/${studentDetailsId}/contracts/files/${
    isStudent ? "student" : "admin"
  }/${fileId}`;

  try {
    const response = await api.get(url, { responseType: "blob" });
    downloadBlob(
      new Blob([response.data], { type: `application/${format}` }),
      `${fileName}.${format}`
    );
  } catch {}
};

const getContractInfo = async (
  userId: number,
  studentDetailsId: number
): Promise<{
  number?: string;
  date_signing?: string;
  status?: string;
  id?: number;
}> => {
  try {
    const url = `/window/users/${userId}/studentDetails/${studentDetailsId}/contracts`;

    const response = await api.get(url);

    return response.data.result;
  } catch {
    return {};
  }
};

const updateContractInfo = async (
  userId: number,
  studentDetailsId: number,
  contractId: number,
  data: { number?: string; date_signing?: string; status?: string }
): Promise<void> => {
  try {
    const url = `/window/users/${userId}/studentDetails/${studentDetailsId}/contracts/${contractId}`;

    return api.put(url, {
      ...data,
    });
  } catch {}
};

const updateContractFile = async (
  userId: number,
  studentDetailsId: number,
  fileId: number,
  filePath: string
): Promise<void> => {
  try {
    const url = `/files/users/${userId}/studentDetails/${studentDetailsId}/contracts/files/admin/${fileId}`;

    return api.put(url, {
      url_new_file: filePath,
    });
  } catch {}
};

const sendSignedContractFile = async (
  userId: number,
  studentDetailsId: number,
  filePath: string
): Promise<void> => {
  try {
    const url = `/files/users/${userId}/studentDetails/${studentDetailsId}/contracts/files/student`;

    return api.post(url, {
      url_new_file: filePath,
    });
  } catch {}
};

export default {
  getApplications,
  getUserApplication,
  getTargetAdmissions,
  getEmailApplication,
  getProgramsWithCost,
  updateProgramsCosts,

  getProgramsWithAdmissionQuotas,
  updateAdmissionQuotas,

  getProgramsWithoutGroups,

  startWorkOnApplication,
  sendOriginalOnGosuslugi,
  applyQuestionnaireForm,
  rejectQuestionnaireForm,

  rejectAgreement,

  rejectApplication,
  acceptApplication,
  createEmptyApplication,
  annulCase,

  getDisciplineProgram,
  updateDisciplineProgram,

  getDisciplines,
  getFormDisciplines,
  updateDisciplinesScores,

  getCompetitiveGroups,
  updateCompetitiveGroupScores,

  getDirectionCodesDictionary,
  getDirectionPrograms,
  getGroupOrders,

  getJournalMaster,
  getJournalPostgraduate,

  getProtocolPostgraduate,
  getEnrollmentOrder,

  sendAgreement,
  sendIsOriginal,
  sendOriginalEduDocument,
  sendNotarizedCopy,
  sendEnrollmentOrder,

  getUsersFile,

  getAllDocuments,

  getOrders,
  getOriginalEduDocument,
  getDirectionCodes,
  getProgramsManager,
  updateOrderGroup,

  getAchievements,

  setStatusComment,

  getPostgraduateCommissionsDictionary,

  changeProgramCost,
  updateTargetAdmissionIsConfirmed,

  addValidatedWindowForm,

  submitComment,
  getComment,
  getSpecialConditions,
  updateStatusSpecialCondition,

  getRating,

  getJournalBachelor,

  getCommissions,
  deleteCommission,
  updateCommission,
  addCommission,
  deleteCommissionMember,
  getAcademicDegrees,
  getRanks,
  getCommissionTypes,
  getCommissionMembersRoles,
  getStatisctics,
  getManagerDegrees,
  sendAddressEntry,

  getAchievementFile,
  getPaymentFilesInfo,
  getPaymentFiles,
  getContractInfo,
  updateContractInfo,
  updateContractFile,
  sendSignedContractFile,
};
