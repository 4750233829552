import { FC, PropsWithChildren, ReactNode, SVGProps } from "react";
import { Button as BootstrapButton, ButtonProps as BootstrapButtonProps } from "react-bootstrap";
import clsx from "clsx";
import styles from "./Button.module.scss";

interface ButtonProps extends BootstrapButtonProps {
    children: ReactNode
    size?: "sm" | "lg"
    Icon?: FC<PropsWithChildren<SVGProps<SVGSVGElement>>>
    iconPosition?: "right" | "left"
    rounded?: boolean
    unborder?: boolean
    transform?: boolean
    disabled?: boolean
}

export const Button: FC<ButtonProps> = (props) => {
  const {
    children,
    Icon,
    className,
    size = "lg",
    variant = "primary",
    iconPosition = "left",
    rounded = false,
    unborder = false,
    transform = false,
    disabled = false,
    ...otherProps
  } = props;

  const mods: Record<string, boolean> = {
    [styles.button_rounded]: rounded,
    [styles.button_unborder]: unborder,
    [styles.button_transform]: transform,
    [styles.button_disabled]: disabled,
  };

  return (
    <BootstrapButton
      className={clsx(styles.button, mods, className)}
      size={size}
      variant={variant}
      {...otherProps}
    >
      {(Icon && iconPosition === "left") && <Icon className={clsx(styles[iconPosition], styles[variant])} />}
      {children}
      {(Icon && iconPosition === "right") && <Icon className={clsx(styles[iconPosition], styles[variant])} />}
    </BootstrapButton>
  );
};
