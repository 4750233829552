export function formatDate(date: Date, type: string = "default"): string {
  let day: string | number = date?.getDate();
  if (day < 10) day = "0" + day;

  let month: string | number = date.getMonth() + 1;
  if (month < 10) month = "0" + month;

  let year: string | number = date.getFullYear();
  if (year < 10) year = "0" + year;

  const hours: number = date.getHours();
  const minutes: number = date.getMinutes();
  const seconds: number = date.getSeconds();

  const timeString = `${hours < 10 ? "0" + hours : hours}:${minutes < 10 ? "0" + minutes : minutes}`;
  const fullTimeString = `${timeString}:${seconds < 10 ? "0" + seconds : seconds}`;

  let formattedDate = `${day}.${month}.${year}`;

  if (type === "withSeconds") {
    formattedDate += ` ${fullTimeString}`;
  } else if (type === "default") {
    formattedDate += ` ${timeString}`;
  }

  return formattedDate;
}
