import { createAsyncThunk } from "@reduxjs/toolkit";
import { ThunkApiType } from "@App/providers/StoreProvider";
import { api } from "@Shared/api/createAxiosApi";
import { AchievementCategoryEnum } from "@/lib/enums/dictionaries/achievementCategoryEnum";
import { achievementToRequest } from "@/lib/services/lib/convertAchievement";
import { formLink } from "@/lib/config";
import getErrorMessage from "@/lib/_common/getErrorMessage";

export interface CreateEntrantAchievementOptions {
  userId: number;
  studentDetailsId: number;
  achievement: StudentAchievement;
  category: AchievementCategoryEnum;
}

const createErrorMessage = "Ошибка при создании достижения";

export const createEntrantAchievement = createAsyncThunk<number | null, CreateEntrantAchievementOptions, ThunkApiType<string>>(
  "achievementSlice/createEntrantAchievement",
  async (options, thunkApi) => {
    const { userId, studentDetailsId, achievement, category } = options;
    const { rejectWithValue } = thunkApi;

    try {
      const response = await api.post(
        `${formLink(userId, studentDetailsId)}/forms/achievement`,
        achievementToRequest(achievement, category)
      );

      if (!response) {
        throw new Error(createErrorMessage);
      }

      return response.data.result.achievement_id;
    } catch (error: any) {
      const errorDetail = getErrorMessage(error?.response);
      return rejectWithValue(errorDetail);
    }
  }
);
