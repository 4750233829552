import FormLabel from "@/components/_Common/Form/Label";
import inputDateHandle from "@/lib/_common/inputs/inputDateHandle";
import { MimeType } from "@Shared/ui/InputFile/types";
import useTranslation from "next-translate/useTranslation";
import { ClipboardEvent, FC } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import { ValidFieldNames } from "../../PersonalFormAll/PersonalFormAll";
import pasteDateHandle from "@/lib/_common/inputs/pasteDateHandle";
import { PersonalDataType } from "@Features/PersonalForm/types/personalStateSchema";
import { InputFile } from "@Shared/ui/InputFile";

interface BirthCertificateFormProps {
  disabled?: boolean;
}

export const BirthCertificateForm: FC<BirthCertificateFormProps> = (props) => {
  const { disabled = false } = props;

  const { t } = useTranslation("personal/personally");

  const { control, formState, trigger } = useFormContext<PersonalDataType>();

  const handlePasteDate = (e: ClipboardEvent<HTMLInputElement>) => {
    const target = e.target as HTMLElement;
    const name = target.dataset.name as ValidFieldNames;

    if (name) {
      pasteDateHandle(e);
      trigger(name);
    }
  };

  return (
    <>
      <Row>
        <Form.Group as={Col} id="birthCertificateSeries" md={3}>
          <FormLabel required>
            {t("form.documents.personId.series.label")}
          </FormLabel>
          <Controller
            control={control}
            name="documents.personId.birthCertificate.series"
            render={({ field }) => (
              <Form.Control
                disabled={disabled}
                id="birthCertificateSeries"
                maxLength={10}
                placeholder={"XXX"}
                type="text"
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
          {formState.errors.documents?.personId?.birthCertificate?.series && (
            <Form.Control.Feedback type="invalid">
              {
                formState.errors.documents?.personId?.birthCertificate?.series
                  .message
              }
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Form.Group as={Col} id="birthCertificateNumber" md={3}>
          <FormLabel required>
            {t("form.documents.personId.number.label")}
          </FormLabel>
          <Controller
            control={control}
            name="documents.personId.birthCertificate.number"
            render={({ field }) => (
              <Form.Control
                disabled={disabled}
                id="birthCertificateNumber"
                maxLength={6}
                placeholder={t("form.documents.personId.number.placeholder")}
                type="text"
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
          {formState.errors.documents?.personId?.birthCertificate?.number && (
            <Form.Control.Feedback type="invalid">
              {
                formState.errors.documents?.personId.birthCertificate?.number
                  .message
              }
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Form.Group as={Col} id="personIdIssuedDateGroup" md={6}>
          <FormLabel required>
            {t("form.documents.personId.issuedDate.label")}
          </FormLabel>
          <Controller
            control={control}
            name="documents.personId.birthCertificate.issuedDate"
            render={({ field }) => (
              <Form.Control
                data-name={field.name}
                disabled={disabled}
                id="personIdIssueDate"
                maxLength={10}
                type="text"
                value={field.value}
                placeholder={t(
                  "form.documents.personId.issuedDate.placeholder"
                )}
                onChange={field.onChange}
                onInput={inputDateHandle}
                onPaste={handlePasteDate}
              />
            )}
          />
          {formState.errors.documents?.personId?.birthCertificate
            ?.issuedDate && (
            <Form.Control.Feedback type="invalid">
              {
                formState.errors.documents?.personId.birthCertificate.issuedDate
                  .message
              }
            </Form.Control.Feedback>
          )}
        </Form.Group>
      </Row>
      <Form.Group id="scansGroup">
        <FormLabel required>
          {t("form.documents.personId.scansCertificate.label")}
        </FormLabel>
        <Controller
          control={control}
          defaultValue={undefined}
          name="documents.personId.birthCertificate.scans"
          render={({ field }) => (
            <InputFile
              category="passport"
              formats={[MimeType.Pdf, MimeType.Jpeg, MimeType.Png]}
              id="personIdScans"
              maxNumFiles={5}
              maxSize={10}
              placeholder={t("form.documents.personId.scans.placeholder")}
              value={field.value}
              hint={t("form.documents.personId.scansCertificate.hint", {
                max: 10,
              })}
              onChange={field.onChange}
            />
          )}
        />
        {formState.errors.documents?.personId?.birthCertificate?.scans && (
          <Form.Control.Feedback type="invalid">
            {
              (
                formState.errors.documents?.personId?.birthCertificate
                  ?.scans as any
              )?.message
            }
          </Form.Control.Feedback>
        )}
      </Form.Group>
    </>
  );
};
