export interface NotificationType {
  title: string;
  text: string;
  variant: "error" | "success";
}

export enum NotificationActionEnum {
  ERROR = "setError",
  SUCCESS = "setSuccess",
  RESET = "resetNotification",
}

export interface NotificationAction {
  type: NotificationActionEnum;
  payload?: string;
}
