import { useState, useEffect, FC } from "react";
import { Container, Row } from "react-bootstrap";
import Toolbar from "@Entities/Header/ui/Toolbar/Toolbar";
import Logo from "@/components/_Common/Logo";
import BurgerIcon from "@/public/images/symbols/burger.svg";
import Close from "@/public/images/symbols/close.svg";
import clsx from "clsx";
import useTranslation from "next-translate/useTranslation";
import Burger from "@Entities/Header/ui/Burger/Burger";
import { useTypedSelector } from "@/hooks/useTypedSelector";
import Link from "next/link";
import globalRoutes from "@/lib/routes";
import useRoles from "@/hooks/useRoles";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedPrograms, setChosenPrograms } from "@Entities/Form";
import { getStudentDetailsActiveUserId, getUserId } from "@Entities/User";
import styles from "./Header.module.scss";

export interface HeaderProps {
  isWindow?: boolean
  isQuestionnaire?: boolean
}

export const Header: FC<HeaderProps> = (props) => {
  const { isWindow = false, isQuestionnaire = false } = props;

  const { t } = useTranslation("common");
  const { theme } = useTypedSelector(state => state.user);

  const dispatch = useDispatch();
  const studentDetailsId = useSelector(getStudentDetailsActiveUserId);
  const userId = useSelector(getUserId);
  const chousenPrograms = useSelector(getSelectedPrograms);

  const { active: currentWindow, questionnaire } = useTypedSelector(state => state.window);
  const { isUserManager } = useRoles();

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const body = document.body;
    if (showModal) body.classList.add("noScroll");
    else body.classList.remove("noScroll");
  }, [showModal]);

  const checkBurger = () => window.innerWidth > 768 && setShowModal(false);

  const pageLabel = (() => {
    if (isWindow && currentWindow && isQuestionnaire) {
      const windowLabel = t("header.logo_text_window") + (isUserManager ? "" : currentWindow.title);
      if (questionnaire) {
        const user = questionnaire.user;

        const userFioLabel = ` —  ${user.last_name} ${user.first_name} ${user.middle_name ?? ""}`;
        const applicationNumberLabel = questionnaire.application?.number ? ` —  ${questionnaire.application.number}` : "";

        const questionnaireLabel = windowLabel + applicationNumberLabel + userFioLabel;
        return questionnaireLabel;
      }

      return windowLabel;
    }

    return t("header.logo_text");
  })();

  useEffect(() => {
    if (showModal && typeof window !== "undefined") window.onresize = checkBurger;
    if (!chousenPrograms && !isWindow && studentDetailsId) {
      dispatch(setChosenPrograms({ userId, studentDetailsId }));
    }
  }, [studentDetailsId]);

  return (
    <header className={clsx(styles.header, theme === "accessible" && styles.header_accessible)}>
      <Container>
        <Row className='flex-nowrap justify-content-between'>
          <div className={styles.header__col_left}>
            <div className='d-flex d-md-none'>
              {
                showModal
                  ? <Close className={styles.header__burger} onClick={() => setShowModal(!showModal)} />
                  : <BurgerIcon className={styles.header__burger} onClick={() => setShowModal(!showModal)} />
              }
            </div>
            <div className={styles.header__logo} id="headerLogo">
              <Link className={styles.header__logo_link} href={(isWindow && currentWindow)
                ? globalRoutes.window(currentWindow.id).dashboard.to
                : globalRoutes.home}>
                <Logo />
              </Link>
              <div className={clsx(styles.header__logo_text, theme === "accessible" && styles.header__logo_text_accessible)}>
                {pageLabel}
              </div>
            </div>
          </div>

          <div className={clsx(styles.header__col_right, "d-flex justify-content-end align-items-center")}>
            <Toolbar className={"d-none d-md-flex"} isWindow={isWindow} />
          </div>
        </Row>
      </Container>

      <Burger showModal={showModal} />
    </header>
  );
};
