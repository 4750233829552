import { ReactNode } from "react";

export enum LanguagesEnum {
  RU = "ru",
  EN = "en",
}

export type AuthRequireOptionsType = {
  lang: LanguagesEnum;
  redirectUrl: string;
  accessToken: string | undefined;
  refreshToken: string | undefined;
  code: string | null;
};

export interface AuthDefaultValueType {
  isLoading: boolean;
  isAuthenticated: boolean;
  error: ImpossibleErrorType;
  user: IUserData | null;
  getAccessToken?: (() => void) | null;
  logoutHandler?: (() => void) | null;
}

export interface AuthProviderPropsType {
  lang?: LanguagesEnum;
  children?: ReactNode;
}

export type ImpossibleErrorType = string | null;

export interface RefreshResponse {
  result: {
    access_token: string;
    expires_in: number;
    refresh_token: string;
    token_type: "bearer";
    access_token_expires_in: string;
    refresh_token_expires_in: string;
  };
}

export interface RefreshBody {
  refresh_token?: string;
}

export interface LoginWithCodeResponse {
  result: {
    access_token: string;
    refresh_token: string;
    access_token_expires_in: string;
    refresh_token_expires_in: string;
  };
}
