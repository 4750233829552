enum DocumentTypesEnum {
  Passport = "Паспорт гражданина РФ",
  InternationalPassport = "Заграничный паспорт гражданина РФ",
  BirthCertificate = "Свидетельство о рождении",
  ShelterCertificate = "Свидетельство о предоставлении временного убежища",
  MilitaryIdReserve = "Военный билет офицера запаса",
  MilitaryId = "Военный билет военнослужащего",
  ConscriptionCertificate = "Удостоверение гражданина, подлежащего призыву на военную службу",
  TemporaryMilitaryId = "Временное удостоверение, выданное взамен военного билета",
  MilitaryCertificate = "Cправка взамен военного билета",
  ForeignPassport = "Паспорт гражданина иностранного государства",
  IDCard = "ID-карта",
}

export enum DocumentTypeEnum {
  passport = "passport",
  foreignPassport = "foreign_passport",
}

export default DocumentTypesEnum;
