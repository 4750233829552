import { ThunkApiType } from "@App/providers/StoreProvider";
import { createAsyncThunk } from "@reduxjs/toolkit";

interface UpdateStudentDetailsActiveOptions {
    userId: number
    studentDetailsId: number
    degree: IDegree | null
}

const errorMessage = "Ошибка при сохранении уровня образования";

export const updateStudentDetailsActive = createAsyncThunk<void, UpdateStudentDetailsActiveOptions, ThunkApiType<string>>(
  "userSlice/updateStudentDetailsActive",
  async (options, thunkAPI) => {
    const { userId, studentDetailsId, degree } = options;
    const { extra, rejectWithValue } = thunkAPI;

    try {
      const body = {
        degree,
        first_time_degree: false,
      };
      const response = await extra.api.put(`/users/${userId}/studentDetails/${studentDetailsId}/assign-active`, body);

      if (!response) {
        throw new Error(errorMessage);
      }
    } catch {
      return rejectWithValue(errorMessage);
    }
  }
);
