import { ResponseReturnType } from "../../types/garReqyestTypes";
import { FetchGarOptions } from "@Entities/AsyncGarSelect/types/garSelectTypes";
import axios from "axios";

interface RegionGarType {
  id: number
  title: string
  type: number
  typeTitle: string
  garInfo: {
    garId: number
    garLevel: number
    garGuid: string
    garPath: string
  },
  readableTitle: string
}

interface RegionsGarResponseResponse {
  meta_data: {
    total: number
  }
  result: {
    cities: RegionGarType[]
  }
}

const buildGarCitiesUrl = (options: FetchGarOptions): string => {
  const { page, limit, regionId, districtId, value } = options;

  const params = new URLSearchParams({
    page: page.toString(),
    limit: limit.toString(),
  });

  if (value) {
    params.set("title", value);
  }

  if (districtId) {
    params.set("district_id", districtId.toString());
  }

  return `https://gar.itmo.ru/api/v1/regions/${regionId}/cities?${params.toString()}`;
};

export const getGarCitiesWithLimit = (options: FetchGarOptions): Promise<ResponseReturnType> => {
  const url = buildGarCitiesUrl(options);

  return axios.get<RegionsGarResponseResponse>(url).then(res => {
    return {
      total: res.data.meta_data.total,
      options: res.data.result.cities.map(city => ({
        label: city.readableTitle,
        value: city.id,
      })),
    };
  });
};
