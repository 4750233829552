enum ApplicationTransitionEnum {
  TO_WORK = "transition_to_work", // взять в работу окном
  FORM_A_CASE = "transition_form_a_case", // создание дела (заявления)
  ANNUL_THE_CASE = "transition_annul_the_case",  // аннулирование дела
  REJECT = "transition_reject", // отказ в заведении дела (заявление не прошло проверку окном)
  SEND_CONSENT = "transition_send_consent", // абитуриент отправил согласие на контрактную программу
  WITHDRAWN_CONSENT = "transition_withdrawn_consent", // согласие отозвано абитуриентом
  CORRECT_BY_STUDENT = "transition_correct_by_student", // абитуриент исправил ошибки
}

export default ApplicationTransitionEnum;
