import { FC, PropsWithChildren, useRef, useState, SVGProps } from "react";
import styles from "./Toolbar.module.scss";
import TooltipCustom from "@/components/_Common/Tooltip";
import clsx from "clsx";
import useEventListener from "@/lib/_common/useEventLisener";

type ActionIconProps = {
  attention?: boolean,
  Icon: FC<PropsWithChildren<SVGProps<SVGSVGElement>>> | string;
  placement?: "bottom" | "auto" | "auto-start" | "auto-end" | "top" | "right" | "left" | "top-start" | "top-end" | "bottom-start" | "bottom-end" | "right-start" | "right-end" | "left-start" | "left-end" | undefined,
  className?: string,
  hint?: boolean,
};

const ActionIcon: FC<PropsWithChildren<ActionIconProps>> = (props): JSX.Element => {
  const { children, attention = false, Icon, placement, className, hint } = props;
  const [show, setShow] = useState(false);
  const target = useRef<HTMLDivElement | null>(null);

  const handleClick = (e: Event) => {
    if (target.current !== null) {
      const elem = e.target as HTMLDivElement;

      if (!target.current.contains(elem) && (document.querySelector(".tooltip") && !elem.closest(".tooltip"))) {
        setShow(false);
      }
    }
  };

  useEventListener("mouseover", handleClick);

  return (
    <>
      <span className={clsx(attention ? styles.toolbar__item_attention : "")} ref={target} onMouseEnter={() => setShow(!show)}>
        {typeof Icon === "string" ?
          (
            <img alt="user avatar" className={styles.toolbar__item_img} src={Icon} />
          ) :
          (
            <Icon className={clsx(
              styles.toolbar__item_icon,
              show && styles.toolbar__item_active,
              styles.toolbar__item_default,
              className && className
            )} />
          )}
      </span>
      <TooltipCustom id='overlay-example' placement={placement} show={show} target={target.current}>
        <div className={clsx(hint && styles.tooltip)}>
          {children}
        </div>
      </TooltipCustom>
    </>
  );
};

export default ActionIcon;
