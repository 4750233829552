import { FC, PropsWithChildren } from "react";
import styles from "./Link.module.scss";
import Link from "next/link";
import LinkIcon from "@/public/images/symbols/link.svg";
import clsx from "clsx";
import { useTypedSelector } from "@/hooks/useTypedSelector";

type LinkProps = {
  target?: "_blank" | "_self" | "_parent" | "_top",
  href: string,
  className?: "link_inline" | "link_policies" | "link_footer" | "link_simple",
};

const LinkCustom: FC<PropsWithChildren<LinkProps>> = (props): JSX.Element => {
  const { target = "_self", href, children, className, ...others } = props;
  const { theme } = useTypedSelector(state => state.user);

  return (
    <>
      {target === "_blank" ? (
        <a href={href} {...others} target={target} className={clsx(
          theme === "accessible" && styles.link_accessible,
          className ? styles[className] : styles.link
        )}>
          {children}
          {(className !== "link_policies" && className !== "link_footer") && <LinkIcon className={styles.icon} />}
        </a>
      ) : (
        <Link
          href={href}
          target={target}
          className={clsx(
            theme === "accessible" && styles.link_accessible,
            className ? styles[className] : styles.link
          )}
        >
          {children}
        </Link>
      )}
    </>
  );
};

export default LinkCustom;
