import { PayloadAction, createSlice, CaseReducer, SliceCaseReducers } from "@reduxjs/toolkit";
import { Contestant } from "../type/Olympiad.interface";

interface OlympiadState {
  olympiad: any;
  contestants: Contestant[];
  loading: boolean;
  error: string | null;
}

const addContestantsReducer: CaseReducer<OlympiadState, PayloadAction<Contestant[]>> = (state, action) => {
  state.contestants = action.payload;
};

const setLoadingReducer: CaseReducer<OlympiadState, PayloadAction<boolean>> = (state, action) => {
  state.loading = action.payload;
};

const setErrorReducer: CaseReducer<OlympiadState, PayloadAction<string | null>> = (state, action) => {
  state.error = action.payload;
};

const olympiadReducers: SliceCaseReducers<OlympiadState> = {
  addContestants: addContestantsReducer,
  setLoading: setLoadingReducer,
  setError: setErrorReducer,
};

const OlympiadSlice = createSlice({
  name: "olympiad",
  initialState: { contestants: [], olympiad: [], loading: false, error: null } as OlympiadState,
  reducers: olympiadReducers,
});

export const OlympiadReducer = OlympiadSlice.reducer;
export const OlympiadActions = OlympiadSlice.actions;
