import { ChangeEvent } from "react";

const inputNumberHandle = (e: ChangeEvent<HTMLInputElement>): void => {
  e.target.value = e.target.value.replace(/[^0-9]/ig, "");
};


export const inputNumberWithDoth = (e: ChangeEvent<HTMLInputElement>): void => {
  e.target.value = e.target.value.replace(/[^0-9.,]/ig, "");
};
export default inputNumberHandle;
