import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DataType, DictionaryType, FormStateSchema } from "../types/formSchema";

const initialState: FormStateSchema = {
  data: null,
  dictionaries: {} as
    | IRelativesDictionaries
    | IExamsDictionaries
    | IPrivilegesDictionaries
    | ISpecialtyDictionaries
    | IPersonalDataDictionaries
    | IMilitaryDictionaries
    | IOlympiadsDictionaries
    | IAchievementsDictionaries
    | IItmostarsDictionaries
    | IEducationDictionaries
    | UniversityRegions,
  sidebar: null,
  chosenPrograms: null,
};

export const FormSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    setChosenPrograms: (state, action: PayloadAction<any[]>) => {
      state.chosenPrograms = action.payload;
    },
    setSidebar: (state, action: PayloadAction<ISidebar | null>) => {
      state.sidebar = action.payload;
    },
    setDictionaries: (state, action: PayloadAction<DictionaryType>) => {
      state.dictionaries = action.payload;
    },
    setFormData: (state, action: PayloadAction<DataType>) => {
      state.data = action.payload;
    },
    addFormData: (state, action: PayloadAction<any>) => {
      state.data = { ...state.data, ...action.payload };
    },
    resetFormData: (state) => {
      state.data = null;
    },
    setUniversityRegions: (state, action: PayloadAction<DictionaryType>) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      state.dictionaries.universityRegions = action.payload;
    },
    deleteFile: (state, action: PayloadAction<number>) => {
      if (state.chosenPrograms) {
        state.chosenPrograms = state.chosenPrograms.map((program) =>
          action.payload ? { ...program, quotaFile: null } : program
        );
      }
    },
  },
});

export const { reducer: FormReducer } = FormSlice;
export const { actions: FormActions } = FormSlice;
