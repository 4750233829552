export const normativeToAccusativeCase = (item: string) : string => {
  const accusativeRuMap: {[key:string]: string}  = {
    "а": "у",
    "я": "ю",
  };

  const lastCharacter = item.substring(item.length);
  if ( !Object.keys(accusativeRuMap).includes(lastCharacter) ) return item;

  return item.substring(0, item.length - 1) + accusativeRuMap[lastCharacter];
};

export const downloadBlob = (blob: Blob, fileName: string) : void => {
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  setTimeout(() => { window.URL.revokeObjectURL(url); }, 100);
};

export const blobToString = (blob: Blob): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsText(blob);
    reader.onload = () => {
      resolve(reader.result as string);
    };
    reader.onerror = reject;
  });
};
