import { FC, PropsWithChildren } from "react";
import clsx from "clsx";
import { useTypedSelector } from "@/hooks/useTypedSelector";
import { useDispatch } from "react-redux";
import { UserActions } from "@Entities/User";
import styles from "./HeaderAccessibleItem.module.scss";

type HeaderAccessibleItemProps = {
  variant: "font" | "spacing" | "family" | "color",
  value: IAccessibleFont | IAccessibleSpacing | IAccessibleFamily | IAccessibleColor,
  text: string,
};

const HeaderAccessibleItem: FC<PropsWithChildren<HeaderAccessibleItemProps>> = (props): JSX.Element => {
  const { variant, value, text } = props;
  const { accessibleSettings } = useTypedSelector(state => state.user);
  const dispatch = useDispatch();

  const setValue = (value: string) => {
    if (variant) {
      const settings: IAccessibleSettings = {
        ...accessibleSettings,
        [variant]: value,
      };
      dispatch(UserActions.setAccessibleSettings(settings));
    }
  };

  return (
    <div className={clsx(
      styles.accessible__item,
      styles[variant],
      styles[`${variant}_${value}`],
      accessibleSettings[variant] === value && styles[`${variant}_active`]
    )}
    onClick={() => setValue(value)}>
      {text}
    </div>
  );
};

export default HeaderAccessibleItem;
