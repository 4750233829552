import DegreeEnum from "@/lib/enums/degree";
import { SidebarItemNames, SidebarItemType } from "../../types/sidebarConfigTypes";
import { SidebarConfig, SidebarResponseItem, SidebarResponseItemNames } from "../../types/sidebarRequestTypes";
import { bachelorSidebarConfig } from "../sidebarConfig/bachelorSidebarConfig";
import { masterSidebarConfig } from "../sidebarConfig/masterSidebarConfig";
import { postgraduateSidebarConfig } from "../sidebarConfig/postgraduateSidebarConfig";

export interface FetchSidebarAdapterOptions {
    data: SidebarResponseItem[]
    degree: IDegree
    config: SidebarConfig
}

export const fetchSidebarAdapter = (options: FetchSidebarAdapterOptions): SidebarItemType[] => {
  const { data, degree, config } = options;

  const sidebarItems = data.map((item) => {
    if (item.form === SidebarResponseItemNames.TARGET_ADMISSION) {
      return {
        ...item,
        form: SidebarItemNames.QUOTA,
      };
    }
    if (item.form === SidebarResponseItemNames.ITMO_ACHIEVEMENTS) {
      return {
        ...item,
        form: SidebarItemNames.ITMOSTARS,
      };
    }
    if (item.form === SidebarResponseItemNames.KRONBARS__ACHIEVEMENTS) {
      return {
        ...item,
        form: SidebarItemNames.KRONBARS,
      };
    }
    return item;
  });

  const submitedForms = new Set();
  const acceptedForms = new Set();
  const hasErrorForms = new Set();

  sidebarItems.forEach(({ status, form }) => {
    switch (status) {
      case "submitted":
        submitedForms.add(form);
        break;
      case "accepted":
        acceptedForms.add(form);
        break;
      case "has_errors":
        hasErrorForms.add(form);
        break;
    }
  });

  const activeFormName = window.location.toString().split("/").at(-1);

  switch (degree) {
    case DegreeEnum.BACHELOR:
      const bachelorPayload = bachelorSidebarConfig.map(item => {
        return ({
          ...item,
          isCompleted: submitedForms.has(item.name),
          isAccepted: acceptedForms.has(item.name),
          hasErrors: hasErrorForms.has(item.name),
          isActive: activeFormName === item.name,
          comment: sidebarItems.find((sidebarItem) => sidebarItem.form === item.name)?.comment,
        });
      })
        .filter((item) => {
          const { isCitizenRf, isAddressSpb, hasTargetAdmission, hasContractAdmission } = config;
          if (item.name === SidebarItemNames.PAYMENT) {
            return hasContractAdmission;
          }
          if (item.name === SidebarItemNames.DORMITORY) {
            return !isAddressSpb;
          }
          if (item.name === SidebarItemNames.MILITARY) {
            return isCitizenRf;
          }
          if (item.name === SidebarItemNames.QUOTA) {
            return hasTargetAdmission;
          }
          return true;
        });
      return bachelorPayload;
    case DegreeEnum.MASTER:
      const masterPayload = masterSidebarConfig.map(item => {
        return ({
          ...item,
          isCompleted: submitedForms.has(item.name),
          isAccepted: acceptedForms.has(item.name),
          hasErrors: hasErrorForms.has(item.name),
          isActive: activeFormName === item.name,
          comment: sidebarItems.find((sidebarItem) => sidebarItem.form === item.name)?.comment,
        });
      })
        .filter((item) => {
          const { isCitizenRf, isAddressSpb, hasTargetAdmission, hasContractAdmission } = config;

          if (item.name === SidebarItemNames.PAYMENT) {
            return hasContractAdmission;
          }
          if (item.name === SidebarItemNames.DORMITORY) {
            return !isAddressSpb;
          }
          if (item.name === SidebarItemNames.MILITARY) {
            return isCitizenRf;
          }
          if (item.name === SidebarItemNames.QUOTA) {
            return hasTargetAdmission;
          }
          return true;
        });
      return masterPayload;
    case DegreeEnum.POSTGRADUATE:
      const postgraduatePayload = postgraduateSidebarConfig.map(item => {
        return ({
          ...item,
          isCompleted: submitedForms.has(item.name),
          isAccepted: acceptedForms.has(item.name),
          hasErrors: hasErrorForms.has(item.name),
          isActive: activeFormName === item.name,
          comment: sidebarItems.find((sidebarItem) => sidebarItem.form === item.name)?.comment,
        });
      })
        .filter((item) => {
          const { isCitizenRf, isAddressSpb, hasTargetAdmission, hasContractAdmission } = config;

          if (item.name === SidebarItemNames.PAYMENT) {
            return hasContractAdmission;
          }
          if (item.name === SidebarItemNames.DORMITORY) {
            return !isAddressSpb;
          }
          if (item.name === SidebarItemNames.MILITARY) {
            return isCitizenRf;
          }
          if (item.name === SidebarItemNames.QUOTA) {
            return hasTargetAdmission;
          }
          return true;
        });
      return postgraduatePayload;
    default:
      return [];
  }
};
