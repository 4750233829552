import { ThunkApiType } from "@App/providers/StoreProvider";
import { createAsyncThunk } from "@reduxjs/toolkit";

interface StudentdetailsProps {
    userId: number
}

const errorMessage = "Не удалось получить данные об уровнях образования";

export const setStudentdetails = createAsyncThunk<IStudentDetails[], StudentdetailsProps, ThunkApiType<string>>(
  "user/studentdetails",
  async (data, thunkApi) => {
    const { extra, rejectWithValue } = thunkApi;
    const { userId } = data;

    try {
      const response = await extra.api.get(`/users/${userId}/studentDetails/`);
      const payload = response.data.result.student_details;

      if (!response.data) {
        throw new Error(errorMessage);
      }

      return payload;
    } catch {
      return rejectWithValue(errorMessage);
    }
  }
);
