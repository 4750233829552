const DateMask = (input: string): string => {
  input = input.replace(/[^0-9]/ig, "");
  if (input.length > 2 && input.length < 5) {
    input = input.substring(0, 2) + "." + input.substring(2);
  } else if (input.length > 4 && input.length < 7) {
    input = input.substring(0, 2) + "." + input.substring(2, 4) + "." + input.substring(4);
  } else if (input.length > 6) {
    input = input.substring(0, 2) + "." + input.substring(2, 4) + "." + input.substring(4, 8);
  }
  return input;
};

export default DateMask;
