import FormLabel from "@/components/_Common/Form/Label";
import DegreeEnum from "@/lib/enums/degree";
import inputDateHandle from "@/lib/_common/inputs/inputDateHandle";
import { MimeType } from "@Shared/ui/InputFile/types";
import useTranslation from "next-translate/useTranslation";
import { ClipboardEvent, FC } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { ValidFieldNames } from "../../PersonalFormAll/PersonalFormAll";
import pasteDateHandle from "@/lib/_common/inputs/pasteDateHandle";
import { PersonalDataType } from "@Features/PersonalForm/types/personalStateSchema";
import { InputFile } from "@Shared/ui/InputFile";
import { useSelector } from "react-redux";
import { getUserDegree } from "@Entities/User";
import { Checkbox } from "@Shared/ui/Checkbox";
import { divisionMask } from "@/lib/_common/inputs/divisionMask";
import inputNumberHandle from "@/lib/_common/inputs/inputNumberHandle";

interface PassportFormProps {
  disabled?: boolean;
}

export const PassportForm: FC<PassportFormProps> = (props) => {
  const { disabled = false } = props;

  const { t } = useTranslation("personal/personally");
  const degree = useSelector(getUserDegree);

  const { control, formState, trigger } = useFormContext<PersonalDataType>();
  const { documents } = useWatch<PersonalDataType>();
  const isHaveOldPassport = Boolean(documents?.isHaveOldPassport);

  const handlePasteDate = (e: ClipboardEvent<HTMLInputElement>) => {
    const target = e.target as HTMLElement;
    const name = target.dataset.name as ValidFieldNames;

    if (name) {
      pasteDateHandle(e);
      trigger(name);
    }
  };

  return (
    <>
      <Row>
        <Form.Group as={Col} id="personIdSeriesGroup" md={6}>
          <FormLabel required>
            {t("form.documents.personId.series.label")}
          </FormLabel>
          <Controller
            control={control}
            name="documents.personId.passport.series"
            render={({ field }) => (
              <Form.Control
                disabled={disabled}
                id="personIdSeries"
                maxLength={4}
                placeholder={t("form.documents.personId.series.placeholder")}
                type="text"
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
          {formState.errors.documents?.personId?.passport?.series && (
            <Form.Control.Feedback type="invalid">
              {formState.errors.documents?.personId?.passport?.series.message}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Form.Group as={Col} id="personIdNumberGroup" md={6}>
          <FormLabel required>
            {t("form.documents.personId.number.label")}
          </FormLabel>
          <Controller
            control={control}
            name="documents.personId.passport.number"
            render={({ field }) => (
              <Form.Control
                disabled={disabled}
                id="personIdNumber"
                maxLength={6}
                placeholder={t("form.documents.personId.number.placeholder")}
                type="text"
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
          {formState.errors.documents?.personId?.passport?.number && (
            <Form.Control.Feedback type="invalid">
              {formState.errors.documents?.personId?.passport?.number.message}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Form.Group as={Col} id="personIdIssuedDateGroup" md={6}>
          <FormLabel required>
            {t("form.documents.personId.issuedDate.label")}
          </FormLabel>
          <Controller
            control={control}
            name="documents.personId.passport.issuedDate"
            render={({ field }) => (
              <Form.Control
                data-name={field.name}
                disabled={disabled}
                id="personIdIssueDate"
                maxLength={10}
                type="text"
                value={field.value}
                placeholder={t(
                  "form.documents.personId.issuedDate.placeholder"
                )}
                onChange={field.onChange}
                onInput={inputDateHandle}
                onPaste={handlePasteDate}
              />
            )}
          />
          {formState.errors.documents?.personId?.passport?.issuedDate && (
            <Form.Control.Feedback type="invalid">
              {
                formState.errors.documents?.personId?.passport?.issuedDate
                  .message
              }
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Form.Group as={Col} id="divisionCodeGroup">
          <FormLabel required>
            {t("form.documents.personId.divisionCode.label")}
          </FormLabel>
          <Controller
            control={control}
            name="divisionCode"
            render={({ field }) => (
              <Form.Control
                data-name={field.name}
                disabled={disabled}
                id="divisionCode"
                maxLength={7}
                type="text"
                placeholder={t(
                  "form.documents.personId.divisionCode.placeholder"
                )}
                value={divisionMask(
                  field.value as string | number | string[] | undefined
                )}
                onInput={inputNumberHandle}
                onChange={(e) =>
                  field.onChange(divisionMask(e.target.value.trim()))
                }
              />
            )}
          />
          {formState.errors?.divisionCode && (
            <Form.Control.Feedback type="invalid">
              {formState.errors.divisionCode.message}
            </Form.Control.Feedback>
          )}
        </Form.Group>
      </Row>
      <Form.Group id="divisionNameGroup">
        <FormLabel required>
          {t("form.documents.personId.divisionName.label")}
        </FormLabel>
        <Controller
          control={control}
          name="documents.personId.passport.divisionName"
          render={({ field }) => (
            <Form.Control
              disabled={disabled}
              id="divisionName"
              type="text"
              value={field.value}
              placeholder={t(
                "form.documents.personId.divisionName.placeholder"
              )}
              onChange={field.onChange}
            />
          )}
        />
        {formState.errors.documents?.personId?.passport?.divisionName && (
          <Form.Control.Feedback type="invalid">
            {
              formState.errors.documents?.personId?.passport?.divisionName
                .message
            }
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <Form.Group id="scansGroup">
        <FormLabel required>
          {t("form.documents.personId.scans.label")}
        </FormLabel>
        <Controller
          control={control}
          defaultValue={undefined}
          name="documents.personId.passport.scans"
          render={({ field }) => (
            <InputFile
              category="passport"
              disabled={disabled}
              formats={[MimeType.Pdf, MimeType.Jpeg, MimeType.Png]}
              hint={t("form.documents.personId.scans.hint")}
              id="personIdScans"
              maxNumFiles={5}
              maxSize={10}
              placeholder={t("form.documents.personId.scans.placeholder")}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
        {formState.errors.documents?.personId?.passport?.scans && (
          <Form.Control.Feedback type="invalid">
            {
              (formState.errors.documents?.personId?.passport?.scans as any)
                ?.message
            }
          </Form.Control.Feedback>
        )}
      </Form.Group>
      {degree === DegreeEnum.BACHELOR && (
        <Form.Group id="isHaveOldPassportGroup">
          <Controller
            control={control}
            name="documents.isHaveOldPassport"
            render={({ field }) => (
              <Checkbox
                disabled={disabled}
                id="isHaveOldPassport"
                label={t("form.documents.personId.isHaveOldPassport")}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
        </Form.Group>
      )}
      {isHaveOldPassport && (
        <Row>
          <Form.Group as={Col} id="personIdSeriesOldGroup">
            <FormLabel required>
              {t("form.documents.personId.series.label")}
            </FormLabel>
            <Controller
              control={control}
              name="documents.passportOld.series"
              render={({ field }) => (
                <Form.Control
                  disabled={disabled}
                  id="passportOldSeries"
                  maxLength={4}
                  placeholder={t("form.documents.personId.series.placeholder")}
                  type="text"
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            {formState.errors.documents?.passportOld?.series && (
              <Form.Control.Feedback type="invalid">
                {formState.errors.documents.passportOld.series.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group as={Col} id="personIdNumberOldGroup">
            <FormLabel required>
              {t("form.documents.personId.number.label")}
            </FormLabel>
            <Controller
              control={control}
              name="documents.passportOld.number"
              render={({ field }) => (
                <Form.Control
                  disabled={disabled}
                  id="passportOldNumber"
                  maxLength={6}
                  placeholder={t("form.documents.personId.number.placeholder")}
                  type="text"
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            {formState.errors.documents?.passportOld?.number && (
              <Form.Control.Feedback type="invalid">
                {formState.errors.documents.passportOld.number.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Row>
      )}
    </>
  );
};
