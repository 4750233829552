import { AxiosError, AxiosResponse } from "axios";
import { apiPrefix, formLink, formFileNamesLink } from "@/lib/config";
import camelcaseKeys from "camelcase-keys";
import returnSizeFileText from "@/lib/_common/returnSizeFileText";
import { captureException } from "@sentry/nextjs";
import { emptyDictionaryItem } from "@/lib/_common/formsConditions";
import { ISendDormitory, IUserDormitory } from "@/types/forms/dormitory";
import { achievementToRequest } from "./lib/convertAchievement";
import { AchievementCategoryEnum } from "../enums/dictionaries/achievementCategoryEnum";
import { api } from "@Shared/api/createAxiosApi";
import { programsAction } from "@Features/ProgramsForm/model/slice";
import getErrorMessage from "../_common/getErrorMessage";

const emptyFormsState = [""];

const getConfigurations = (
  host: string,
  unique?: number | null
): Promise<{
  reception_company_year: number;
  reception_company_in_process: boolean;
  allowed_postgraduate_change_achievement: false;
}> => {
  const uri = `/configurations${unique ? `?unique=${unique}` : ""}`;

  return api
    .get(uri, {
      baseURL: `${host}${apiPrefix}`,
    })
    .then((response: AxiosResponse<ISuccessResponse>) => {
      return response.data.result;
    })
    .catch((err) => {
      captureException(err);
      return {
        reception_company_year: 2023,
        reception_company_in_process: true,
        allowed_postgraduate_change_achievement: false,
      };
    });
};

const getAvailablePrograms = (
  host: string,
  userId: number,
  studentDetailsId: number,
  olympiadType: string,
  profile: string,
  diploma: string,
  year: number,
  confirmingSubject?: string | null
): Promise<any> => {
  return api
    .get(
      `/users/${userId}/studentDetails/${studentDetailsId}/programs/olympiad/${olympiadType}/${profile}/${diploma}/${year}${confirmingSubject ? `/${confirmingSubject}` : ""}`,
      {
        baseURL: `${host}${apiPrefix}`,
      }
    )
    .then((res) => {
      return res.data.result;
    })
    .catch((err) => {
      captureException(err);
    });
};

// TO-DO delete isIngoreYear
const getStudentDetails = (userID: any, isIgnoreYear?: boolean): Promise<IStudentDetails[]> => {
  return api
    .get(`/users/${userID}/studentDetails/${isIgnoreYear ? `?is_ignore_year=${isIgnoreYear ? "1" : "0"}` : ""}`)
    .then((response: AxiosResponse<ISuccessResponse>) => {
      return response.data.result.student_details;
    })
    .catch((error: AxiosError) => {
      return error.message;
    });
};

const getStudentDetailActive = (host: string, userID: number): Promise<IStudentDetails> => {
  return api
    .get(`/users/${userID}/studentDetails/active`, {
      baseURL: `${host}${apiPrefix}`,
    })
    .then((response: AxiosResponse<ISuccessResponse>) => {
      return response.data.result;
    })
    .catch((error: AxiosError) => {
      return error.message;
    });
};

const putAssignStudentDetailActive = (host: string, userID: number, studentDetailsID: number): Promise<void> => {
  return api
    .put(
      `/users/${userID}/studentDetails/${studentDetailsID}/assign-active`,
      {},
      {
        baseURL: `${host}${apiPrefix}`,
      }
    )
    .then(() => {
      return Promise.resolve();
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error.response);
    });
};

const putCreateStudentDetail = (host: string, userID: number, degree: IDegree): Promise<void> => {
  return api
    .put(
      `/users/${userID}/studentDetails/`,
      { degree: degree, first_time_degree: false },
      {
        baseURL: `${host}${apiPrefix}`,
      }
    )
    .then(() => {
      return Promise.resolve();
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error.response);
    });
};

const getFormsStates = (userID: number, studentDetailsID: number): Promise<IFormsStatesResponse> => {
  return api
    .get(`${formLink(userID, studentDetailsID)}/submitted_forms`)
    .then((response: AxiosResponse<ISuccessResponse>) => {
      return camelcaseKeys(response.data.result);
    })
    .catch(() => emptyFormsState);
};

const getPersonally = (userID: number, studentDetailsID: number): Promise<IUserPersonalData> => {
  return api
    .get(`${formLink(userID, studentDetailsID)}/forms/personally`)
    .then((response: AxiosResponse<ISuccessResponse>) => {
      return camelcaseKeys(response.data.result, { deep: true });
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error.message);
    });
};

const sendPersonally = (userID: number, studentDetailsID: number, data: IUserPersonalData): Promise<void> => {
  return api
    .post(`${formLink(userID, studentDetailsID)}/forms/personally`, data)
    .then(() => {
      return Promise.resolve();
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error.response);
    });
};

const getAddress = (userID: number, studentDetailsID: number, host: string): Promise<IUserAddress> => {
  return api
    .get(`${formLink(userID, studentDetailsID)}/forms/address`, {
      baseURL: `${host}${apiPrefix}`,
    })
    .then((response: AxiosResponse<ISuccessResponse>) => {
      return camelcaseKeys(response.data.result);
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error.message);
    });
};

const sendAddress = (userID: number, studentDetailsID: number, data: IUserAddress): Promise<void> => {
  return api
    .post(`${formLink(userID, studentDetailsID)}/forms/address`, data)
    .then(() => {
      return Promise.resolve();
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error.response);
    });
};

/** @deprecated */
const getAchievements = (
  userID: number,
  studentDetailsID: number,
  host: string
): Promise<StudentAchievementsResponse> => {
  return api
    .get(`${formLink(userID, studentDetailsID)}/forms/achievements`, {
      baseURL: `${host}${apiPrefix}`,
    })
    .then((response: AxiosResponse<ISuccessResponse>) => {
      return camelcaseKeys(response.data.result, { deep: true });
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error.message);
    });
};

const getEntrantAchievements = async (
  userId: number,
  studentDetailsId: number
): Promise<StudentAchievement[] | null> => {
  try {
    const response = await api.get<LKResponse<StudentAchievementsResponse>>(
      `${formLink(userId, studentDetailsId)}/forms/achievement`
    );
    const responseData = camelcaseKeys(response.data.result, { deep: true });
    return responseData.achievements.map((item) => ({
      id: item.id,
      typeId: item.type.id,
      title: item.award?.title || item.grant?.title || item.patent?.title || item.publication?.title,
      authors: item.grant?.authors || item.patent?.authors || item.publication?.authors,
      year:
        item.award?.year ||
        item.grant?.year ||
        item.olympiad?.year ||
        item.patent?.year ||
        item.publication?.year ||
        item.year,
      number: item.patent?.number,
      journal: item.publication?.journal,
      journalNumber: item.publication?.journalNumber,
      volume: item.publication?.volume,
      doi: item.publication?.doi,
      pages: item.publication?.pageNumbers,
      issuedBy: item.award?.issuedBy || item.grant?.issuedBy || item.certificate?.certificate,
      olympiad: item.olympiad?.olympiad,
      track: item.professional?.title,
      language: item.certificate?.language,
      result: item.certificate?.result,
      level: item.certificate?.level,
      scans: item.scans,
      status: item.status,
      comment: item.comment,
    }));
  } catch (error) {
    return null;
  }
};

const createEntrantAchievement = async (
  userId: number,
  studentDetailsId: number,
  achievement: StudentAchievement,
  category: AchievementCategoryEnum
): Promise<number | null> => {
  try {
    const response = await api.post(
      `${formLink(userId, studentDetailsId)}/forms/achievement`,
      achievementToRequest(achievement, category)
    );
    return response.data.result.achievement_id;
  } catch (error) {
    return null;
  }
};

const updateEntrantAchievement = async (
  userId: number,
  studentDetailsId: number,
  achievement: StudentAchievement,
  category: AchievementCategoryEnum
): Promise<boolean> => {
  try {
    const response = await api.put(
      `${formLink(userId, studentDetailsId)}/forms/achievement/${achievement.id}`,
      achievementToRequest(achievement, category)
    );
    return response.status === 204;
  } catch (error) {
    return false;
  }
};

const deleteEntrantAchievement = async (
  userId: number,
  studentDetailsId: number,
  achievementId: number
): Promise<boolean> => {
  try {
    const response = await api.delete(`${formLink(userId, studentDetailsId)}/forms/achievement/${achievementId}`);
    return response.status === 200;
  } catch (error) {
    return false;
  }
};

const postEmptyAchievements = async (userId: number, studentDetailsId: number): Promise<boolean> => {
  try {
    const response = await api.post(`${formLink(userId, studentDetailsId)}/forms/achievement/empty`);
    return response.status === 200;
  } catch (error) {
    return false;
  }
};

const sendKronbars = (userID: number, studentDetailsID: number, data: IUserKronbars): Promise<void> => {
  return api
    .post(`${formLink(userID, studentDetailsID)}/forms/kronbars_achievement`, data)
    .then(() => {
      return Promise.resolve();
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error.response);
    });
};

const getKronbars = (userID: number, studentDetailsID: number, host: string): Promise<IUserKronbars> => {
  return api
    .get(`${formLink(userID, studentDetailsID)}/forms/kronbars_achievement`, {
      baseURL: `${host}${apiPrefix}`,
    })
    .then((response: AxiosResponse<ISuccessResponse>) => {
      return camelcaseKeys(response.data.result, { deep: true });
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error.message);
    });
};

const sendItmostars = (userID: number, studentDetailsID: number, data: IUserItmostars): Promise<void> => {
  return api
    .post(`${formLink(userID, studentDetailsID)}/forms/itmo_achievement`, data)
    .then(() => {
      return Promise.resolve();
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error.response);
    });
};

const getItmostars = (userID: number, studentDetailsID: number, host: string): Promise<IUserItmostars> => {
  return api
    .get(`${formLink(userID, studentDetailsID)}/forms/itmo_achievement`, {
      baseURL: `${host}${apiPrefix}`,
    })
    .then((response: AxiosResponse<ISuccessResponse>) => {
      const data: IBackendItmostars = camelcaseKeys(response.data.result, { deep: true });

      const formattedData: IUserItmostars = {
        kronbarsIsParticipated: data.isParticipated,
        kronbarsName: data.name,
        portfolio: data.portfolio,
        fromWhere: data.fromWhere,
        description: data.description,
        additional: data.additional,
        another: data.another,
        linkVk: data.linkVk,
        linkTelegram: data.linkTelegram,
        isHavePrize: data.isHavePrize,
      };

      return formattedData;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error.message);
    });
};

const sendRelatives = (userID: number, studentDetailsID: number, data: IUserRelatives): Promise<void> => {
  return api
    .post(`${formLink(userID, studentDetailsID)}/forms/relatives`, data)
    .then(() => {
      return Promise.resolve();
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error.response);
    });
};

const getRelatives = (userID: number, studentDetailsID: number, host: string): Promise<IUserRelatives> => {
  return api
    .get(`${formLink(userID, studentDetailsID)}/forms/relatives`, {
      baseURL: `${host}${apiPrefix}`,
    })
    .then((response: AxiosResponse<ISuccessResponse>) => {
      return camelcaseKeys(response.data.result, { deep: true });
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error.message);
    });
};

const getExams = (userID: number, studentDetailsID: number, host: string): Promise<IUserExams> => {
  return api
    .get(`${formLink(userID, studentDetailsID)}/forms/exams`, {
      baseURL: `${host}${apiPrefix}`,
    })
    .then((response: AxiosResponse<ISuccessResponse>) => {
      return camelcaseKeys(response.data.result);
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error.message);
    });
};

const sendPhoto = (userID: number, studentDetailsID: number, data: IUserPhoto): Promise<void> => {
  return api
    .put(`${formLink(userID, studentDetailsID)}/forms/photo`, data)
    .then(() => {
      return Promise.resolve();
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error.response);
    });
};

const getPhoto = (userID: number, studentDetailsID: number, host: string): Promise<IUserPhoto> => {
  return api
    .get(`${formLink(userID, studentDetailsID)}/forms/photo`, {
      baseURL: `${host}${apiPrefix}`,
    })
    .then((response: AxiosResponse<ISuccessResponse>) => {
      return response.data.result;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error.response);
    });
};

const sendMilitary = (userID: number, studentDetailsID: number, data: IUserMilitary): Promise<void> => {
  return api
    .post(`${formLink(userID, studentDetailsID)}/forms/military`, data)
    .then(() => {
      return Promise.resolve();
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error.response);
    });
};

const getMilitary = (userID: number, studentDetailsID: number, host: string): Promise<any> => {
  return api
    .get(`${formLink(userID, studentDetailsID)}/forms/military`, {
      baseURL: `${host}${apiPrefix}`,
    })
    .then((response: AxiosResponse<ISuccessResponse>) => {
      return camelcaseKeys(response.data.result);
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error.response);
    });
};

const sendMedical = (userID: number, studentDetailsID: number, data: IUserMedical): Promise<void> => {
  return api
    .post(`${formLink(userID, studentDetailsID)}/forms/medical`, data)
    .then(() => {
      return Promise.resolve();
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error.response);
    });
};

const getMedical = (userID: number, studentDetailsID: number, host: string): Promise<IUserMedical> => {
  return api
    .get(`${formLink(userID, studentDetailsID)}/forms/medical`, {
      baseURL: `${host}${apiPrefix}`,
    })
    .then((response: AxiosResponse<ISuccessResponse>) => {
      return response.data.result;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error.response);
    });
};

const sendEducation = (
  userID: number,
  studentDetailsID: number,
  degree: IDegree,
  data: IUserEducation
): Promise<void> => {
  return api
    .post(`${formLink(userID, studentDetailsID, degree)}/forms/education`, data)
    .then(() => {
      return Promise.resolve();
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error.response);
    });
};

const getEducation = (
  userID: number,
  studentDetailsID: number,
  degree: IDegree,
  host: string
): Promise<IUserEducationBachelor & IUserEducationMaster> => {
  return api
    .get(`${formLink(userID, studentDetailsID, degree)}/forms/education`, {
      baseURL: `${host}${apiPrefix}`,
    })
    .then((response: AxiosResponse<ISuccessResponse>) => {
      return camelcaseKeys(response.data.result, { deep: true });
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error.response);
    });
};

const getOlympiads = (userID: number, studentDetailsID: number, host: string): Promise<IUserOlympiads> => {
  return api
    .get(`${formLink(userID, studentDetailsID)}/forms/olympiads`, {
      baseURL: `${host}${apiPrefix}`,
    })
    .then((response: AxiosResponse<ISuccessResponse>) => {
      return camelcaseKeys(response.data.result, { deep: true });
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error.response);
    });
};

const sendOlympiads = (userID: number, studentDetailsID: number, data: IUserOlympiads): Promise<void> => {
  return api
    .post(`${formLink(userID, studentDetailsID)}/forms/olympiads`, data)
    .then(() => {
      return Promise.resolve();
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error.response);
    });
};

const sendPrivileges = (userID: number, studentDetailsID: number, data: IUserPrivileges): Promise<void> => {
  return api
    .post(`${formLink(userID, studentDetailsID)}/forms/privileges`, data)
    .then(() => {
      return Promise.resolve();
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error.response);
    });
};

const getSpecialConditions = async (userID: number, studentDetailsId: number, host: string): Promise<ISpecialty> => {
  return api
    .get(`${formLink(userID, studentDetailsId)}/forms/special_condition`, {
      baseURL: `${host}${apiPrefix}`,
    })
    .then((response: AxiosResponse<ISuccessResponse>) => (response.data.result !== 0 ? response.data.result : null))
    .catch(() => emptyDictionaryItem);
};

const sendCondition = (userID: number, studentDetailsId: number, data: ISpecialtyConditions): Promise<void> => {
  const url = `${formLink(userID, studentDetailsId)}/forms/special_condition`;

  return api
    .patch(url, data)
    .then(() => Promise.resolve())
    .catch((error: AxiosError) => {
      return Promise.reject(error.response);
    });
};

const getPrivileges = (userID: number, studentDetailsID: number, host: string): Promise<IUserPrivileges> => {
  return api
    .get(`${formLink(userID, studentDetailsID)}/forms/privileges`, {
      baseURL: `${host}${apiPrefix}`,
    })
    .then((response: AxiosResponse<ISuccessResponse>) => {
      return camelcaseKeys(response.data.result);
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error.response);
    });
};

const sendExams = (userID: number, studentDetailsID: number, data: IUserExams): Promise<void> => {
  return api
    .post(`${formLink(userID, studentDetailsID)}/forms/exams`, data)
    .then(() => {
      return Promise.resolve();
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error.response);
    });
};

const sendPayment = (userID: number, studentDetailsID: number, data: IUserPayment): Promise<void> => {
  return api
    .post(`${formLink(userID, studentDetailsID)}/forms/payment`, data)
    .then(() => {
      return Promise.resolve();
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error.response);
    });
};

const getPayment = (userID: number, studentDetailsID: number, host: string): Promise<IUserPayment> => {
  return api
    .get(`${formLink(userID, studentDetailsID)}/forms/payment`, {
      baseURL: `${host}${apiPrefix}`,
    })
    .then((response: AxiosResponse<ISuccessResponse>) => {
      return camelcaseKeys(response.data.result, { deep: true });
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error.response);
    });
};

const getDormitory = (userID: number, studentDetailsID: number, host: string): Promise<IUserDormitory> => {
  return api
    .get(`${formLink(userID, studentDetailsID)}/forms/dormitory`, {
      baseURL: `${host}${apiPrefix}`,
    })
    .then((response: AxiosResponse<ISuccessResponse>) => {
      return camelcaseKeys(response.data.result, { deep: true });
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error.response);
    });
};

const sendDormitory = (userID: number, studentDetailsID: number, data: ISendDormitory): Promise<void> => {
  return api
    .post(`${formLink(userID, studentDetailsID)}/forms/dormitory`, data)
    .then(() => {
      return Promise.resolve();
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error.response);
    });
};

const uploadFile = async (category: string, studentDetailsId: number, data: FormData): Promise<FileData | null> => {
  try {
    const response = await api.post(`/files/studentDetails/${studentDetailsId}/${category}`, data);
    return camelcaseKeys(response.data.result.file);
  } catch {
    return null;
  }
};

/** @deprecated use uploadFile */
const sendFile = (category: string, studentDetailsId: number, data: FormData): Promise<FileData> => {
  return api
    .post(`/files/studentDetails/${studentDetailsId}/${category}`, data)
    .then((response: AxiosResponse<ISuccessResponse>) => {
      return camelcaseKeys(response.data.result.file);
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error.response);
    });
};

const getFileName = (urls: string[] | string): Promise<{ originalNames: FileName[] }> => {
  return api
    .get(`/files/original-name?${formFileNamesLink(urls)}`)
    .then((response: AxiosResponse<ISuccessResponse>) => {
      return camelcaseKeys(response.data.result, { deep: true });
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error.response);
    });
};

const setInfoFiles = (
  newUrl: string[],
  setFileName: (val: string[]) => void,
  setFileSize: (val: string[]) => void,
  megabyte: string,
  kilobyte: string
): void => {
  if (typeof newUrl[0] !== undefined) {
    const resultSize: string[] = [];
    getFileName(newUrl).then((data: { originalNames: FileName[] }) => {
      setFileName(data.originalNames.map((item) => item.originalName));
    });

    newUrl.forEach((fileUrl) => {
      api
        .get(fileUrl, {
          baseURL: location.origin,
        })
        .then((response: AxiosResponse<ISuccessResponse>) => {
          resultSize.push(returnSizeFileText(response.headers["content-length"], megabyte, kilobyte));
          if (resultSize.length === newUrl.length) setFileSize(resultSize);
        })
        .catch((error: AxiosError) => {
          return Promise.reject(error.response);
        });
    });
  }
};

const getBlob = (link: string, host: string): Promise<Blob | ISuccessResponse> => {
  return api
    .get(link, {
      baseURL: host,
      responseType: "blob",
    })
    .then((response: AxiosResponse<ISuccessResponse>) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error.response);
    });
};

const getPrograms = (
  lang: string,
  year: number,
  degree: IDegree,
  host: string,
  page: number = 1,
  limit: number = 20,
  title?: string,
  types?: string[],
  faculties?: string[],
  opportunities?: string[],
  languages?: string[],
  disciplines?: string[],
  directions?: string[],
  priceFrom?: string,
  priceTo?: string
): Promise<IProgramsResponse> => {
  return api
    .get("programs/list", {
      params: {
        year: year,
        degree: degree,
        page: page,
        limit: limit,
        title: title || null,
        types: types || opportunities || null,
        languages: languages || null,
        faculties: faculties || null,
        disciplines: disciplines || null,
        directions: directions || null,
        price_from: priceFrom || null,
        price_to: priceTo || null,
      },
      headers: {
        "Accept-Language": lang,
      },
      baseURL: `${host}${apiPrefix}`,
    })
    .then((response: AxiosResponse<IProgramsResponse>) => {
      return camelcaseKeys(response.data, { deep: true });
    })
    .catch((error: AxiosError) => {
      captureException(error);
      return Promise.reject(error.response);
    });
};

const getManagerPrograms = async (
  year: number,
  degree?: IDegree
): Promise<IProgramsResponse> => {
  try {
    let url = `/programs/user_aware/list?year=${year}`;
    if (degree) url += `&degree=${degree}`;
    const response = await api.get(url);
    return camelcaseKeys(response.data);
  } catch (err) {
    captureException(err);
    return Promise.reject((err as AxiosError).response);
  }
};

const getUserPrograms = async (userID: number, studentDetailsID: number): Promise<Program[]> => {
  try {
    const response = await api.get(`${formLink(userID, studentDetailsID)}/programs`);
    return camelcaseKeys(response.data.result.selected_programs, { deep: true });
  } catch {
    return [];
  }
};

const getTargetAdmission = async (userID: number, studentDetailsID: number): Promise<any | unknown> => {
  try {
    const response = await api.get(`${formLink(userID, studentDetailsID)}/forms/target_admission`);
    return response.data.result;
  } catch (err) {
    captureException(err);
  }
};

/** @deprecated use addUserPrograms instead */
const updateUserPrograms = (
  userID: number,
  studentDetailsID: number,
  data: { selected_programs: Program[] }
): Promise<void> => {
  return api
    .put(`${formLink(userID, studentDetailsID)}/programs`, data)
    .then(() => Promise.resolve())
    .catch((error: AxiosError) => Promise.reject(error.response));
};

const changePriorityPrograms = (
  userID: number,
  studentDetailsID: number,
  data: { priorities: { priority: number; selected_program_id: number }[] }
): Promise<void> => {
  return api
    .patch(`${formLink(userID, studentDetailsID)}/programs/priority`, data)
    .then(() => Promise.resolve())
    .catch((error: AxiosError) => Promise.reject(error.response));
};

const addUserPrograms = async (userID: number, studentDetailsID: number, data: ISendProgram, dispatch: any): Promise<void> => {
  try {
    await api.post(`${formLink(userID, studentDetailsID)}/programs/add`, data);
    return await Promise.resolve();
  } catch (error: any) {
    dispatch(programsAction.setError(error?.response?.data?.errors?.violations?.at(0)?.title || error.message ));
    return await Promise.reject((error as AxiosError).response);
  }
};

const deleteUserPrograms = async (userID: number, studentDetailsID: number, selectedProgram: number): Promise<void> => {
  try {
    await api.delete(`${formLink(userID, studentDetailsID)}/programs/${selectedProgram}`);
    return await Promise.resolve();
  } catch (error) {
    return await Promise.reject((error as AxiosError).response);
  }
};

const changeFinancing = async (
  userID: number,
  studentDetailsID: number,
  selectedProgram: string | number,
  financing: string,
  dispatch: any
): Promise<void> => {
  const url = `${formLink(userID, studentDetailsID)}/programs/${selectedProgram}/financing`;

  try {
    await api.patch(url, { financing: financing });
    dispatch(programsAction.setIsSavedSuccessfull(true));
  } catch (error: any) {
    dispatch(programsAction.setError(getErrorMessage(error.response)));
  }
};

const updateFile = async (
  type: string,
  userId: number,
  studentDetailsId: number,
  selectedProgramId: string | number,
  file?: string
): Promise<void> => {
  const url = `${formLink(userId, studentDetailsId)}/programs/${selectedProgramId}/${type}`;
  return api
    .patch(url, { [`${type}`]: file })
    .then(() => Promise.resolve())
    .catch((error: AxiosError) => Promise.reject(error.response));
};

const changeAdmissionCondition = async (
  userId: number,
  studentDetailsId: number,
  selectedProgramId: string | number,
  admissionCondition: string,
  dispatch?: any,
  file?: string
): Promise<void> => {
  const url = `${formLink(
    userId,
    studentDetailsId
  )}/programs/${selectedProgramId}/admission_condition`;

  try {
    await api.patch(url, { admissionCondition: admissionCondition, quotaFile: file });
    dispatch?.(programsAction.setIsSavedSuccessfull(true));
  } catch (error: any) {
    dispatch?.(programsAction.setError(getErrorMessage(error.response)));
  }
};

const sendPriority = (
  userID: number,
  studentDetailsId: number,
  selectedProgramId: string | number,
  disciplineId: number
): Promise<void> => {
  const url = `${formLink(userID, studentDetailsId)}/programs/${selectedProgramId}/discipline`;

  return api
    .patch(url, { discipline_id: disciplineId })
    .then(() => Promise.resolve())
    .catch((error: AxiosError) => {
      return Promise.reject(error.response);
    });
};

const sendDissertation = (
  userId: number,
  studentDetailsId: number,
  selectedProgramId: string | number,
  dissertationTopic: string
): Promise<void> => {
  const url = `${formLink(userId, studentDetailsId)}/programs/${selectedProgramId}/dissertation_topic`;

  return api
    .patch(url, { dissertationTopic: dissertationTopic })
    .then(() => Promise.resolve())
    .catch((error: AxiosError) => {
      return Promise.reject(error.response);
    });
};

const sendSpecialtyExam = (
  userID: number,
  studentDetailsId: number,
  selectedProgramId: number,
  specialtyExam: string
): Promise<void> => {
  const url = `${formLink(userID, studentDetailsId)}/programs/${selectedProgramId}/specialty_exams`;

  return api
    .patch(url, { specialty_exams: specialtyExam })
    .then(() => Promise.resolve())
    .catch((error: AxiosError) => {
      return Promise.reject(error.response);
    });
};

const sendLanguageExam = (
  userID: number,
  studentDetailsId: number,
  selectedProgramId: number,
  language: string
): Promise<void> => {
  const url = `${formLink(userID, studentDetailsId)}/programs/${selectedProgramId}/language`;

  return api
    .patch(url, { language: language })
    .then(() => Promise.resolve())
    .catch((error: AxiosError) => {
      return Promise.reject(error.response);
    });
};

const sendLanguageExamDate = (
  userID: number,
  studentDetailsId: number,
  selectedProgramId: number,
  languageExam: string
): Promise<void> => {
  const url = `${formLink(userID, studentDetailsId)}/programs/${selectedProgramId}/language_exam`;

  return api
    .patch(url, { languageExam: languageExam })
    .then(() => Promise.resolve())
    .catch((error: AxiosError) => {
      return Promise.reject(error.response);
    });
};

const sendFaculty = (
  userId: number,
  studentDetailsId: number,
  selectedProgramId: number,
  faculty: number
): Promise<void> => {
  const url = `${formLink(userId, studentDetailsId)}/programs/${selectedProgramId}/faculty`;

  return api
    .patch(url, { id: faculty })
    .then(() => Promise.resolve())
    .catch((error: AxiosError) => {
      return Promise.reject(error.response);
    });
};

const sendApplication = (
  userID: number,
  studentDetailsId: number,
  applicationsDataSend:
    | { agreement: { student_agreement_file: string } }
    | { financing: string; agreement_file: string; selected_program_id: string }
): Promise<void> => {
  const url = `${formLink(userID, studentDetailsId)}/application`;

  return api
    .post(url, applicationsDataSend)
    .then(() => Promise.resolve())
    .catch((error: AxiosError) => {
      return Promise.reject(error.response);
    });
};

const sendSupervisor = (
  userID: number,
  studentDetailsId: number,
  selectedProgramId: number,
  supervisor: number
): Promise<void> => {
  const url = `${formLink(userID, studentDetailsId)}/programs/${selectedProgramId}/supervisor`;

  return api
    .patch(url, { supervisor_id: supervisor })
    .then(() => Promise.resolve())
    .catch((error: AxiosError) => {
      return Promise.reject(error.response);
    });
};

const sendTargetAdmission = (userID: number, studentDetailsID: number, data: ITargetAdmission): Promise<void> => {
  return api
    .post(`${formLink(userID, studentDetailsID)}/forms/target_admission`, data)
    .then(() => {
      return Promise.resolve();
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error.response);
    });
};

const sendNumberMail = (
  userID: number,
  studentDetailsId: number,
  selectedProgramId: number,
  numberMail: string
): Promise<void> => {
  const url = `${formLink(userID, studentDetailsId)}/programs/${selectedProgramId}/number_mail`;

  return api
    .patch(url, { number_mail: numberMail })
    .then(() => Promise.resolve())
    .catch((error: AxiosError) => {
      return Promise.reject(error.response);
    });
};

const sendCitizenship = (citizenship: string): Promise<void> => {
  const url = "/users/citizenship";

  return api
    .patch(url, { citizenship: citizenship })
    .then(() => Promise.resolve())
    .catch((error: AxiosError) => {
      return Promise.reject(error.response);
    });
};

const sendNumberDirection = (
  userID: number,
  studentDetailsId: number,
  selectedProgramId: number,
  numberDirection: string
): Promise<void> => {
  const url = `${formLink(userID, studentDetailsId)}/programs/${selectedProgramId}/number_direction`;

  return api
    .patch(url, { number_direction: numberDirection })
    .then(() => Promise.resolve())
    .catch((error: AxiosError) => {
      return Promise.reject(error.response);
    });
};

const sendDateMail = (
  userID: number,
  studentDetailsId: number,
  selectedProgramId: number,
  dateMail: string
): Promise<void> => {
  const url = `${formLink(userID, studentDetailsId)}/programs/${selectedProgramId}/date_mail`;

  return api
    .patch(url, { date_mail: dateMail })
    .then(() => Promise.resolve())
    .catch((error: AxiosError) => {
      return Promise.reject(error.response);
    });
};

const changeDirection = (
  userID: number,
  studentDetailsId: number,
  selectedProgramId: number,
  programId: number
): Promise<void> => {
  const url = `${formLink(userID, studentDetailsId)}/programs/${selectedProgramId}/program`;

  return api
    .patch(url, { program_id: programId })
    .then(() => Promise.resolve())
    .catch((error: AxiosError) => {
      return Promise.reject(error.response);
    });
};

const updateProgramTrack = async (
  userId: number,
  studentDetailsId: number,
  selectedProgramId: number,
  nameTrack: string
): Promise<AxiosResponse<void>> => {
  const url = `${formLink(userId, studentDetailsId)}/programs/${selectedProgramId}/track`;
  return await api.patch(url, { track: nameTrack });
};

const changeProgramCost = async (
  programId: number,
  typeId: number,
  data: { rf: string; foreigner: string }
): Promise<AxiosResponse> => {
  const url = `/glossary/program/${programId}/cost/${typeId}`;
  return api.put(url, data);
};

const updateProgramForeignerCost = async (
  year: number,
  degree: string,
  data: { sum: string }
): Promise<AxiosResponse> => {
  const url = `/glossary/program/cost/foreigner?${degree && `degree=${degree}`}&year=${year}`;
  return api.patch(url, data);
};

const updateConfiguration = async (year: number, acceptance: boolean, changeAchievement: boolean): Promise<boolean> => {
  try {
    const url = "/glossary/configurations";
    return (
      (
        await api.put(url, {
          reception_company_year: Number(year),
          reception_company_in_process: acceptance,
          is_allowed_postgraduate_change_achievement: changeAchievement,
        })
      ).status === 204
    );
  } catch (error) {
    return false;
  }
};

export default {
  sendAddress,
  sendPersonally,
  sendKronbars,
  sendItmostars,
  sendRelatives,
  sendPhoto,
  sendMilitary,
  sendMedical,
  sendEducation,
  sendOlympiads,
  sendPrivileges,
  sendExams,
  getStudentDetails,
  getStudentDetailActive,
  putAssignStudentDetailActive,
  putCreateStudentDetail,
  getAddress,
  uploadFile,
  sendFile,
  setInfoFiles,
  getFileName,
  getRelatives,
  getExams,
  getPhoto,
  getMedical,
  getBlob,
  getEducation,
  getMilitary,
  getPrivileges,
  getPersonally,
  getOlympiads,
  getFormsStates,
  getAchievements,
  getEntrantAchievements,
  createEntrantAchievement,
  updateEntrantAchievement,
  deleteEntrantAchievement,
  postEmptyAchievements,
  getItmostars,
  getKronbars,
  getPrograms,
  getManagerPrograms,
  getUserPrograms,
  getDormitory,
  getConfigurations,
  sendDormitory,
  getSpecialConditions,
  getTargetAdmission,
  getPayment,
  updateUserPrograms,
  addUserPrograms,
  deleteUserPrograms,
  changeFinancing,
  changeAdmissionCondition,
  changePriorityPrograms,
  changeDirection,
  updateProgramTrack,
  sendApplication,
  sendPriority,
  sendDissertation,
  sendSpecialtyExam,
  sendLanguageExam,
  sendLanguageExamDate,
  sendFaculty,
  sendSupervisor,
  sendPayment,
  sendNumberMail,
  sendNumberDirection,
  sendDateMail,
  sendTargetAdmission,
  sendCondition,
  updateFile,
  updateConfiguration,
  changeProgramCost,
  sendCitizenship,
  updateProgramForeignerCost,
  getAvailablePrograms,
};
