import { ResponseReturnType } from "../../types/garReqyestTypes";
import { FetchGarOptions } from "../../types/garSelectTypes";
import axios from "axios";

interface RegionGarType {
  id: number
  title: string
  type: number
  typeTitle: string
  garInfo: {
    garId: number
    garLevel: number
    garGuid: string
    garPath: string
  },
  readableTitle: string
}

interface RegionsGarResponseResponse {
  meta_data: {
    total: number
  }
  result: {
    regions: RegionGarType[]
  }
}

export const getGarRegionsWithLimit = (options: FetchGarOptions): Promise<ResponseReturnType> => {
  const { page, limit } = options;

  const url = `https://gar.itmo.ru/api/v1/regions?page=${page}&limit=${limit}`;

  return axios.get<RegionsGarResponseResponse>(url).then(res => {
    return {
      total: res.data.meta_data.total,
      options: res.data.result.regions.map(region => ({
        label: region.readableTitle,
        value: region.id,
      })),
    };
  });
};
