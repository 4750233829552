export interface SidebarResponseItem {
    id: number,
    form: SidebarResponseItemNames,
    status: "submitted" | "accepted" | "has_errors",
    comment: string | null
}

export enum SidebarResponseItemNames {
    // PROGRAMS = "programs",
    PERSONALLY = "personally",
    ADDRESS = "address",
    PHOTO = "photo",
    EDUCATION = "education",
    EXAMS = "exams",
    TARGET_ADMISSION = "target_admission",
    RELATIVES = "relatives",
    MEDICAL = "medical",
    MILITARY = "military",
    PRIVILEGES = "privileges",
    SPECIALTY = "specialty",
    OLYMPIADS = "olympiads",
    ACHIEVEMENTS = "achievements",
    ITMO_ACHIEVEMENTS = "itmo_achievements",
    KRONBARS__ACHIEVEMENTS = "kronbars_achievements",
    DORMITORY = "dormitory",
    // DOCUMENTS = "documents",
    // PAYMENT = "payment"
}

export interface FetchSidebarResponse {
    result: {
        submitted_forms: SidebarResponseItem[]
    }
}

export interface FetchSidebarConfigResponse {
    result: {
        has_target_admission: boolean,
        has_unusual_admission: boolean,
        has_contract_admission: boolean,
        is_address_spb: boolean,
        is_citizen_rf: boolean,
        citizenship: string
    }
}

export interface SidebarConfig {
    hasTargetAdmission: boolean,
    hasUnusualAdmission: boolean,
    hasContractAdmission: boolean,
    isAddressSpb: boolean,
    isCitizenRf: boolean
}
