export interface FetchGarOptions {
    regionId?: number | null
    districtId?: number | null
    cityId?: number | null
    streetId?: number | null
    value?: string
    page: number
    limit: number
}

export enum GarRequestsEnum {
    Countries = "countries",
    Regions = "regions",
    Districts = "districts",
    Cities = "cities",
    Streets = "streets",
    Houses = "houses"
}

export type RequestDataTypes =
    GarRequestsEnum.Countries |
    GarRequestsEnum.Regions |
    GarRequestsEnum.Districts |
    GarRequestsEnum.Cities |
    GarRequestsEnum.Streets |
    GarRequestsEnum.Houses;


export interface SelectOptionsType {
    label: string
    value: number | string | boolean
}

export interface CountriesSelectOption {
    value: string
    label: string
}
