import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { SidebarConfigType, SidebarStateSchema } from "../../types/sidebarConfigTypes";
import { sidebarEntityAdapter } from "../adapter/sidebarEntityAdapter";
import { fetchSubmitedForms } from "../services/fetchSubmitedForms";

const initialState: SidebarStateSchema = {
  isLoading: true,
  error: null,
  items: {
    ids: [],
    entities: {},
  },
};

export const SidebarSlice = createSlice({
  name: "sidebarSlice",
  initialState,
  reducers: {
    setSidebarItems: (state, action: PayloadAction<SidebarConfigType>) => {
      sidebarEntityAdapter.setAll(state.items, action);
    },
    setItemComplited: (state, action) => {
      sidebarEntityAdapter.updateOne(state.items, action.payload);
    },
  },
  extraReducers: build => {
    build.addCase(fetchSubmitedForms.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    build.addCase(fetchSubmitedForms.fulfilled, (state) => {
      state.isLoading = false;
    });
    build.addCase(fetchSubmitedForms.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export const { reducer: SidebarReducer } = SidebarSlice;
export const { actions: SidebarActions } = SidebarSlice;
