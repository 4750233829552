import { NextRouter } from "next/router";
import { Dispatch } from "redux";
import { SidebarItemType } from "@Entities/Sidebar/types/sidebarConfigTypes";
import { SidebarActions } from "@Entities/Sidebar/model/slice/SidebarSlice";

interface NextPageForLegacyForm {
    router: NextRouter,
    dispatch: Dispatch,
    sidebarLinks: SidebarItemType[]
}

export const nextPageForLegacyForm = (options: NextPageForLegacyForm): void => {
  const { router, sidebarLinks, dispatch } = options;

  const activeForm = router.pathname.split("/").at(-1);
  const activeFormIndex = sidebarLinks.findIndex(item => item.name === activeForm);
  const nextFormIndex = activeFormIndex + 1;
  const nextFormName = sidebarLinks[nextFormIndex].name;

  const payload = sidebarLinks.map(item => {
    if (item.name === activeForm) {
      return ({
        ...item,
        isCompleted: true,
        isActive: false,
      });
    }
    return ({
      ...item,
      isActive: item.name === nextFormName,
    });
  });
  dispatch(SidebarActions.setSidebarItems(payload));

  router.push(`/personal/${nextFormName}`);
};
