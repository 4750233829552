const clearThemeVariables = (): void => {
  const style = document.documentElement.style;
  const defaultFont = getComputedStyle(document.documentElement).getPropertyValue("--font-default");
  const defaultFontFamilyHeading = getComputedStyle(document.documentElement).getPropertyValue("--font-family-heading-default");
  const defaultFontFamilyBase = getComputedStyle(document.documentElement).getPropertyValue("--font-family-base-default");

  style.setProperty("--font-base", defaultFont);
  style.setProperty("--font-family-heading", defaultFontFamilyHeading);
  style.setProperty("--font-family-base", defaultFontFamilyBase);
  style.setProperty("--letter-spacing", "0px");
  style.setProperty("--form-border", "1px solid #d4d6d7");
};

export default clearThemeVariables;
