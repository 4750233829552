import { ThunkApiType } from "@App/providers/StoreProvider";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getDataAdapter } from "../../../lib/getDataAdapter";
import { PersonalDataType } from "../../../types/personalStateSchema";

interface PersonalOptions {
    userId: number
    studentDetailsId: number
}

const errorMessage = "";

export const FetchPersonalData = createAsyncThunk<PersonalDataType | null, PersonalOptions, ThunkApiType<string>>(
  "personal/getPersonalData",
  async (options, thunkApi) => {
    const { extra, rejectWithValue } = thunkApi;
    const { userId, studentDetailsId } = options;

    try {
      const response = await extra.api.get(`/users/${userId}/studentDetails/${studentDetailsId}/forms/personally`);

      if (!response) {
        throw new Error(errorMessage);
      }

      const payload = getDataAdapter(response.data.result);

      return payload;
    } catch {
      return rejectWithValue(errorMessage);
    }
  }
);
