import { ThemeTypes } from "@Shared/types/themeTypes";

const accessibleModeHandler = (theme: ThemeTypes, accessibleSettings: IAccessibleSettings): void => {
  const style = document.documentElement.style;
  const defaultFont = getComputedStyle(document.documentElement).getPropertyValue("--font-default");
  const defaultFontFamilyHeading = getComputedStyle(document.documentElement).getPropertyValue("--font-family-heading-default");
  const defaultFontFamilyBase = getComputedStyle(document.documentElement).getPropertyValue("--font-family-base-default");

  switch (accessibleSettings.font) {
    case "l": style.setProperty("--font-base", `${parseInt(defaultFont) * 1.5}px`);
      break;
    case "xl": style.setProperty("--font-base", `${parseInt(defaultFont) * 2}px`);
      break;
    default:
      style.setProperty("--font-base", defaultFont);
  }

  switch (accessibleSettings.spacing) {
    case "l": style.setProperty("--letter-spacing", "2px");
      break;
    case "xl": style.setProperty("--letter-spacing", "5px");
      break;
    default:
      style.setProperty("--letter-spacing", "0px");
  }

  switch (accessibleSettings.family) {
    case "arial":
      style.setProperty("--font-family-heading", "Arial, serif");
      style.setProperty("--font-family-base", "Arial, serif");
      break;
    case "times":
      style.setProperty("--font-family-heading", "Times New Roman, serif");
      style.setProperty("--font-family-base", "Times New Roman, serif");
      break;
    default:
      style.setProperty("--font-family-heading", defaultFontFamilyHeading);
      style.setProperty("--font-family-base", defaultFontFamilyBase);
  }

  style.setProperty("--form-border", "2px solid #2e3542");
};

export default accessibleModeHandler;
