var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = "/sentry-monitoring";
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"abitlk_frontend@0.0.0"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";


import { init, Replay } from "@sentry/nextjs";

init({
  dsn: "https://45b7c64c142b484a938d454ed0bdb919@sentry-intern.itmo.dev/4",
  tracesSampleRate: 1,
  debug: false,
  replaysOnErrorSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  environment: process.env.APP_ENV,
  initialScope: {
    tags: { "nextjs-side": "client" },
  },
  integrations: [
    new Replay({
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
});
