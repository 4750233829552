const getErrorMessage = (errorResponse: {
  data: { errors: { violations: { title: string }[] }; message: string };
}): string => {
  const message = errorResponse?.data?.errors?.violations
    ? errorResponse?.data?.errors?.violations[0]?.title
    : errorResponse?.data?.message;

  return message ?? "Internal server error, please try again later";
};

export default getErrorMessage;
