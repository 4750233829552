import { FinancingProgramEnum } from "@/components/_Common/Forms/Programs/types/programTypes";
import { StateSchema } from "@App/providers/StoreProvider";
import { SelectOptionsType } from "@Entities/AsyncGarSelect";

export const getSelectedPrograms = (state: StateSchema): Program[] | null => {
  return state.form.chosenPrograms;
};

export const getIsContarctProgramInclude = (state: StateSchema): boolean => {
  const contarctPrograms = state.form.chosenPrograms
    ?.find(program => program.financing === FinancingProgramEnum.Contract);

  return Boolean(contarctPrograms);
};

export const getUniversityRegions = (state: StateSchema): SelectOptionsType[] => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  return state.form.dictionaries.universityRegions || [];
};
