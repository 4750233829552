export const AUTH_CONTEXT = "AUTH_CONTEXT";
export const ACCESS_TOKEN_KEY = "access_token";
export const REFRESH_TOKEN_KEY = "refresh_token";
export const CODE_URL_PARAM_NAME = "code";

enum AuthErrorsMessagesKeysEnum {
  AuthWithCodeErrorMessage = "AuthWithCodeErrorMessage",
  AuthWithRedirectErrorMessage = "AuthWithRedirectErrorMessage"
}

export const AuthErrorsMessages: Record<AuthErrorsMessagesKeysEnum, string> = {
  AuthWithCodeErrorMessage: "auth with code error",
  AuthWithRedirectErrorMessage: "auth with redirect error",
};
