import { FC, PropsWithChildren } from "react";
import styles from "@/components/_Common/Form/Border/Border.module.scss";
import clsx from "clsx";

type FormBorderProps = {
  className?: "border_biggerMargin" | "border_dark" | "border_statement" | "border_noMargin";
};

const FormBorder: FC<PropsWithChildren<FormBorderProps>> = ({ className }): JSX.Element => {

  return (
    <div className={clsx(styles.border, className && styles[className])} />
  );
};

export default FormBorder;
