export const degreeList: IDegreeList[] = [
  {
    label: "Бакалавриат",
    value: "bachelor",
  },
  {
    label: "Магистратура",
    value: "master",
  },
  {
    label: "Аспирантура",
    value: "postgraduate",
  },
];

export const foreignerDegreeList: IDegreeList[] = [
  {
    label: "Bachelor",
    value: "bachelor",
  },
  {
    label: "Master",
    value: "master",
  },
  {
    label: "PhD",
    value: "postgraduate",
  },
];
