import { FC, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";

import ButtonCustom from "@/components/_Common/ButtonCustom";
import { DynamicModuleLoader } from "@Shared/lib/DynamicModuleLoader";
import { getUserAcceptence } from "@Entities/User";

import { SidebarItem } from "../SidebarItem/SidebarItem";
import {
  SidebarItemEnum,
  SidebarItemType,
} from "../../types/sidebarConfigTypes";
import { SidebarReducer } from "../../model/slice/SidebarSlice";
import {
  getSidebarLoading,
  sidebarSelectors,
} from "../../model/selectors/getSidebarItems";
import { fetchSubmitedForms } from "../../model/services/fetchSubmitedForms";
import UserIcon from "../../lib/icons/user.svg";
import FileIcon from "../../lib/icons/file.svg";
import PaymentIcon from "../../lib/icons/payment.svg";
import Favorite from "../../lib/icons/favorite.svg";
import { SidebarSkeleton } from "../SidebarSkeleton";
import ArrowIcon from "../../lib/icons/arrow_down.svg";
import styles from "./Sidebar.module.scss";

interface SidebarProps {
  userId: number;
  studentDetailsId: number;
  degree: IDegree;
  isWindow?: boolean;
}

const sidebarReducer = {
  sidebarReducer: SidebarReducer,
};

export const Sidebar: FC<SidebarProps> = (props) => {
  const { userId, studentDetailsId, degree, isWindow = false } = props;

  const { t } = useTranslation("sidebar");
  const router = useRouter();
  const dispatch = useDispatch();
  const isLoading = useSelector(getSidebarLoading);
  const acceptence = useSelector(getUserAcceptence);
  const sidebarLinks = useSelector(sidebarSelectors.selectAll);
  const isFetched = useRef(false);
  const windowId = router.asPath.split("/")[2];

  const handleClickBack = () => {
    if (!isWindow) {
      return router.push("/");
    } else {
      return router.push(`/window/${windowId}/tables/all`);
    }
  };

  useEffect(() => {
    if (!isFetched.current) {
      isFetched.current = true;
      dispatch(fetchSubmitedForms({ userId, studentDetailsId, degree }));
    }

    // Это нужно оставить, чтобы в дальнейщем оставить один запрос
    // if (!sidebarLinks.length) {
    //   dispatch(fetchSubmitedForms({ userId, studentDetailsId, degree }));
    // } else {
    //   const activeForm = window.location.toString().split("/").at(-1);
    //   const activeFormIndex = sidebarLinks.findIndex(item => item.name === activeForm);
    //   const currentFormIndex = activeFormIndex;
    //   const currentFormName = sidebarLinks[currentFormIndex].name;

    //   const payload = sidebarLinks.map(item => ({
    //     ...item,
    //     isActive: item.name === currentFormName,
    //   }));
    //   dispatch(SidebarActions.setSidebarItems(payload));
    // } [degree]
  }, []);

  if (isLoading) {
    return (
      <DynamicModuleLoader reducers={sidebarReducer}>
        <SidebarSkeleton />
      </DynamicModuleLoader>
    );
  }

  const programLinks = sidebarLinks.filter(
    (item: SidebarItemType) => item.type === SidebarItemEnum.Programs
  );
  const personalLinks = sidebarLinks.filter(
    (item: SidebarItemType) => item.type === SidebarItemEnum.Personal
  );
  const documentsLinks = sidebarLinks.filter(
    (item: SidebarItemType) => item.type === SidebarItemEnum.Documents
  );
  const paymentLinks = sidebarLinks.filter(
    (item: SidebarItemType) => item.type === SidebarItemEnum.Payment
  );

  return (
    <DynamicModuleLoader reducers={sidebarReducer}>
      <ButtonCustom
        Icon={ArrowIcon}
        className={styles.link__home}
        iconPosition="left"
        rounded={true}
        type="button"
        variant="outline-primary"
        onClick={handleClickBack}
      >
        {t("home.personal")}
      </ButtonCustom>

      {/** Programs links */}
      <h4 className={styles.link__title}>
        <Favorite className={styles.link__title_icon} />
        {t("programsTitle")}
      </h4>
      <ul className={styles.list} id="sidebar_block_programs">
        {programLinks.map((item: SidebarItemType) => (
          <SidebarItem
            isWindow={isWindow}
            item={item}
            key={item.name}
            studentDetailsId={studentDetailsId}
            userId={userId}
            windowId={windowId}
          />
        ))}
      </ul>

      {(acceptence || isWindow) && (
        <>
          {/** Personal links */}
          <h4 className={styles.link__title}>
            <UserIcon className={styles.link__title_icon} />
            {t("questionnaireTitle")}
          </h4>
          <ul className={styles.list} id="sidebar_block_questionnaire">
            {personalLinks.map((item: SidebarItemType) => (
              <SidebarItem
                isWindow={isWindow}
                item={item}
                key={item.name}
                studentDetailsId={studentDetailsId}
                userId={userId}
                windowId={windowId}
              />
            ))}
          </ul>

          {/** Payment links */}
          {!!paymentLinks.length && (
            <>
              <h4 className={styles.link__title}>
                <PaymentIcon className={styles.link__title_icon} />
                {t("paymentTitle")}
              </h4>
              <ul className={styles.list} id="sidebar_block_payment">
                {paymentLinks.map((item: SidebarItemType) => (
                  <SidebarItem
                    isWindow={isWindow}
                    item={item}
                    key={item.name}
                    studentDetailsId={studentDetailsId}
                    userId={userId}
                    windowId={windowId}
                  />
                ))}
              </ul>
            </>
          )}

          {/** Documents links */}
          <h4 className={styles.link__title}>
            <FileIcon className={styles.link__title_icon} />
            {t("applicationsTitle")}
          </h4>
          <ul className={styles.list} id="sidebar_block_application">
            {documentsLinks.map((item: SidebarItemType) => (
              <SidebarItem
                isWindow={isWindow}
                item={item}
                key={item.name}
                studentDetailsId={studentDetailsId}
                userId={userId}
                windowId={windowId}
              />
            ))}
          </ul>
        </>
      )}
    </DynamicModuleLoader>
  );
};
