import { FC, PropsWithChildren, useState } from "react";
import styles from "./Toolbar.module.scss";
import Exit from "@/public/images/symbols/exit.svg";
import Favorite from "@/public/images/symbols/favorite.svg";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "next/router";
import HideIcon from "@/public/images/symbols/hide.svg";
import ShowIcon from "@/public/images/symbols/show.svg";
import useTranslation from "next-translate/useTranslation";

import { useAuth } from "@Shared/hooks/useAuth";
import Cookies from "js-cookie";
import { UserActions, getUserTheme } from "@Entities/User";
import { ThemeTypes } from "@Shared/types/themeTypes";
import { getSelectedPrograms } from "@Entities/Form";
import { LanguagesLocalesEnum } from "@/types/LanguagesLocalesEnum";

interface ToolbarProps {
  className?: string,
  isWindow?: boolean
}

const Toolbar: FC<PropsWithChildren<ToolbarProps>> = props => {
  const { isWindow = false, className } = props;

  const { t } = useTranslation("common");

  const router = useRouter();
  const [active, setActive] = useState<boolean>(false);
  const { logoutHandler } = useAuth();

  const dispatch = useDispatch();
  const theme = useSelector(getUserTheme);
  const chosenPrograms = useSelector(getSelectedPrograms);

  const toggleThemeValue = () => {
    const newTheme: ThemeTypes = theme === "normal" ? "accessible" : "normal";
    Cookies.set("theme", newTheme);
    dispatch(UserActions.setTheme(newTheme));
  };

  const openProgramsPage = () => {
    if (document.location.href !== "/personal/programs") router.push(`/${router.locale}/personal/programs`);
  };

  const changeLang = async () => {
    switch (router.locale) {
      case LanguagesLocalesEnum.RU:
        router.push(router.asPath, router.asPath, { locale: "en" }).then(() => { location.reload(); });
        break;
      case LanguagesLocalesEnum.EN:
        router.push(router.asPath, router.asPath, { locale: "ru" }).then(() => { location.reload(); });
        break;
      default:
        return;
    }
  };

  return (
    <div className={clsx(styles.toolbar, className)}>
      {
        !isWindow &&
        <>
          <button className={clsx(styles.toolbar__lang, "mr-3")} id={"toolbarLanguageBtn"} onClick={changeLang}>
            {router.locales?.find(locale => router.locale !== locale)}
          </button>
          <div
            className={clsx(styles.toolbar__theme, theme === "accessible" && styles.toolbar__theme_accessible)}
            id={"toolbarProgramsBtn"}
            onClick={openProgramsPage}
          >
            <div className={styles.toolbar__item}>
              <Favorite className={clsx(
                styles.toolbar__favorite,
                styles.toolbar__icon,
                theme === "accessible" && styles.toolbar__icon_accessible)}
              />
              {
                chosenPrograms && chosenPrograms?.length > 0 &&
                <div className={styles.toolbar__item_counter}>
                  <p>
                    {chosenPrograms.length}
                  </p>
                </div>
              }
            </div>

            <div className='d-none d-lg-block pr-2'>
              {t("accessible.text.programs")}
            </div>
          </div>
          <div
            className={clsx(styles.toolbar__theme, theme === "accessible" && styles.toolbar__theme_accessible)}
            id={"toolbarAccessibleBtn"} onClick={toggleThemeValue}
            onMouseOut={() => setActive(false)}
            onMouseOver={() => setActive(true)}
          >
            <div className={styles.toolbar__item}>
              {
                theme === "accessible"
                  ? <HideIcon className={clsx(styles.toolbar__accessible, styles.toolbar__icon, styles.toolbar__icon_accessible)} />
                  : <ShowIcon className={clsx(styles.toolbar__accessible, styles.toolbar__icon)} />
              }
            </div>

            <div className='d-none d-lg-block pr-2'>
              {active ?
                theme === "accessible"
                  ? t("accessible.text.on")
                  : t("accessible.text.off") : <></>
              }
            </div>
          </div>
        </>
      }

      <div className={styles.toolbar__item} id={"toolbarExitBtn"}>
        <Exit
          className={clsx(
            styles.toolbar__exit,
            styles.toolbar__icon,
            theme === "accessible" && styles.toolbar__icon_accessible)}
          onClick={logoutHandler}
        />
      </div>
    </div>
  );
};

export default Toolbar;
