import { useEffect, useRef } from "react";

export default function useEventListener(eventName: keyof WindowEventMap, handler: (event: Event) => void): void {
  const savedHandler = useRef<(event: Event) => void>();

  useEffect(() => {
    if (savedHandler.current !== handler) {
      savedHandler.current = handler;
    }

    const eventListener = (event: Event): void => {
      if (savedHandler?.current) {
        savedHandler.current(event);
      }
    };

    document.addEventListener(eventName, eventListener);

    return () => {
      document.removeEventListener(eventName, eventListener);
    };
  }, []);
}
