import { FC, ReactNode, useMemo } from "react";
import { Provider } from "react-redux";
import { createReduxStore } from "../lib/store";
import { useRouter } from "next/router";

interface StoreProviderProps {
    children: ReactNode
}

export const StoreProvider: FC<StoreProviderProps> = ({ children }) => {
  const router = useRouter();

  // Удаление этого useMemo приведет к пересозданию store
  const store = useMemo(() => (
    createReduxStore({ router })
  ), []);

  return (
    <Provider store={store}>{children}</Provider>
  );
};
