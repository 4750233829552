import { FC } from "react";
import useTranslation from "next-translate/useTranslation";
import { Button } from "@Shared/ui/Button";
import styles from "./PageError.module.scss";
import { DefaultLayout } from "@Widgets/DefaultLayout";

export const PageError: FC = () => {
  const { t } = useTranslation("errorpage");

  const onBackHomeClick = () => {
    window.location.replace("/");
  };

  return (
    <div className={styles.wrapper}>
      <DefaultLayout>
        <div className={styles.main}>
          <div className={styles.content}>
            <h1>{t("errorBoundary.title")}</h1>
            <div className={styles.controls}>
              <Button onClick={onBackHomeClick}>{t("errorBoundary.backToMainPage")}</Button>
            </div>
          </div>
        </div>
      </DefaultLayout>
    </div>
  );
};
