
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { Col, Container, Row } from "react-bootstrap";
import ButtonCustom from "@/components/_Common/ButtonCustom";
import NextImage from "next/image";
import styles from "../styles/error.module.scss";
import Img from "@/public/images/humans.png";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";
export default function Custom404(): JSX.Element {
    const router = useRouter();
    const { t } = useTranslation("errorpage");
    return (<Container className={styles.ErrorPage}>
      <Row className="align-items-center">
        <Col className="d-flex flex-column align-items-start" md={5}>
          <span className={styles.ErrorPage__number}>{t("404.fileError")}</span>
          <h1 className={styles.ErrorPage__title}>{t("404.notFound")}</h1>
          <p className={styles.ErrorPage__paragraph}>
            {t("404.text")}
          </p>
          <ButtonCustom className={styles.ErrorPage__btn} onClick={() => router.push("/")}>
            {t("404.linkMain")}
          </ButtonCustom>
        </Col>
        <Col className="d-flex mt-5 mt-md-0 justify-content-md-end" md={{ span: 6, offset: 1 }}>
          <NextImage alt={"humans"} src={Img}/>
        </Col>
      </Row>
    </Container>);
}

    async function __Next_Translate__getStaticProps__191c3108537__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/404',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__191c3108537__ as getStaticProps }
  