import { FC, useEffect, useState } from "react";
import Close from "public/images/symbols/close.svg";

import styles from "./Tooltip.module.scss";
import { Portal } from "@Shared/lib/Portal";
import clsx from "clsx";

interface TooltipProps {
    title: string
    text: string
    variant?: "success" | "error"
    showTimeDuration?: number
    onShowTimeout?: () => void
    bigSize?: boolean
}

const ANIMATION_DURATION = 200;
const TOOLTIP_SHOW_DURATION = 3000;

export const Tooltip: FC<TooltipProps> = (props) => {
  const {
    title,
    text,
    variant = "success",
    showTimeDuration = TOOLTIP_SHOW_DURATION,
    onShowTimeout,
    bigSize,
  } = props;

  const [isMounted, setIsMounted] = useState(false);

  const handleClose = () => {
    setIsMounted(false);
  };

  const mods: Record<string, boolean> = {
    [styles.tooltip_active]: isMounted,
    [styles.tooltip_error]: variant === "error",
    [styles.tooltip_success]: variant === "success",
  };

  useEffect(() => {
    setTimeout(() => {
      setIsMounted(true);
    }, ANIMATION_DURATION);

    const fullDuration = showTimeDuration + ANIMATION_DURATION;
    setTimeout(() => {
      setIsMounted(false);
      onShowTimeout && onShowTimeout();
    }, fullDuration);
  }, []);

  const baseIdName = `tooltip_${variant}`;

  return (
    <Portal>
      <div className={clsx(styles.tooltip, mods, bigSize && styles.tooltip_big)} id={`${baseIdName}_wrapper`}>
        <div className={styles.tooltip__wrapper}>
          <div className={styles.tooltip__controls}>
            <span
              className={styles.tooltip__close}
              onClick={handleClose}
            >
              <Close />
            </span>
          </div>
          <div className={styles.tooltip__content}>
            <span className={clsx(styles.tooltip__title, bigSize && styles.tooltip__title_big)} id={`${baseIdName}_title`}>{title}</span>
            <span className={clsx(styles.tooltip__text, bigSize && styles.tooltip__text_big)} id={`${baseIdName}_text`}>{text}</span>
          </div>
        </div>
      </div>
    </Portal>
  );
};
