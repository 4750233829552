import { StateSchema } from "@App/providers/StoreProvider";

export const getPersonalLoading = (state: StateSchema): boolean => {
  return state.personalReducer?.isLoading || false;
};

export const getPersonalError = (state: StateSchema): string | null => {
  return state.personalReducer?.error || null;
};

export const getIsSavedSuccessfully = (state: StateSchema): boolean => {
  return state.personalReducer?.isSavedSuccessfull || false;
};
