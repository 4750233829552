const snils = [/\D/, /\D/, /\D/, "-", /\D/, /\D/, /\D/, "-", /\D/, /\D/, /\D/, " ", /\D/, /\D/];

export function snilsMask(value: string): string {
  const result = [];

  for (let i = 0, j = 0; i < value.length; i++) {
    if (i >= value.length || i >= snils.length) break;
    result[i] = (typeof snils[i + j] === "string" && snils[i + j] !== value[i] ? snils[i + j++] : "") + value[i];
  }
  return result.join("").substring(0, 14);
}
