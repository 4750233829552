import { FC, PropsWithChildren, ReactNode, SVGProps } from "react";
import { ButtonProps as BootstrapButtonProps } from "react-bootstrap";
import { ButtonVariant } from "react-bootstrap/esm/types";
import styles from "./Button.module.scss";
import clsx from "clsx";

interface ButtonProps extends Omit<BootstrapButtonProps, "className"> {
  children: string | (string & ReactNode)[] | ReactNode;
  variant?: ButtonVariant;
  size?: "sm" | "lg";
  Icon?: FC<PropsWithChildren<SVGProps<SVGSVGElement>>>;
  iconPosition?: "right" | "left";
  rounded?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
  form?: string;
  onClick?: () => void;
  onSubmit?: () => void;
  className?: string | false;
  unborder?: boolean;
  transform?: boolean;
  disabled?: boolean;
  marginLeft?: string;
  style?: any;
}

const ButtonCustom: FC<PropsWithChildren<ButtonProps>> = (props) => {
  const {
    children,
    Icon,
    className,
    variant = "primary",
    size = "sm",
    iconPosition = "left",
    rounded = false,
    unborder = false,
    transform = false,
    disabled = false,
    marginLeft,
    style,
    ...others
  } = props;

  return (
    <button
      data-color={variant}
      data-size={size}
      disabled={disabled}
      style={marginLeft ? { marginLeft: `${marginLeft}` } : style}
      className={clsx(
        styles.button,
        className,
        rounded && styles.button_rounded,
        unborder && styles.button_unborder,
        transform && styles.button_transform,
        disabled && styles.button_disabled
      )}
      {...others}
    >
      {Icon && iconPosition === "left" && (
        <Icon className={clsx(styles[iconPosition], styles[variant])} />
      )}
      {children}
      {Icon && iconPosition === "right" && (
        <Icon className={clsx(styles[iconPosition], styles[variant])} />
      )}
    </button>
  );
};

export default ButtonCustom;
