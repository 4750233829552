export enum WindowActionTypes {
  SET_WINDOW = "SET_WINDOW",
  SET_APPLICATION = "SET_APPLICATION",
  SET_QUESTIONNAIRE = "SET_QUESTIONNAIRE",
  SET_IS_EDITABLE_MODE = "SET_IS_EDITABLE_MODE",
  SET_PARAMS = "SET_PARAMS",
  SET_DISCIPLINES = "SET_DISCIPLINES",
  SET_SPO_DISCIPLINES = "SET_SPO_DISCIPLINES",
  SET_FOREIGNER_DISCIPLINES = "SET_FOREIGNER_DISCIPLINES",
  SET_PROGRAM_DISCIPLINES = "SET_PROGRAM_DISCIPLINES",
  SET_TARGET_ADMISSIONS = "SET_TARGET_ADMISSIONS",
  SET_SPECIAL_CONDITIONS = "SET_SPECIAL_CONDITIONS",
  UPD_STATUS_SPECIAL_CONDITIONS = "UPD_STATUS_SPECIAL_CONDITIONS",
  SET_RATING = "SET_RATING",
  SET_DIRECTIONS_TRAINING = "SET_DIRECTIONS_TRAINING",
  SET_STATISTICS = "SET_STATISTICS",

  SET_FORMS_DISCIPLINES = "SET_FORMS_DISCIPLINES",
  UPD_FORMS_DISCIPLINES = "UPD_FORMS_DISCIPLINES",
  SET_FORMS_COMPETITIVE_GROUPS = "SET_FORMS_COMPETITIVE_GROUPS",
  UPD_FORMS_COMPETITIVE_GROUPS = "UPD_FORMS_COMPETITIVE_GROUPS",
  SET_FORMS_PROGRAMS_WITH_COST = "SET_FORMS_PROGRAMS_WITH_COST",
  UPD_FORMS_PROGRAMS_WITH_COST = "UPD_FORMS_PROGRAMS_WITH_COST",
  SET_FORMS_ADMISSION_QUOTA = "SET_FORMS_ADMISSION_QUOTA",
  UPD_FORMS_ADMISSION_QUOTA = "UPD_FROMS_ADMISSION_QUOTA",
}
