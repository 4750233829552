import useTranslation from "next-translate/useTranslation";
import { YupAppType, AppYup } from "./yup-extended";

export function useYup(): YupAppType {
  const { t } = useTranslation("validations");

  AppYup.setLocale({
    mixed: {
      default: t("mixed.default"),
      required: t("mixed.required"),
      oneOf: t("mixed.one_of"),
      notOneOf: t("mixed.not_one_of"),
      defined: t("mixed.defined"),
    },
    string: {
      length: t("string.length"),
      min: t("string.min"),
      max: t("string.max"),
      matches: t("string.matches"),
      email: t("string.email"),
      url: t("string.url"),
      uuid: t("string.uuid"),
      trim: t("string.trim"),
      lowercase: t("string.lowercase"),
      uppercase: t("string.uppercase"),
    },
    number: {
      min: t("number.min"),
      max: t("number.max"),
      lessThan: t("number.less_than"),
      moreThan: t("number.more_than"),
      positive: t("number.positive"),
      negative: t("number.negative"),
      integer: t("number.integer"),
    },
    date: {
      min: t("date.min"),
      max: t("date.max"),
    },
    boolean: {
      isValue: t("boolean.is_value"),
    },
    object: {
      noUnknown: t("object.no_unknown"),
    },
    array: {
      min: t("array.min"),
      max: t("array.max"),
      length: t("array.length"),
    },
  });

  return AppYup;
}
