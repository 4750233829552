import { PostBachelorExamsDataType } from "../model/services/postBachelorExams";

export const postExamsDataAdapter = (data: PostBachelorExamsDataType): any => {
  const { is_have_exams, entranceTests, exams } = data;
  return {
    is_have_exams: is_have_exams,
    exams: exams.map(exam => ({
      type_id: exam.type?.value,
      discipline_id: exam.discipline?.value,
      year: exam.year?.value,
      scores: exam.scores,
    })),
    entranceTests: entranceTests.map(test => ({
      type_id: test.type?.value,
      discipline_id: test.discipline?.value,
      exam_date_id: test.date?.value,
    })),
  };
};
