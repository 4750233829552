import { ChangeEvent } from "react";
import dateMask from "@/lib/_common/inputs/dateMask";

const inputDateHandle = (e: ChangeEvent<HTMLInputElement>): void => {
  const target = e.target;
  const reg = /[^0-9.]/ig;

  if (target.selectionStart === target.value.length) {
    target.value = dateMask(target.value);
  }
  else {
    const index = target.selectionStart ? target.selectionStart - 1 : 0;
    if (reg.test(target.value[index]) || target.value.length > 10) target.value = target.value.substring(0, index) + target.value.substring(index + 1);
  }
};

export default inputDateHandle;
