import { ThunkApiType } from "@App/providers/StoreProvider";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { getBachelorExamDataAdapter } from "../../lib/getBachelorExamDataAdapter";
import { BachelorExamType } from "../../types/bachelorTypes";
import getErrorMessage from "@/lib/_common/getErrorMessage";

interface ExamsDataType {
  userId: number;
  studentDetailsId: number;
}

const errorMessage = "Ошибка при получении вступительных испытаний";

export const fetchBachelorExams = createAsyncThunk<
  BachelorExamType[],
  ExamsDataType,
  ThunkApiType<string>
>("bachelorExams/examsData", async (options, thunkApi) => {
  const { extra, rejectWithValue } = thunkApi;
  const { userId, studentDetailsId } = options;

  try {
    const response = await extra.api.get(
      `/users/${userId}/studentDetails/${studentDetailsId}/forms/exams`
    );
    const data = response.data.result;

    if (!response.data) {
      throw new Error(errorMessage);
    }

    const payload = getBachelorExamDataAdapter(data);

    return payload;
  } catch ({ response: error }: any) {
    return rejectWithValue(getErrorMessage(error));
  }
});
