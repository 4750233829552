import { FC, PropsWithChildren, ReactNode } from "react";
import { Overlay, Tooltip } from "react-bootstrap";

type TooltipProp = {
  children: ReactNode,
  target: HTMLDivElement | null,
  show: boolean,
  id: string,
  placement?: "bottom" | "auto" | "auto-start" | "auto-end" | "top" | "right" | "left" | "top-start" | "top-end" | "bottom-start" | "bottom-end" | "right-start" | "right-end" | "left-start" | "left-end" | undefined,
};

const TooltipCustom: FC<PropsWithChildren<TooltipProp>> = (props): JSX.Element => {
  const { children, target, show, id, placement = "bottom" } = props;
  return (
    <Overlay placement={placement} show={show} target={target}>
      {(props) => (
        <Tooltip id={`custom_${id}`} {...props} className='tooltip-custom'>
          {children}
        </Tooltip>
      )}
    </Overlay>
  );
};

export default TooltipCustom;
