import { FC, PropsWithChildren } from "react";
import FormBorder from "@/components/_Common/Form/Border";
import clsx from "clsx";
import { Container } from "react-bootstrap";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import Link from "next/link";
import { useAuth } from "@Shared/hooks/useAuth";
import styles from "./Burger.module.scss";
import { AbiturientInformation } from "@Entities/AbiturientInformation";
import { useSelector } from "react-redux";
import { getSelectedPrograms } from "@Entities/Form";


type BurgerProps = {
  showModal: boolean,
};

const Burger: FC<PropsWithChildren<BurgerProps>> = ({ showModal }): JSX.Element => {
  const { t } = useTranslation("common");

  const router = useRouter();
  const path = useRouter().pathname;
  const { logoutHandler } = useAuth();
  const chosenPrograms = useSelector(getSelectedPrograms);

  const handleLogout = () => {
    logoutHandler && logoutHandler();
  };

  if (!showModal) {
    return <></>;
  }

  return (
    <div className={clsx(styles.modal, showModal && styles.modal_active, "d-md-none")}>
      <Container>
        <AbiturientInformation isMobile />

        <FormBorder className='border_dark' />

        {(path === "/" || path === "/programs") &&
          <>
            <div className={styles.modal__navigation}>
              <Link href='/'>
                <div className={clsx(styles.modal__tab, path === "/" && styles.modal__tab_active)}>
                  <p className={clsx(path === "/" ? styles.modal__tab__text_active : styles.modal__tab__text)}>
                    {t("burger.tabs.admission")}
                  </p>
                </div>
              </Link>
              <Link href='/programs'>
                <div className={clsx(styles.modal__tab, path === "/programs" && styles.modal__tab_active)}>
                  <p className={clsx(path === "/programs" ? styles.modal__tab__text_active : styles.modal__tab__text)}>
                    {t("burger.tabs.programs")}
                  </p>
                </div>
              </Link>
            </div>

            <FormBorder className='border_dark' />
          </>
        }

        <div className={styles.modal__icon} onClick={() => router.push("/personal/programs")}>
          <p className={styles.modal__text}>
            {t("burger.chosen")}
          </p>
          <p className={styles.modal__text}>
            {chosenPrograms?.length}
          </p>
        </div>

        <div className={styles.modal__icon} onClick={handleLogout}>
          <p className={styles.modal__text}>
            {t("burger.exit")}
          </p>
        </div>

      </Container>
    </div>
  );
};

export default Burger;
