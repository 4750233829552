import { ThunkApiType } from "@App/providers/StoreProvider";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { PersonalActions } from "../../slice/PersonalSlice";

interface FetchDocumentsType {
  isForeigner: boolean;
}

const errorMessage = "";

export const FetchDocumentsType = createAsyncThunk<
  MapItem[],
  FetchDocumentsType,
  ThunkApiType<string>
>("personal/getDocumentTypeOptions", async (options, thunkApi) => {
  const { isForeigner } = options;
  const { extra, rejectWithValue, dispatch } = thunkApi;

  try {
    const response = await extra.api.get(
      `/dictionaries/person_id_types/${!isForeigner ? "RF" : "foreigner"}`
    );
    const payload = response.data.result.items;

    if (!response.data) {
      rejectWithValue(errorMessage);
    }

    dispatch(PersonalActions.setDocumentTypeOptions(payload));

    return response.data;
  } catch (error) {
    rejectWithValue(errorMessage);
  }
});
