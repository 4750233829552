import { Dispatch } from "redux";
import { BaseThunkAPI } from "@reduxjs/toolkit/dist/createAsyncThunk";
import { StateSchema, ThunkExtraArgs } from "@App/providers/StoreProvider";
import { sidebarSelectors } from "../../model/selectors/getSidebarItems";
import { SidebarActions } from "@Entities/Sidebar/model/slice/SidebarSlice";

export const nextPage = (thunkApi: BaseThunkAPI<StateSchema, ThunkExtraArgs, Dispatch, string>): void => {
  const { extra, getState, dispatch } = thunkApi;

  const sidebarLinks = sidebarSelectors.selectAll(getState());
  const router = extra.router;
  const activeForm = window.location.toString().split("/").at(-1);
  const activeFormIndex = sidebarLinks.findIndex(item => item.name === activeForm);
  const nextFormIndex = activeFormIndex + 1;
  const nextFormName = sidebarLinks[nextFormIndex].name;

  const payload = sidebarLinks.map(item => {
    if (item.name === activeForm) {
      return ({
        ...item,
        isCompleted: true,
        isActive: false,
      });
    }
    return ({
      ...item,
      isActive: item.name === nextFormName,
    });
  });
  dispatch(SidebarActions.setSidebarItems(payload));

  router.push(`/personal/${nextFormName}`);
};
