import { useRouter } from "next/router";
import { createContext, FC, PropsWithChildren } from "react";

export interface IQueryParamsContext<T extends IQueryParams<string>> {
  queryParams: T;
  addQueryParams: (queryParams: IQueryParams<string>) => void;
  removeQueryParams: (removeList: string[], keepList?: string[]) => void;
}

const initialContext: IQueryParamsContext<any> = {
  queryParams: {},
  addQueryParams: () => {
    return;
  },
  removeQueryParams: () => {
    return;
  },
};

export const QueryParamsContext =
  createContext<IQueryParamsContext<any>>(initialContext);

export const ProvideQueryParams: FC<PropsWithChildren> = ({ children }) => {
  const router = useRouter();

  const addQueryParams = (queryParams: IQueryParams<string>) => {
    router.replace(
      {
        pathname: router.pathname,
        query: { ...router.query, ...queryParams },
      },
      undefined,
      { shallow: true }
    );
  };

  const removeQueryParams = (
    removeList: string[] = [],
    keepList?: string[]
  ) => {
    if (removeList.length > 0) {
      removeList.forEach((param) => delete router.query[param]);
    } else {
      // Remove all query params
      Object.keys(router.query)
        .filter((key) => !keepList?.includes(key))
        .forEach((param) => delete router.query[param]);
    }
    router.replace(
      {
        pathname: router.pathname,
        query: router.query,
      },
      undefined,
      { shallow: true }
    );
  };

  return (
    <QueryParamsContext.Provider
      value={{
        queryParams: router.query,
        addQueryParams,
        removeQueryParams,
      }}
    >
      {children}
    </QueryParamsContext.Provider>
  );
};
