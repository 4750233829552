import Cookies from "js-cookie";
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from "../constants/constants";
import { AxiosResponse } from "axios";
import { LoginWithCodeResponse } from "../../types/authTypes";

export const setUserCookies = (res: AxiosResponse<LoginWithCodeResponse>): void => {
  const { access_token, refresh_token, access_token_expires_in, refresh_token_expires_in } = res.data.result;

  const rangeDateAccessMilliseconds = new Date(access_token_expires_in).getTime() - new Date().getTime();
  const accessExpireInDays = rangeDateAccessMilliseconds / 86400000;

  const rangeDateRefreshMilliseconds = new Date(refresh_token_expires_in).getTime() - new Date().getTime();
  const refreshExpireInDays = rangeDateRefreshMilliseconds / 86400000;

  Cookies.set(ACCESS_TOKEN_KEY, access_token, {
    expires: accessExpireInDays,
    secure: true,
    sameSite: "strict",
  });
  Cookies.set(REFRESH_TOKEN_KEY, refresh_token, {
    expires: refreshExpireInDays,
    secure: true,
    sameSite: "strict",
  });
};
