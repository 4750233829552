import { IDictionaryItem } from "@/lib/services/dictionary.service";
import ProgramStatuses from "@/lib/data/programStatuses";

export enum FinancingProgramEnum {
  Budget = "budget",
  Contract = "contract",
}

export interface ExamsProps {
  exams: IDictionaryItem[];
  watchFile: string;
  sendExams: () => void;
}

export interface ModalProps {
  modalOpen: string;
  closeModal: () => void;
  selectedProgram: Program;
  program: ProgramInSelectedProgram;
}

export interface Discipline {
  admissionType: string | null;
  description: string | null;
  id: number;
  link: string | null;
  shortTitle: string;
  title: string;
  type: string;
}
export interface Disciplines {
  discipline: Discipline;
  minScore: number;
  priority: number;
  stack: number;
  year: number;
  type?: string;
}

export interface Faculty {
  degree: IDegree;
  id: number;
  isuId: number;
  link: string;
  title: string;
}

type ProgramStatusKey = keyof typeof ProgramStatuses;

export interface ProgramInSelectedProgram {
  competitiveGroup: CompetitiveGroup
  degree: IDegree;
  directionCode: string;
  directionOfEducation: string;
  disciplines: Disciplines[];
  discipline: Discipline | null;
  faculties: Faculty[];
  id: number;
  isuId: number;
  title: string;
  name?: string;
  status?: ProgramStatusKey;
}

export interface CompetitiveGroup {
  budgetMin: number;
  contract: number;
  id: number;
  invalid: number;
  special: number;
  targetReception: number;
  title: string;
  admissionQuotas: IAdmissionQuotas;
}

export interface SelectedProgram {
  admissionCondition: string;
  applications: null | any;
  competitiveGroup: CompetitiveGroup;
  dateMail: string | null;
  discipline: any | null;
  dissertationTopic: string | null;
  faculty: any | null;
  financing: "budget" | "contract";
  id: number;
  language: string | null;
  numberDirection: string | null;
  numberMail: string | null;
  priority: number;
}
