export enum AchievementCategoryEnum {
  Standard = "standard",
  Publication = "publication",
  Patent = "patent",
  Grant = "grant",
  Award = "award",
  Certificate = "certificate",
  Olympiad = "olympiad",
  Professional = "professional",
  NoDownload = "no_download",
}
