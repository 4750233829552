import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  error: null,
  isSavedSuccessfull: false,
};

export const programsFormSlice = createSlice({
  name: "programsForm",
  initialState,
  reducers: {
    resetError: (state) => {
      state.error = null;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setIsSavedSuccessfull: (state, action: PayloadAction<boolean>) => {
      state.isSavedSuccessfull = action.payload;
    },
  },
});

export const { actions: programsAction } = programsFormSlice;
export const { reducer: programsReducer } = programsFormSlice;
