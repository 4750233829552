import { FC, useRef, useState } from "react";

import clsx from "clsx";
import useTranslation from "next-translate/useTranslation";
import { LanguagesLocalesEnum } from "@/types/LanguagesLocalesEnum";
import styles from "./RolesList.module.scss";
import { ConfirmChangeDegree } from "../ConfirmChangeDegree/ConfirmChangeDegree";
import { degreeList as citizenDegreeList, foreignerDegreeList } from "@Shared/consts/degreeList";
import { useSelector } from "react-redux";
import { getUserDegree } from "@Entities/User";

interface RolesListProps {
  setIsOpen: (value: boolean) => void;
  isMobile?: boolean
}

export const RolesList: FC<RolesListProps> = (props) => {
  const { setIsOpen, isMobile = false } = props;

  const { lang } = useTranslation();

  const degree = useSelector(getUserDegree);
  const [showModal, setShowModal] = useState<boolean>(false);
  const selectedDegree = useRef<IDegree | null>(null);

  const degreeList = lang === LanguagesLocalesEnum.RU ? citizenDegreeList : foreignerDegreeList;

  const handleSelectDegree = (item: IDegreeList) => {
    selectedDegree.current = item.value;
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setIsOpen(false);
  };

  return (
    <>
      <div className={isMobile ? styles.rolesBurger : styles.rolesModal} id={"rolesList"}>
        {degreeList.map(
          (item: IDegreeList, index: number) => {
            return (
              <div
                id={`${item.value}Role`}
                key={index}
                className={clsx(
                  isMobile && styles.rolesBurger__item,
                  !isMobile && styles.rolesModal__item,
                  !isMobile && degree !== item.value && styles.rolesModal__item_active
                )}
                onClick={() => handleSelectDegree(item)}
              >
                <p>{item.label}</p>
              </div>
            );
          })}
      </div>
      <ConfirmChangeDegree
        closeModal={handleCloseModal}
        newActiveDegree={selectedDegree.current}
        showModal={showModal}
      />
    </>
  );
};
