import { useRouter } from "next/router";
import { FC, ReactNode, useEffect, useState } from "react";
import { routeConfig } from "../config/routeConfig";
import UserRoleEnum from "@/lib/enums/userRole";
import { RoutesPath } from "@Shared/lib/routes";
import { useSelector } from "react-redux";
import { getUserAcceptence, getUserRoles } from "@Entities/User";

interface RouteProtectorProps {
    children: ReactNode
}

/**
 * Для окон данный протектор сейчас не работает
 * только для абитуриента
 */

export const RouteProtector: FC<RouteProtectorProps> = (props) => {
  const { children } = props;

  const router = useRouter();
  const userRoles = useSelector(getUserRoles);
  const acceptence = useSelector(getUserAcceptence);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const isWindowUser = userRoles.includes(UserRoleEnum.ROLE_WINDOW);

    const availableRoutes = Object.values(routeConfig)
      .filter(route => {
        if (route.userOnly === isWindowUser) {
          return false;
        }
        if (!route.fullVersionOnly && !acceptence) {
          return false;
        }
        return route;
      })
      .map(route => route.path);

    if (!isWindowUser && !availableRoutes.includes(router.pathname)) {
      router.push(RoutesPath.notFound);
    } else {
      setIsLoading(false);
    }
  }, [router.pathname]);

  if (!isLoading) {
    return (
      <>{children}</>
    );
  }
  return null;
};
