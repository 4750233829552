import { FC } from "react";
import styles from "./HeaderAccessible.module.scss";
import { Container } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "@/hooks/useTypedSelector";
import useTranslation from "next-translate/useTranslation";
import HeaderAccessibleItem from "@Entities/Header/ui/HeaderAccessibleItem/HeaderAccessibleItem";
import clsx from "clsx";
import { isDev } from "@/lib/config";
import Cookies, { CookieAttributes } from "js-cookie";
import { UserActions } from "@Entities/User";

const HeaderAccessible: FC = (): JSX.Element => {
  const { accessibleSettings } = useTypedSelector(state => state.user);
  const dispatch = useDispatch();
  const { t } = useTranslation("common");

  const toggleImages = () => {
    const cookiesOptions: CookieAttributes = {
      maxAge: 10 * 365 * 24 * 60 * 60,
      path: "/",
      secure: !isDev,
      sameSite: "none",
    };
    const settings: IAccessibleSettings = { ...accessibleSettings, images: !accessibleSettings.images };
    Cookies.set("accessibleSettings", JSON.stringify(settings), cookiesOptions);
    dispatch(UserActions.setAccessibleSettings(settings));
  };

  return (
    <header className={styles.header}>
      <Container>
        <div className={styles.accessible}>
          <div className={styles.accessible__col}>
            <HeaderAccessibleItem text={t("accessible.size")} value='m' variant='font' />
            <HeaderAccessibleItem text={t("accessible.size")} value='l' variant='font' />
            <HeaderAccessibleItem text={t("accessible.size")} value='xl' variant='font' />
          </div>

          <div className={styles.accessible__col}>
            <HeaderAccessibleItem text={t("accessible.spacing")} value='m' variant='spacing' />
            <HeaderAccessibleItem text={t("accessible.spacing")} value='l' variant='spacing' />
            <HeaderAccessibleItem text={t("accessible.spacing")} value='xl' variant='spacing' />
          </div>

          <div className={styles.accessible__col}>
            <HeaderAccessibleItem text={t("accessible.family.normal")} value='normal' variant='family' />
            <HeaderAccessibleItem text={t("accessible.family.arial")} value='arial' variant='family' />
            <HeaderAccessibleItem text={t("accessible.family.times")} value='times' variant='family' />
          </div>

          <div className={clsx(styles.accessible__col, "d-none")}>
            <HeaderAccessibleItem text={t("accessible.color")} value='white' variant='color' />
            <HeaderAccessibleItem text={t("accessible.color")} value='black' variant='color' />
            <HeaderAccessibleItem text={t("accessible.color")} value='blue' variant='color' />
          </div>

          <div className={styles.accessible__col}>
            <div className={styles.images} onClick={toggleImages}>
              {accessibleSettings.images ? t("accessible.images.on") : t("accessible.images.off")}
            </div>
          </div>
        </div>
      </Container>
    </header>
  );
};

export default HeaderAccessible;
