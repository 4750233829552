import { FC } from "react";
import { Skeleton } from "@Shared/ui/Skeleton";

import styles from "./SidebarSkeleton.module.scss";

export const SidebarSkeleton: FC = () => {
  return (
    <div className={styles.wrapper} id="sidebar_skeleton">
      <Skeleton className={styles.buttonSkeleton} />
      {/** Programs list */}
      <div className={styles.listBlock}>
        <div className={styles.listBlock__title}>
          <Skeleton className={styles.listBlock__icon} />
          <Skeleton className={styles.listBlock__text} />
        </div>
        <Skeleton className={styles.listBlock__item} />
      </div>
      {/** Qustionary list */}
      <div className={styles.listBlock}>
        <div className={styles.listBlock__title}>
          <Skeleton className={styles.listBlock__icon} />
          <Skeleton className={styles.listBlock__text} />
        </div>
        <Skeleton className={styles.listBlock__item} />
        <Skeleton className={styles.listBlock__item} />
        <Skeleton className={styles.listBlock__item} />
        <Skeleton className={styles.listBlock__item} />
        <Skeleton className={styles.listBlock__item} />
        <Skeleton className={styles.listBlock__item} />
        <Skeleton className={styles.listBlock__item} />
        <Skeleton className={styles.listBlock__item} />
        <Skeleton className={styles.listBlock__item} />
        <Skeleton className={styles.listBlock__item} />
      </div>
      {/** Documents list */}
      <div className={styles.listBlock}>
        <div className={styles.listBlock__title}>
          <Skeleton className={styles.listBlock__icon} />
          <Skeleton className={styles.listBlock__text} />
        </div>
        <Skeleton className={styles.listBlock__item} />
      </div>
      {/** Payment list */}
      <div className={styles.listBlock}>
        <div className={styles.listBlock__title}>
          <Skeleton className={styles.listBlock__icon} />
          <Skeleton className={styles.listBlock__text} />
        </div>
        <Skeleton className={styles.listBlock__item} />
      </div>
    </div>
  );
};
