import { FC } from "react";
import clsx from "clsx";
import LoaderAnimation from "./Loader.svg";
import styles from "./Loader.module.scss";

interface LoaderProps {
  className?: string;
}

export const Loader: FC<LoaderProps> = (props) => {
  const { className } = props;

  return (
    <div className={clsx(styles.loader, className)} id="loaderSpinner">
      <LoaderAnimation />
    </div>
  );
};
