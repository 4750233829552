import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import Cookies from "js-cookie";
import { MutableRefObject } from "react";
import {
  ACCESS_TOKEN_KEY,
  CODE_URL_PARAM_NAME,
  REFRESH_TOKEN_KEY,
} from "../constants/constants";
import {
  AuthRequireOptionsType,
  LanguagesEnum,
  RefreshResponse,
} from "../../types/authTypes";

let isRefreshing = false;
const timedoutRequestsQueue: [NodeJS.Timeout, (value: unknown) => void][] = [];
const TIMEOUT_REQUEST = 30000;

export interface CheckAccessTokenMiddlewareOptions {
  config: AxiosRequestConfig;
  lang: LanguagesEnum;
  AuthRequireOptions: MutableRefObject<AuthRequireOptionsType | null>;
  authUserByCurrentStatus: (options: AuthRequireOptionsType) => Promise<void>;
}

/**
 *
 * Функция CheckAccessTokenMiddleware представляет собой промежуточный слой для Axios,
 * который проверяет наличие access_token и refresh_token в Cookies. Если access_token отсутствует,
 * а refresh_token присутствует, то происходит запрос на обновление токенов.
 * Если refresh_token также отсутствует, то функция передает параметры для авторизации пользователя.

 * Входные параметры:
 * @param options

 * Выходные параметры:
 * @returns AxiosRequestConfig

 * Также функция имеет переменную isRefreshing, которая отвечает за состояние обновления токенов,
 * и массив timedoutRequestsQueue, который содержит отложенные запросы на обновление токенов.

 * Функция также содержит интерфейс CheckAccessTokenMiddlewareOptions, который определяет
 * типы входных параметров.

 * Для корректной работы функции необходимо передать в нее все входные параметры.
 */

export const CheckAccessTokenMiddleware = async (
  options: CheckAccessTokenMiddlewareOptions
): Promise<AxiosRequestConfig> => {
  const { AuthRequireOptions, config, lang, authUserByCurrentStatus } = options;

  const accessToken = Cookies.get(ACCESS_TOKEN_KEY);
  const refreshToken = Cookies.get(REFRESH_TOKEN_KEY);

  if (isRefreshing) {
    await new Promise((resolve) => {
      const timeout = setTimeout(resolve, TIMEOUT_REQUEST);
      timedoutRequestsQueue.push([timeout, resolve]);
    });

    const accessToken = Cookies.get(ACCESS_TOKEN_KEY);
    config.headers["X-Authorization"] = `Bearer ${accessToken}`;
    return config;
  }

  if (!accessToken && refreshToken) {
    isRefreshing = true;

    const response = await axios.post<
      RefreshResponse,
      AxiosResponse<RefreshResponse>
    >("/api/v1/sso/refresh", {
      refresh_token: refreshToken,
    });

    const {
      access_token,
      refresh_token,
      access_token_expires_in,
      refresh_token_expires_in,
    } = response.data.result;

    Cookies.set(ACCESS_TOKEN_KEY, access_token, {
      expires: new Date(access_token_expires_in),
      secure: true,
      sameSite: "strict",
    });
    Cookies.set(REFRESH_TOKEN_KEY, refresh_token, {
      expires: new Date(refresh_token_expires_in),
      secure: true,
      sameSite: "strict",
    });

    for (let i = 0; i < timedoutRequestsQueue.length; i++) {
      const [timeout, resolver] = timedoutRequestsQueue[i];
      clearTimeout(timeout);
      resolver(true);
    }

    isRefreshing = false;
  }

  if (!refreshToken) {
    const urlParams = new URLSearchParams(window.location.href);
    const accessToken = Cookies.get(ACCESS_TOKEN_KEY);
    const refreshToken = Cookies.get(REFRESH_TOKEN_KEY);
    const code = urlParams.get(CODE_URL_PARAM_NAME);
    AuthRequireOptions.current = {
      lang: lang,
      redirectUrl: window.location.pathname,
      accessToken: accessToken,
      refreshToken: refreshToken,
      code: code,
    };
    authUserByCurrentStatus(AuthRequireOptions.current);
  }

  config.headers["X-Authorization"] = `Bearer ${accessToken}`;
  return config;
};
