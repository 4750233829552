export const localeDateString = (dateString?: string | null): string => {
  if (!dateString) {
    return "";
  }

  const date = new Date(dateString);

  if (isNaN(date.getTime())) {
    return "";
  }

  const options: Intl.DateTimeFormatOptions = {
    timeZone: "Europe/Moscow",
  };

  return date.toLocaleDateString("ru-RU", options);
};
