import styles from "./Logo.module.scss";
import { FC, PropsWithChildren } from "react";
import clsx from "clsx";
import { useTypedSelector } from "@/hooks/useTypedSelector";

type LogoProps = {
  simple?: boolean;
};

const Logo: FC<PropsWithChildren<LogoProps>> = ({ simple }): JSX.Element => {
  const { theme } = useTypedSelector(state => state.user);

  return (
    <div className={clsx(theme === "accessible" || simple ? styles.logo_simple : styles.logo)}>
      <span className={styles.logo__first}>itmo</span>
      <span className={clsx(
        styles.logo__dot,
        theme === "accessible" && styles.logo__dot_accessible,
        simple && styles.logo__dot_simple)}
      />
      <span className={styles.logo__second}>Abit</span>
    </div>
  );
};

export default Logo;
