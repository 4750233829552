import { Skeleton } from "@Shared/ui/Skeleton";
import { FC } from "react";

import styles from "./FormSkeleton.module.scss";

export const FormSkeleton: FC = () => {
  return (
    <div className={styles.wrapper} id="skeleton_wrapper">
      <div className={styles.field} id="skeleton_field">
        <Skeleton className={styles.field__label} />
        <Skeleton />
      </div>
      <div className={styles.field} id="skeleton_field">
        <Skeleton className={styles.field__label} />
        <Skeleton />
      </div>
      <div className={styles.row} id="skeleton_row">
        <div className={styles.field}>
          <Skeleton className={styles.field__label} />
          <Skeleton />
        </div>
        <div className={styles.field} id="skeleton_field">
          <Skeleton className={styles.field__label} />
          <Skeleton />
        </div>
      </div>
      <div className={styles.row} id="skeleton_row">
        <div className={styles.field} id="skeleton_field">
          <Skeleton className={styles.field__label} />
          <Skeleton />
        </div>
        <div className={styles.field} id="skeleton_field">
          <Skeleton className={styles.field__label} />
          <Skeleton />
        </div>
      </div>
      <div className={styles.field} id="skeleton_field">
        <Skeleton className={styles.field__label} />
        <Skeleton />
      </div>
      <div className={styles.field} id="skeleton_field">
        <Skeleton className={styles.field__label} />
        <Skeleton />
      </div>
      <div className={styles.row} id="skeleton_row">
        <div className={styles.field} id="skeleton_field">
          <Skeleton className={styles.field__label} />
          <Skeleton />
        </div>
        <div className={styles.field} id="skeleton_field">
          <Skeleton className={styles.field__label} />
          <Skeleton />
        </div>
      </div>
    </div>
  );
};
