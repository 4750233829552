import { FC, useEffect } from "react";
import { PersonalFormAll } from "../PersonalFormAll";
import { useDispatch, useSelector } from "react-redux";
import { FetchPersonalData } from "../../model/services/FetchPersonalData/FetchPersonalData";
import { getPersonalLoading } from "../../model/selectors/getComponentStates/getComponentStates";
import { FormSkeleton } from "@Shared/ui/FormSkeleton";
import { DynamicModuleLoader } from "@Shared/lib/DynamicModuleLoader";
import { PersonalReducer } from "../../model/slice/PersonalSlice";
import { getPersonalData } from "../../model/selectors/getPersonalData/getPersonalData";

interface PersonalFormProps {
    id: string
    userId: number
    studentDetailsId: number
    isWindow?: boolean
    disabled?: boolean
}

const personalReducer = {
  personalReducer: PersonalReducer,
};

export const PersonalForm: FC<PersonalFormProps> = (props) => {
  const {
    id,
    userId,
    studentDetailsId,
    isWindow = false,
    disabled = false,
  } = props;

  const dispatch = useDispatch();
  const personalData = useSelector(getPersonalData);
  const isLoading = useSelector(getPersonalLoading);

  useEffect(() => {
    if (!personalData) {
      dispatch(FetchPersonalData({ userId, studentDetailsId }));
    }
  }, []);

  if (isLoading) {
    return (
      <DynamicModuleLoader reducers={personalReducer}>
        <FormSkeleton />
      </DynamicModuleLoader>
    );
  }

  return (
    <DynamicModuleLoader reducers={personalReducer}>
      <PersonalFormAll
        disabled={disabled}
        id={id}
        isWindow={isWindow}
        studentDetailsId={studentDetailsId}
        userId={userId}
      />
    </DynamicModuleLoader>
  );
};
