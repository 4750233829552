import { ThunkApiType } from "@App/providers/StoreProvider";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { SidebarActions } from "../slice/SidebarSlice";
import {
  FetchSidebarAdapterOptions,
  fetchSidebarAdapter,
} from "../../lib/fetchSidebarAdapter/fetchSidebarAdapter";
import {
  FetchSidebarConfigResponse,
  FetchSidebarResponse,
} from "../../types/sidebarRequestTypes";
import { filterSidebarItems } from "../../lib/filterSidebarItems/filterSidebarItems";
import camelcaseKeys from "camelcase-keys";
import { UserActions } from "@Entities/User";

interface FetchSubmitedFormsOptions {
  userId: number;
  studentDetailsId: number;
  degree: IDegree;
}

const errorMessage = "Не удалось получить мюню форм";

export const fetchSubmitedForms = createAsyncThunk<
  void,
  FetchSubmitedFormsOptions,
  ThunkApiType<string>
>("sidebar/fetchSubmitedForms", async (options, thunkApi) => {
  const { extra, rejectWithValue, dispatch } = thunkApi;
  const { userId, studentDetailsId, degree } = options;

  try {
    const response = await extra.api.get<FetchSidebarResponse>(
      `/users/${userId}/studentDetails/${studentDetailsId}/submitted_forms`
    );
    const sidebarConfigResponse =
      await extra.api.get<FetchSidebarConfigResponse>(
        `users/${userId}/studentDetails/${studentDetailsId}/sidebar`
      );

    if (!response) {
      throw new Error();
    }

    const sidebarConfig = camelcaseKeys(sidebarConfigResponse.data.result);

    const options: FetchSidebarAdapterOptions = {
      data: response.data.result.submitted_forms,
      config: sidebarConfig,
      degree,
    };
    const sidebarItems = fetchSidebarAdapter(options);
    const payload = filterSidebarItems(sidebarItems);

    dispatch(UserActions.setUserCitizenship(sidebarConfig.citizenship));
    dispatch(SidebarActions.setSidebarItems(payload));
  } catch (error) {
    return rejectWithValue(errorMessage);
  }
});
