import { ResponseReturnType } from "../../types/garReqyestTypes";
import { FetchGarOptions } from "../../types/garSelectTypes";
import axios from "axios";

interface RegionGarType {
  id: number;
  title: string;
  type: number;
  typeTitle: string;
  garInfo: {
    garId: number;
    garLevel: number;
    garGuid: string;
    garPath: string;
  },
  readableTitle: string;
}

interface RegionsGarResponseResponse {
  meta_data: {
    total: number;
  };
  result: {
    houses: RegionGarType[];
  };
}

export const getGarHousesWithLimit = (options: FetchGarOptions): Promise<ResponseReturnType> => {
  const { page, limit, regionId, cityId, streetId, value } = options;

  let url = `https://gar.itmo.ru/api/v1/regions/${regionId}/cities/${cityId}/houses?${streetId ? `street_id=${streetId}&` : ""}page=${page}&limit=${limit}`;

  if (value) {
    url += `&number=${value}`;
  }

  return axios.get<RegionsGarResponseResponse>(url).then(res => {
    return {
      total: res.data.meta_data.total,
      options: res.data.result.houses.map(house => ({
        label: house.readableTitle,
        value: house.id,
      })),
    };
  });
};
