import { createAsyncThunk } from "@reduxjs/toolkit";
import { ThunkApiType } from "@App/providers/StoreProvider";
import { api } from "@Shared/api/createAxiosApi";
import { formLink } from "@/lib/config";
import camelcaseKeys from "camelcase-keys";

export const fetchEntrantAchievements = createAsyncThunk<
    StudentAchievement[] | null,
    { userId: number; studentDetailsId: number },
    ThunkApiType<string>
>("achievementSlice/getEntrantAchievements", async (options, thunkApi) => {
  const { userId, studentDetailsId } = options;
  const { rejectWithValue } = thunkApi;

  try {
    const response = await api.get(
      `${formLink(userId, studentDetailsId)}/forms/achievement`
    );
    const responseData = camelcaseKeys(response.data.result, { deep: true });

    const achievements = responseData.achievements.map((item: any) => ({
      id: item.id,
      typeId: item.type.id,
      title:
                item.award?.title ||
                item.grant?.title ||
                item.patent?.title ||
                item.publication?.title,
      authors:
                item.grant?.authors ||
                item.patent?.authors ||
                item.publication?.authors,
      year:
                item.award?.year ||
                item.grant?.year ||
                item.olympiad?.year ||
                item.patent?.year ||
                item.publication?.year ||
                item.year,
      number: item.patent?.number,
      journal: item.publication?.journal,
      journalNumber: item.publication?.journalNumber,
      volume: item.publication?.volume,
      doi: item.publication?.doi,
      pages: item.publication?.pageNumbers,
      issuedBy:
                item.award?.issuedBy ||
                item.grant?.issuedBy ||
                item.certificate?.certificate,
      olympiad: item.olympiad?.olympiad,
      track: item.professional?.title,
      language: item.certificate?.language,
      result: item.certificate?.result,
      level: item.certificate?.level,
      scans: item.scans,
      status: item.status,
      comment: item.comment,
    }));

    return achievements;
  } catch (error: any) {
    const errorDetail = error?.response?.data?.errors?.detail;
    return rejectWithValue(errorDetail);
  }
});
