import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";

import MenuLeft from "@/public/images/personal/menu_left.svg";
import CloseIcon from "@/public/images/symbols/close.svg";
import {
  getStudentDetailsActiveUserId,
  getUserDegree,
  getUserId,
  getUserTheme,
} from "@Entities/User";

import { Sidebar } from "../Sidebar/Sidebar";
import styles from "./Sidebar.module.scss";

export const SidebarMobile: FC = () => {
  const theme = useSelector(getUserTheme);
  const userId = useSelector(getUserId);
  const degree = useSelector(getUserDegree);
  const studentDetailsId = useSelector(getStudentDetailsActiveUserId);
  const [showSidebar, setShowSidebar] = useState<boolean>(false);

  const toggleShowSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  useEffect(() => {
    const body = document.body;
    if (showSidebar) {
      body.classList.add("noScroll");
    } else {
      body.classList.remove("noScroll");
    }
  }, [showSidebar]);

  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    setIsMobile(window.innerWidth < 768);

    const checkModal = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", checkModal);
    return () => {
      window.removeEventListener("resize", checkModal);
    };
  }, []);

  const classList: Record<string, boolean> = {
    [styles.background_active]: showSidebar,
    "d-lg-none": theme !== "accessible",
  };

  const sidebarClassList: Record<string, boolean> = {
    [styles.modal__sidebar_accessible]: theme === "accessible",
  };

  const menuClassList: Record<string, boolean> = {
    [styles.modal__menu_accessible]: theme === "accessible",
    [styles.modal__menu_open]: showSidebar,
    [styles.modal__menu_closed]: !showSidebar,
  };

  return (
    isMobile && (
      <div
        className={clsx(styles.background, classList)}
        id="mobile_sidebar_wrapper"
        onClick={toggleShowSidebar}
      >
        <div
          className={clsx(styles.modal, showSidebar && styles.modal_active)}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={clsx(styles.modal__sidebar, sidebarClassList)}>
            <Sidebar
              degree={degree}
              studentDetailsId={studentDetailsId}
              userId={userId}
            />
          </div>
        </div>
        <div
          className={clsx(styles.modal__menu, menuClassList)}
          onClick={toggleShowSidebar}
        >
          {showSidebar ? <CloseIcon /> : <MenuLeft />}
        </div>
      </div>
    )
  );
};
