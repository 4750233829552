import { createSlice } from "@reduxjs/toolkit";
import { postMasterExams } from "@Features/ExamsForm/MasterExamsForm/model/services/PostMasterExams";

// TODO: добавить все остальны сервисы.
const initialState: { isLoading: boolean } = {
  isLoading: false,
};

export const masterExamsSlice = createSlice({
  name: "masterExamsSlice",
  initialState,
  reducers: {},
  extraReducers: (build) => {
    build.addCase(postMasterExams.pending, (state) => {
      state.isLoading = true;
    });
    build.addCase(postMasterExams.fulfilled, (state) => {
      state.isLoading = false;
    });
    build.addCase(postMasterExams.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const { actions: MasterExamsActions } = masterExamsSlice;
export const { reducer: MasterExamsReducer } = masterExamsSlice;
