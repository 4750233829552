export {
  getUserId,
  getUserDegree,
  getUserYear,
  getUserStudentDetailsLocked,
  getStudentDetailsUserId,
  getUserCitizenship,
  getUserTheme,
  getUserData,
  getUserConfiguration,
  getUserAcceptence,
  getUserAccessableSettings,
  getUserRoles,
} from "./model/selectors/getUserSelectors";

export { getStudentDetails } from "./model/selectors/getStudentDetails";

export {
  getStudentDetailsActiveLocked,
  getStudentDetailsActiveDegree,
  getStudentDetailsActiveUserId,
} from "./model/selectors/getStudentDetailsActive";

export { UserReducer, UserActions } from "./model/slice/UserSlice";
export { setStudentdetails } from "./model/services/setStudentdetails/setStudentdetails";
export { setStudentDetailsActive } from "./model/services/setStudentdetailsActive/setStudentdetailsActive";
export { setConfiguration } from "./model/services/setConfiguration/setConfiguration";
export { postUserCitizenship } from "./model/services/postUserCitizenship/postUserCitizenship";
export { getUserInited } from "./model/selectors/getUserSelectors";
export { updateStudentDetailsActive } from "./model/services/updateStudentDetailsActive/updateStudentDetailsActive";

export type { UserStateSchema } from "./model/types/userType";
