import UserRoleEnum from "@/lib/enums/userRole";

const labels: {
  [k in UserRoleEnum]: string
} = {
  ROLE_USER: "Клиент",
  ROLE_MANAGER: "Менеджер",
  ROLE_WINDOW: "Менеджер",
  ROLE_SUPER_ADMIN: "СУПЕРАДМИН",
  ROLE_WINDOW_FOREIGNER: "Иностранцы",
  ROLE_WINDOW_BACHELOR: "Бакалавриат",
  ROLE_WINDOW_MASTER: "Магистратура",
  ROLE_WINDOW_POSTGRADUATE: "Аспирантура",
};

const getUserRoleLabel = (role: UserRoleEnum): string => labels[role];

export default getUserRoleLabel;
