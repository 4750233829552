import { CountriesSelectOption } from "../../types/garSelectTypes";
import { api } from "@Shared/api/createAxiosApi";

export const getCountries = (): Promise<CountriesSelectOption[]> => {
  const url = "/dictionaries/countries";

  return api.get<ISuccessResponse>(url)
    .then((response) => {
      return response.data.result.items;
    })
    .catch(() => {
      return [];
    });
};
