export function divisionMask(value: string | number | string[] | undefined): string {

  if (value) {
    value = value.toString().replace(/[^0-9]/i, "");
    if (value.length > 3) {
      value = value.substring(0, 3) + "-" + value.substring(3);
    }
    return value;
  }
  return "";

}
