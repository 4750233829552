import { EntityState } from "@reduxjs/toolkit";

export type SidebarStateSchema = {
    isLoading: boolean
    error?: string | null
    items: EntityState<SidebarItemType>
};

export enum SidebarItemEnum {
    Programs = "programs",
    Personal = "personal",
    Documents = "documents",
    Payment = "payment_details"
}

export enum SidebarItemNames {
    PROGRAMS = "programs",
    PERSONALLY = "personally",
    ADDRESS = "address",
    PHOTO = "photo",
    EDUCATION = "education",
    EXAMS = "exams",
    QUOTA = "quota",
    RELATIVES = "relatives",
    MEDICAL = "medical",
    MILITARY = "military",
    PRIVILEGES = "privileges",
    SPECIALTY = "specialty",
    OLYMPIADS = "olympiads",
    ACHIEVEMENTS = "achievements",
    ITMOSTARS = "itmostars",
    KRONBARS = "kronbars",
    DORMITORY = "dormitory",
    DOCUMENTS = "documents",
    PAYMENT = "payment_details"
}

export interface SidebarItemType {
    type: SidebarItemEnum
    name: SidebarItemNames
    isAccepted: boolean
    isCompleted: boolean
    isActive: boolean
    isHidden: boolean
    hasErrors: boolean
    comment?: string | null
    citizenship: string
}

export type SidebarConfigType = SidebarItemType[];
