import { StateSchema } from "@App/providers/StoreProvider";
import { examsAdapter } from "../adapters/examsAdapter";

export const getBachelorTypeOptions = (state: StateSchema): MapItem[] => {
  return state.bachelorExamsReducer?.typeOptions || [];
};

export const getExamDisciplines = (state: StateSchema): MapItem[] => {
  return state.bachelorExamsReducer?.disciplineOptions || [];
};

export const getEntranceDateOptions = (state: StateSchema): MapItem[] => {
  return state.bachelorExamsReducer?.entranceDateOptions || [];
};

export const getBachelorExamsIsLoading = (state: StateSchema): boolean => {
  return state.bachelorExamsReducer?.isLoading || false;
};

export const getBachelorValidating = (state: StateSchema): boolean => {
  return state.bachelorExamsReducer?.isValidatingSuccess || false;
};

export const getIsHaveExams = (state: StateSchema): boolean => {
  return state.bachelorExamsReducer?.isHaveExams || true;
};

export const getBachelorIsValidatingMode = (state: StateSchema): boolean => {
  return state.bachelorExamsReducer?.isValidatingMode || false;
};

export const getBachelorExamsCards = examsAdapter.getSelectors<StateSchema>(
  state => state.bachelorExamsReducer || examsAdapter.getInitialState()
);
