import { BachelorExamsResponseType } from "../types/bachelorRequestTypes";
import { BachelorExamType } from "../types/bachelorTypes";
import { formatDate } from "@/lib/_common/formatedDate";

export const getBachelorExamDataAdapter = (data: BachelorExamsResponseType): BachelorExamType[] => {
  const exams = data.exams.map(exam => ({
    id: exam.id.toString(),
    type: {
      label: exam.type.title,
      value: exam.type.id,
    },
    discipline: {
      label: exam.discipline_title,
      value: exam.discipline_id,
    },
    year: {
      label: String(exam.year),
      value: exam.year,
    },
    scores: String(exam.scores),
  }));
  const entranceTests = data.entrance_tests.map(test => ({
    id: test.id.toString(),
    type: {
      label: test.type.title,
      value: test.type.id,
    },
    discipline: {
      label: test.discipline_title,
      value: test.discipline_id,
    },
    date: {
      label: formatDate(new Date(test.exam_date.date)),
      value: test.exam_date.id,
    },
    scores: test.scores ? String(test.scores) : "",
  }));

  return [...exams, ...entranceTests];
};
