import {
  SidebarConfigType,
  SidebarItemEnum,
  SidebarItemNames,
} from "../../types/sidebarConfigTypes";

export const masterSidebarConfig: SidebarConfigType = [
  /** Programs Links */
  {
    type: SidebarItemEnum.Programs,
    name: SidebarItemNames.PROGRAMS,
    isCompleted: false,
    isAccepted: false,
    isActive: false,
    isHidden: false,
    hasErrors: false,
    comment: null,
    citizenship: "",
  },

  /** Personal Links */
  {
    type: SidebarItemEnum.Personal,
    name: SidebarItemNames.PERSONALLY,
    isCompleted: false,
    isAccepted: false,
    isActive: false,
    isHidden: false,
    hasErrors: false,
    comment: null,
    citizenship: "",
  },
  {
    type: SidebarItemEnum.Personal,
    name: SidebarItemNames.ADDRESS,
    isCompleted: false,
    isAccepted: false,
    isActive: false,
    isHidden: false,
    hasErrors: false,
    comment: null,
    citizenship: "",
  },
  {
    type: SidebarItemEnum.Personal,
    name: SidebarItemNames.PHOTO,
    isCompleted: false,
    isAccepted: false,
    isActive: false,
    isHidden: false,
    hasErrors: false,
    comment: null,
    citizenship: "",
  },
  {
    type: SidebarItemEnum.Personal,
    name: SidebarItemNames.EDUCATION,
    isCompleted: false,
    isAccepted: false,
    isActive: false,
    isHidden: false,
    hasErrors: false,
    comment: null,
    citizenship: "",
  },
  {
    type: SidebarItemEnum.Personal,
    name: SidebarItemNames.EXAMS,
    isCompleted: false,
    isAccepted: false,
    isActive: false,
    isHidden: false,
    hasErrors: false,
    comment: null,
    citizenship: "",
  },
  {
    type: SidebarItemEnum.Personal,
    name: SidebarItemNames.QUOTA,
    isCompleted: false,
    isAccepted: false,
    isActive: false,
    isHidden: false,
    hasErrors: false,
    comment: null,
    citizenship: "",
  },
  {
    type: SidebarItemEnum.Personal,
    name: SidebarItemNames.RELATIVES,
    isCompleted: false,
    isAccepted: false,
    isActive: false,
    isHidden: false,
    hasErrors: false,
    comment: null,
    citizenship: "",
  },
  {
    type: SidebarItemEnum.Personal,
    name: SidebarItemNames.MEDICAL,
    isCompleted: false,
    isAccepted: false,
    isActive: false,
    isHidden: false,
    hasErrors: false,
    comment: null,
    citizenship: "",
  },
  {
    type: SidebarItemEnum.Personal,
    name: SidebarItemNames.MILITARY,
    isCompleted: false,
    isAccepted: false,
    isActive: false,
    isHidden: false,
    hasErrors: false,
    comment: null,
    citizenship: "",
  },
  // {
  //   type: SidebarItemEnum.Personal,
  //   name: SidebarItemNames.PRIVILEGES,
  //   isCompleted: false,
  //   isAccepted: false,
  //   isActive: false,
  //   isHidden: false,
  // hasErrors: false,
  //   comment: null,
  // citizenship: ""
  // },
  // {
  //   type: SidebarItemEnum.Personal,
  //   name: SidebarItemNames.OLYMPIADS,
  //   isCompleted: false,
  //   isAccepted: false,
  //   isActive: false,
  //   isHidden: false,
  // hasErrors: false,
  //   comment: null,
  // citizenship: ""
  // },
  {
    type: SidebarItemEnum.Personal,
    name: SidebarItemNames.ACHIEVEMENTS,
    isCompleted: false,
    isAccepted: false,
    isActive: false,
    isHidden: false,
    hasErrors: false,
    comment: null,
    citizenship: "",
  },
  {
    type: SidebarItemEnum.Personal,
    name: SidebarItemNames.SPECIALTY,
    isCompleted: false,
    isAccepted: false,
    isActive: false,
    isHidden: false,
    hasErrors: false,
    comment: null,
    citizenship: "",
  },
  // {
  //   type: SidebarItemEnum.Personal,
  //   name: SidebarItemNames.ITMOSTARS,
  //   isCompleted: false,
  //   isAccepted: false,
  //   isActive: false,
  //   isHidden: false,
  // hasErrors: false,
  //   comment: null,
  // citizenship: ""
  // },
  // {
  //   type: SidebarItemEnum.Personal,
  //   name: SidebarItemNames.KRONBARS,
  //   isCompleted: false,
  //   isAccepted: false,
  //   isActive: false,
  //   isHidden: false,
  // hasErrors: false,
  //   comment: null,
  // citizenship: ""
  // },
  {
    type: SidebarItemEnum.Personal,
    name: SidebarItemNames.DORMITORY,
    isCompleted: false,
    isAccepted: false,
    isActive: false,
    isHidden: false,
    hasErrors: false,
    comment: null,
    citizenship: "",
  },

  /** Payment Links */
  {
    type: SidebarItemEnum.Payment,
    name: SidebarItemNames.PAYMENT,
    isCompleted: false,
    isAccepted: false,
    isActive: false,
    isHidden: false,
    hasErrors: false,
    comment: null,
    citizenship: "",
  },

  /** Documents Links */
  {
    type: SidebarItemEnum.Documents,
    name: SidebarItemNames.DOCUMENTS,
    isCompleted: false,
    isAccepted: false,
    isActive: false,
    isHidden: false,
    hasErrors: false,
    comment: null,
    citizenship: "",
  },
];
