import { localeDateString } from "@Shared/lib/localeDateString";
import type { PersonalDataType } from "../types/personalStateSchema";
import type { PostPersonallyData } from "../types/postRequestSchema";
import { snilsMask } from "@/lib/_common/inputs/snilsMask";
import DocumentTypesEnum from "./documentTypesEnum";

export const getDataAdapter = (data: PostPersonallyData): PersonalDataType => {
  const {
    citizenship,
    full_name,
    is_have_old_name,
    full_name_old,
    full_name_old_date,
    is_compatriot,
    compatriot_file,
    compatriot_file_translation,
    contact,
    birth_date,
    birth_place,
    gender,
    documents,
  } = data;

  const documentType = documents?.person_id?.type;

  return {
    citizenship: {
      value: citizenship,
      label: citizenship,
    },
    isCompatriot: is_compatriot,
    fullName: {
      firstName: full_name.first_name?.trim(),
      lastName: full_name.last_name?.trim(),
      middleName: full_name.middle_name?.trim() || "",
    },
    isHaveOldName: is_have_old_name,
    fullNameOld: {
      firstName: full_name_old?.first_name?.trim() || "",
      lastName: full_name_old?.last_name?.trim() || "",
      middleName: full_name_old?.middle_name?.trim() || "",
    },
    fullNameOldDate: full_name_old_date
      ? localeDateString(full_name_old_date)
      : "",
    compatriotFile: compatriot_file ? [compatriot_file] : [],
    compatriotFileTranslation: compatriot_file_translation
      ? [compatriot_file_translation]
      : [],
    contact: {
      email: contact.email,
      phone: contact.phone,
    },
    birthDate: birth_date ? localeDateString(birth_date) : "",
    birthPlace: birth_place,
    gender: gender,
    documents: {
      type: "passport",
      personId: {
        type: documentType
          ? {
            label: documentType,
            value: documentType,
          }
          : null,
        passport:
          documentType === DocumentTypesEnum.Passport
            ? {
              series: documents?.person_id.series,
              number: documents?.person_id.number,
              issuedDate: localeDateString(documents?.person_id.issued_date),
              divisionName: documents?.person_id.division_name,
              scans: documents?.person_id.scans,
            }
            : undefined,
        internationalPassport:
          documentType === DocumentTypesEnum.InternationalPassport
            ? {
              series: documents?.person_id.series,
              number: documents?.person_id.number,
              issuedDate: localeDateString(documents?.person_id.issued_date),
              divisionName: documents?.person_id.division_name,
              validityPeriod:
                  documents?.person_id.validity_period &&
                  localeDateString(documents.person_id.validity_period),
              scans: documents?.person_id.scans,
            }
            : undefined,
        birthCertificate:
          documentType === DocumentTypesEnum.BirthCertificate
            ? {
              series: documents?.person_id.series,
              number: documents?.person_id.number,
              issuedDate: localeDateString(documents?.person_id.issued_date),
              scans: documents?.person_id.scans,
            }
            : undefined,
        shelterCertificate:
          documentType === DocumentTypesEnum.ShelterCertificate
            ? {
              series: documents?.person_id.series,
              number: documents?.person_id.number,
              issuedDate: localeDateString(documents?.person_id.issued_date),
              validityPeriod:
                  documents?.person_id.validity_period &&
                  localeDateString(documents.person_id.validity_period),
              scans: documents?.person_id.scans,
            }
            : undefined,
        foreignPassport:
          documentType === DocumentTypesEnum.ForeignPassport
            ? {
              number: documents?.person_id.number,
              issuedDate: localeDateString(documents?.person_id.issued_date),
              validityPeriod:
                  documents?.person_id.validity_period &&
                  localeDateString(documents.person_id.validity_period),
              scans: documents?.person_id.scans,
              translateScans: documents?.person_id.translate_scans,
              divisionName: documents?.person_id.division_name,
            }
            : undefined,
        IDCard:
          documentType === DocumentTypesEnum.IDCard
            ? {
              number: documents?.person_id.number,
              issuedDate: localeDateString(documents?.person_id.issued_date),
              validityPeriod:
                  documents?.person_id.validity_period &&
                  localeDateString(documents.person_id.validity_period),
              scans: documents?.person_id.scans,
              translateScans: documents?.person_id.translate_scans,
              divisionName: documents?.person_id.division_name,
            }
            : undefined,
        military: { scans: documents?.person_id.scans },
      },
      isHaveOldPassport: Boolean(documents?.is_have_old_passport),
      passportOld: {
        series: documents?.passport_old?.series || "",
        number: documents?.passport_old?.number || "",
      },
    },
    snils: {
      number: snilsMask(documents?.snils?.number || ""),
      scans: documents?.snils?.scans || [],
    },
    divisionCode: documents?.person_id.division_code || "",
  };
};
