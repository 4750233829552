import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { UserStateSchema } from "../types/userType";
import { fakeDegreeList } from "@/lib/configTest";
import { setStudentdetails } from "../services/setStudentdetails/setStudentdetails";
import { ThemeTypes } from "@Shared/types/themeTypes";

const initialState: UserStateSchema = {
  isLoading: true,
  _isInited: false,
  error: null,
  studentDetailActive: {
    id: 0,
    degree: "bachelor",
    first_time_degree: true,
    year: new Date().getFullYear(),
    locked: false,
    applicationStatus: "",
  },
  studentDetails: [],
  degreeList: fakeDegreeList,
  user: {
    id: 0,
    last_name: "test",
    first_name: "test",
    middle_name: "test",
    degree: "bachelor",
    phone: "test",
    email: "test@test.ru",
    citizenship: "test",
    isSendOriginalUpdatedAt: null,
    student_details: 0,
    roles: [],
    windows: [],
  },
  // TODO: move to a separate state for application variables
  theme: "normal",
  accessibleSettings: {
    font: "l",
    color: "white",
    spacing: "m",
    family: "normal",
    images: true,
  },
  acceptance: false,
  year: 2022,
  changeAchievement: false,
  status: "",
};

export const UserSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    setUser: (state, action: PayloadAction<IUserData>) => {
      state.user = action.payload;
    },
    setUserCitizenship: (state, action: PayloadAction<string>) => {
      state.user.citizenship = action.payload;
    },
    setStudentDetails: (state, action: PayloadAction<IStudentDetails[]>) => {
      state.studentDetails = action.payload;
    },
    setStudentDetailsActive: (state, action: PayloadAction<IStudentDetails>) => {
      state.studentDetailActive = action.payload;
    },
    setConfiguration: (state, action: PayloadAction<any>) => {
      state.acceptance = action.payload.acceptance;
      state.year = action.payload.year;
      state.changeAchievement = action.payload.changeAchievement;
    },
    setLockedStudentDetailsActive: (state, action: PayloadAction<boolean>) => {
      state.studentDetailActive.locked = action.payload;
    },
    setTheme: (state, action: PayloadAction<ThemeTypes>) => {
      state.theme = action.payload;
    },
    setAccessibleSettings: (state, action: PayloadAction<IAccessibleSettings>) => {
      state.accessibleSettings = action.payload;
    },
    setInited: (state) => {
      state._isInited = true;
    },
    setStatus: (state, action: PayloadAction<string>) => {
      state.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setStudentdetails.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(setStudentdetails.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message || "";
    });
    builder.addCase(setStudentdetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.studentDetails = action.payload;
    });
  },
});

export const { reducer: UserReducer } = UserSlice;
export const { actions: UserActions } = UserSlice;
