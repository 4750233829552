import { createContext, Dispatch, FC, PropsWithChildren, SetStateAction, useState } from "react";

interface IModalState {
  isOpen: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>,
}

export const ModalContext = createContext({} as IModalState);

const ModalProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isOpen, setOpen] = useState<boolean>(false);

  return (
    <ModalContext.Provider value={{ isOpen, setOpen }}>
      {children}
    </ModalContext.Provider>
  );
};

export default ModalProvider;
